import React from "react";
import Sidebar from "../../common/Sidebar";
import Navbar from "../../common/Navbar";
import AddSchool from "../../components/school/AddSchool";
import { Box } from "@mui/material";

function AddSchoolPage() {
  return (
    <>
        <AddSchool />
    </>
  );
}

export default AddSchoolPage;
