import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    metrics: {}, 
    loading: false, 
};

const dashboardDataSlice = createSlice({
    name: 'dashboardData',
    initialState,
    reducers: {
        setMetrics(state, action) {
            state.metrics = action.payload;
            state.loading = false;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        clearDashboardData(state) {
            state.metrics = {};
        },
    },
});

export const { setMetrics, setLoading, clearDashboardData } = dashboardDataSlice.actions;
export default dashboardDataSlice.reducer;
