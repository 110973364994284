import { toast } from "react-hot-toast";
import { apiConnector } from '../ApiConnector';
import { teacherEndpoints } from '../ApiEndPoints';
import { setTeachers, appendTeachers, setLoading, clearTeachers, setSchoolId, setSearchTerm } from '../../Store/Slices/teacherSlice';

export function fetchTeachers(token, page, limit, searchTerm = '', schoolId = '', reset = false) {
  return async (dispatch, getState) => {
    const { searchTerm: previousSearchTerm, schoolId: previousSchoolId } = getState().teachers;

    if (reset || searchTerm !== previousSearchTerm || schoolId !== previousSchoolId) {
      dispatch(clearTeachers());
      dispatch(setSearchTerm(searchTerm));
      dispatch(setSchoolId(schoolId));
    }

    const toastId = toast.loading("Loading teachers...");
    dispatch(setLoading(true));

    try {
      const response = await apiConnector(
        "GET",
        `${teacherEndpoints.ALL_TEACHERS_DATA_API}?page=${page}&perPage=${limit}&search=${searchTerm}&schoolId=${schoolId}`,
        null,
        { Authorization: `Bearer ${token}` }
      );

      if (response.status !== 200) {
        throw new Error("Could not fetch teacher data");
      }

      const result = response?.data?.data;
      const totalTeachers = response.data.totalTeachers;
      const totalPages = response.data.totalPages;

      if (reset || searchTerm !== previousSearchTerm || schoolId !== previousSchoolId || page === 1) {
        dispatch(setTeachers({ teachers: result, totalTeachers, totalPages, currentPage: page, schoolId, searchTerm }));
      } else {
        dispatch(appendTeachers({ teachers: result, totalTeachers, totalPages, currentPage: page, schoolId, searchTerm }));
      }

      toast.success("Teachers fetched successfully");
    } catch (error) {
      toast.error(`Error! ${error?.response?.data?.message}`)
    } finally {
      dispatch(setLoading(false));
      toast.dismiss(toastId);
    }
  };
}
