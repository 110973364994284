import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // For fetching the id from URL params
import { Avatar, Button, Tab, Tabs, Box, Typography, Grid, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import ClientsTable from '../../components/counselor/counselorProfilePage/ClientsTable';
import QuestionnaireTable from '../../components/counselor/counselorProfilePage/AssessmentTable';
import { fetchCounselorProfile,updateCounselor } from '../../services/Operations/CounselorOperation';
import {useSelector} from 'react-redux'
import toast from 'react-hot-toast';
import Permission from '../../components/counselor/counselorProfilePage/Permission'

const ProfilePage = ({ fetchProfileDetails, updateProfileDetails }) => {
  const token = useSelector((state)=>state.auth.token)
  
  const { id } = useParams(); // Get the profile ID from URL params
  const [tabValue, setTabValue] = useState(0);
  const [edit, setEdit] = useState(false); // Edit mode state
  const [profileDetails, setProfileDetails] = useState(null); // Profile details from API
  const [updatedDetails, setUpdatedDetails] = useState({}); // For managing updates
  const [assessments,setAssessments] = useState(null);

  // Fetch profile details on component mount
  useEffect(() => {
    const fetchProfile = async () => {
      const data = await fetchCounselorProfile(token,id);
      console.log(data)
      setAssessments(data?.surveys)
      setProfileDetails(data?.councellerData);
      setUpdatedDetails(data?.councellerData); 
    };
    fetchProfile();
  }, [id]);

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Toggle edit mode
  const toggleEditMode = () => {
    setEdit(!edit);
  };

  // Handle input change in edit mode
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedDetails({
      ...updatedDetails,
      [name]: value,
    });
  };

  // Call the update API when updating profile
  const updateDetails = async () => {
    try{
     const data= await updateCounselor(token,id, updatedDetails); // Call the API to update profile
      setProfileDetails({
        ...profileDetails,councellerData:data
      })
      console.log(data)
      setUpdatedDetails({
        ...updatedDetails,councellerData:data
      })
      setEdit(false); // Exit edit mode after updating
    }catch(error){
       console.log("error while updating")
       toast.error("SOmething went wrong while creating the counselor!")
    }
  };

  // Render loading state if profileDetails is null
  if (!profileDetails) return <Typography>Loading...</Typography>;

  return (
    <Box sx={{ padding: 4 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Avatar
            alt={updatedDetails?.name || profileDetails?.name}
            src={updatedDetails?.profilePhoto} // Use profile photo from API
            sx={{ width: 100, height: 100 }}
          />
        </Grid>
        <Grid item xs>
          {!edit ? (
            <Typography variant="h4">{updatedDetails?.name}</Typography>
          ) : (
            <TextField
              label="Name"
              name="name"
              value={updatedDetails.name}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
          )}
          {!edit ? (
            <Button variant="outlined" startIcon={<EditIcon />} onClick={toggleEditMode}>
              Edit Details
            </Button>
          ) : (
            <Button variant="outlined" startIcon={<SaveIcon />} onClick={updateDetails}>
              Update Details
            </Button>
          )}
        </Grid>
      </Grid>

      <Box sx={{ marginY: 4 }}>
        <Typography variant="h6">Personal Details</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography><strong>Contact No.:</strong> {updatedDetails?.phone}</Typography>
            {edit ? (
              <TextField
                label="Address"
                name="address"
                value={updatedDetails?.address}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
            ) : (
              <Typography><strong>Address:</strong> {updatedDetails?.address}</Typography>
            )}
            {edit ? (
              <TextField
                label="Landmark"
                name="landmark"
                value={updatedDetails?.landmark}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
            ) : (
              <Typography><strong>Landmark:</strong> {updatedDetails?.landmark}</Typography>
            )}
            {edit ? (
              <TextField
                label="City"
                name="city"
                value={updatedDetails?.city}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
            ) : (
              <Typography><strong>City:</strong> {updatedDetails?.city}</Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {edit ? (
              <TextField
                label="Email"
                name="email"
                value={updatedDetails?.email}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
            ) : (
              <Typography><strong>Email ID:</strong> {updatedDetails?.email}</Typography>
            )}
            {edit ? (
              <TextField
                label="School"
                name="schoolName"
                value={updatedDetails?.schoolName}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
            ) : (
              <Typography><strong>School:</strong> {updatedDetails?.schoolName}</Typography>
            )}
            {edit ? (
              <TextField
                label="Approved Client Limit"
                name="maxAllowedStudent"
                value={updatedDetails?.maxAllowedStudent}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
            ) : (
              <Typography><strong>Approved Client Limit:</strong> {updatedDetails?.maxAllowedStudent}</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography><strong>Brand Name:</strong> {updatedDetails?.brandId?.title}</Typography>
            <Typography><strong>Role:</strong> {updatedDetails?.role}</Typography>
          </Grid>
        </Grid>
      </Box>

      <Permission permission={profileDetails?.permission} userId={id}/>

      <Box sx={{ borderBottom: 1, borderColor: 'divider',mt:4 }}>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="Clients" />
          <Tab label="Questionnaire" />
        </Tabs>
      </Box>

      <Box sx={{ paddingY: 2  }}>
        {tabValue === 0 && <ClientsTable  />}
        {tabValue === 1 && <QuestionnaireTable Assessments={assessments} />}
      </Box>
    </Box>
  );
};

export default ProfilePage;
