import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    surveys: [],        
    loading: false,     
    totalSurveys: 0,    
    totalPages: 0,      
    currentPage: 1,     
    searchTerm: '',     
};

const surveySlice = createSlice({
    name: 'surveys',
    initialState,
    reducers: {
        setSurveys(state, action) {
            state.surveys = action.payload.surveys;
            state.totalSurveys = action.payload.totalSurveys;
            state.totalPages = action.payload.totalPages;
            state.currentPage = action.payload.currentPage;
            state.loading = false;
        },
        appendSurveys(state, action) {
            state.surveys = [...state.surveys, ...action.payload.surveys];
            state.totalSurveys = action.payload.totalSurveys;
            state.totalPages = action.payload.totalPages;
            state.currentPage = action.payload.currentPage;
            state.loading = false;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        clearSurveys(state) {
            state.surveys = [];
            state.totalSurveys = 0;
            state.totalPages = 0;
            state.currentPage = 1;
        },
        setSearchTerm(state, action) {
            state.searchTerm = action.payload;
        },
        updateCurrentPage(state, action) {
            state.currentPage = action.payload;
        }
    },
});

export const { setSurveys, appendSurveys, setLoading, clearSurveys, setSearchTerm, updateCurrentPage } = surveySlice.actions;
export default surveySlice.reducer;
