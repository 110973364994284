import React from 'react'
import { Box } from "@mui/material";
import Navbar from "../../common/Navbar";
import Sidebar from "../../common/Sidebar";
import AllSurvey from '../../components/survey/AllSurvey';
function AllStudentPage() {
  return (
      <>
        <AllSurvey/>
      
    </>
    
  )
}

export default AllStudentPage