import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    certifications: [],       // Array to hold certification data
    loading: false,            // Loading state
    totalCertifications: 0,    // Total number of certifications
    totalPages: 0,             // Total number of pages
    currentPage: 1,            // Current page number
    searchTerm: '',            // Search term for filtering certifications
};

const certificationSlice = createSlice({
    name: 'certifications',
    initialState,
    reducers: {
        setCertifications(state, action) {
            state.certifications = action.payload.certifications;
            state.totalCertifications = action.payload.totalCertifications;
            state.totalPages = action.payload.totalPages;
            state.currentPage = action.payload.currentPage;
            state.loading = false;
        },
        appendCertifications(state, action) {
            state.certifications = [...state.certifications, ...action.payload.certifications];
            state.totalCertifications = action.payload.totalCertifications;
            state.totalPages = action.payload.totalPages;
            state.currentPage = action.payload.currentPage;
            state.loading = false;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        clearCertifications(state) {
            state.certifications = [];
            state.totalCertifications = 0;
            state.totalPages = 0;
            state.currentPage = 1;
        },
        setSearchTerm(state, action) {
            state.searchTerm = action.payload;
        },
        updateCurrentPage(state, action) {
            state.currentPage = action.payload;
        }
    },
});

export const { 
    setCertifications, 
    appendCertifications, 
    setLoading, 
    clearCertifications, 
    setSearchTerm, 
    updateCurrentPage 
} = certificationSlice.actions;

export default certificationSlice.reducer;
