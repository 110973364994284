import React, { useEffect, useState } from "react";
import {
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CardMedia,
  Menu,
  MenuItem,
  Box,
  InputAdornment,
  Select,
  FormControl,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { fetchCounselors, deleteCounselor } from "../../services/Operations/CounselorOperation.js";
import { setSearchTerm, updateCurrentPage, clearCounselors } from "../../Store/Slices/counselorSlice.js";
import { useDebounce } from "use-debounce";

const AllCounselors = () => {
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { counselors, totalCounselors, currentPage, loading, totalPages, searchTerm } = useSelector((state) => state.counselors);
  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);
  const [counselorsPerPage, setCounselorsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCounselorId, setSelectedCounselorId] = useState(null);

  const handleChange = (event) => {
    setCounselorsPerPage(event.target.value);
  };

  // Fetch counselors on search term change
  useEffect(() => {
    const fetchCounselorData = async () => {
      dispatch(fetchCounselors(token, 1, counselorsPerPage, debouncedSearchTerm, true));
    };

    if (!loading) {
      fetchCounselorData();
    }
  }, [debouncedSearchTerm, dispatch, counselorsPerPage]);

  // Fetch counselors on page change
  useEffect(() => {
    const fetchCounselorData = async () => {
      dispatch(fetchCounselors(token, currentPage, counselorsPerPage, debouncedSearchTerm));
    };

    if (!loading && currentPage > 1) {
      fetchCounselorData();
    }
  }, [currentPage, debouncedSearchTerm, dispatch]);

  // Clear counselors on unmount
  useEffect(() => {
    return () => {
      dispatch(clearCounselors());
    };
  }, [dispatch]);

  // Handle load more
  const handleLoadMore = () => {
    if (currentPage < totalPages) {
      dispatch(updateCurrentPage(currentPage + 1));
    }
  };

  // Handle search term change
  const handleSearchChange = (e) => {
    dispatch(setSearchTerm(e.target.value));
    dispatch(updateCurrentPage(1)); // Reset to first page on new search
  };

  // Handle menu open
  const handleMenuOpen = (event, counselorId) => {
    setAnchorEl(event.currentTarget);
    setSelectedCounselorId(counselorId);
  };

  // Handle menu close
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Handle counselor deletion
  const handleDelete = () => {
    if (window.confirm("Do you really want to delete this counselor from the database?")) {
      dispatch(deleteCounselor(token, selectedCounselorId));
      handleMenuClose();
    }
  };

  // Handle navigation to counselor profile
  const handleView = () => {
    navigate(`/counselorprofile/${selectedCounselorId}`);
    handleMenuClose();
  };

  return (
    <div style={{ padding: "20px" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={5}>
        <Typography variant="h5" fontWeight="bold">
          All Counselors <Typography component="span">({totalCounselors} Counselors Available)</Typography>
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <TextField
            variant="outlined"
            placeholder="Search Counselor"
            onChange={handleSearchChange}
            value={searchTerm}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="error"
            startIcon={<AddIcon />}
            sx={{ backgroundColor: "#EF492E" }}
            onClick={() => navigate("/addcounselor")}
          >
            Add Counselor
          </Button>
        </Box>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#FFBE9D" }}>
              <TableCell sx={{ fontWeight: "bold" }}>Brand</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Counselor ID</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Max Student Allowed</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {counselors?.map((counselor, index) => (
              <TableRow key={index}>
                <TableCell>
                  {counselor?.brand?.logo ? (
                    <CardMedia
                      component="img"
                      sx={{ width: "100px", height: "50px", objectFit: "contain", borderRadius: "50%" }}
                      image={counselor?.brand?.logo}
                      alt="Counselor Profile Picture"
                    />
                  ) : (
                    <Typography
                      variant="body1"
                      sx={{
                        backgroundColor: "wheat",
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        overflow: "hidden",
                        color: "#FF6636",
                        fontSize: "20px",
                      }}
                    >
                      {counselor?.brand?.title}
                    </Typography>
                  )}
                </TableCell>
                <TableCell>{counselor?.name}</TableCell>
                <TableCell>{counselor?._id}</TableCell>
                <TableCell>{counselor?.maxAllowedStudent}</TableCell>
                <TableCell>
                  <MoreVertIcon
                    sx={{ cursor: "pointer" }}
                    onClick={(event) => handleMenuOpen(event, counselor._id)}
                  />
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl) && selectedCounselorId === counselor._id}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={handleView}>View</MenuItem>
                    <MenuItem onClick={handleDelete}>Delete</MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box mt={3} display="flex" justifyContent="end" alignItems="center" gap="10px">
        <Typography>
          Page - {currentPage}/{totalPages}
        </Typography>
        <Button onClick={handleLoadMore} disabled={currentPage >= totalPages} variant="outlined" color="primary">
          Load More
        </Button>
        {/* Bottom pagination */}
        <FormControl>
          <Select
            value={counselorsPerPage}
            onChange={handleChange}
            displayEmpty
            size="small"
            renderValue={(value) => `Page Size - ${value}`}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </div>
  );
};

export default AllCounselors;


