import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, Button, Accordion, AccordionSummary, AccordionDetails, TextField, Box, Tab, Tabs, Modal, LinearProgress, FormControl, FormControlLabel, RadioGroup, Radio, IconButton, Input, InputLabel, Select, MenuItem } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReactPlayer from 'react-player';
import AddIcon from '@mui/icons-material/Add';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router';
import { toast } from "react-hot-toast";
import { apiConnector } from '../../services/ApiConnector';
import { courseEndpoints, lectureEndpoints, surveyEndpoints, testEndpoints,uploadEnpoints } from '../../services/ApiEndPoints';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import TestComponent from './TestComponent';
import AddTest from './AddTest';
import axios from 'axios';


// CoursePage Component: Manages and displays course data and lectures
const CoursePage = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const { id } = useParams();
  const [courseData, setCourseData] = useState(null);
  const [selectedLecture, setSelectedLecture] = useState(null);
  const [lectures, setLectures] = useState(courseData?.lectures || []);
  const [surveys, setSurveys] = useState(courseData?.surveys || []);
  const [tests, setTests] = useState(courseData?.tests || []);
  const [newLecture, setNewLecture] = useState({ title: '', description: '', url: '', relatedResources: [], relatedSurveys: [] });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [questionnaireType, setQuestionnaireType] = useState('survey')
  const [renderedSurveys, setRenderedSurvey] = useState(null) //survey which is being rendered
  // add/update lecture variables ...
  const [inputType, setInputType] = useState('url'); // 'url' or 'file'
  const [videoFile, setVideoFile] = useState(null);
  const [progress,setProgress] = useState(0);

  useEffect(() => {
    const filteredSurveys = surveys?.filter((survey) =>
      survey?.type === questionnaireType // Return a boolean from the filter function
    );
    setRenderedSurvey(filteredSurveys);
  }, [surveys, questionnaireType]);

  // fetch the data on page load
  useEffect(() => {
    const fetchCourseDetail = async () => {
      const toastId = toast.loading("Loading Course Detail...");

      try {
        const response = await apiConnector(
          "GET",
          `${courseEndpoints.GET_COURSE_DETAIL}/${id}`,
          null,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        );
        setCourseData(response?.data?.data?.courseDetails);
        setLectures(response?.data?.data?.lectures)
        setSurveys(response?.data?.data?.courseSpecificSurveys)
        setTests(response?.data?.data?.courseSpecificResources)
        toast.success("Course Detail loaded successfully");
      } catch (error) {
        toast.error(`Failed to load course Detail: ${error.message}`);
        console.error("FETCH STUDENT PROFILE ERROR:", error);
      } finally {
        toast.dismiss(toastId);
      }
    };

    fetchCourseDetail();
  }, [token, id])
  // When ever the component is being loaded for the first time i will play the first video ....
  useEffect(() => {
    setSelectedLecture(0);
  }, [])
  // Function to handle the selection of a lecture for the video player
  const handleLectureSelect = (index) => {
    setSelectedLecture(index);
  };

  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewLecture((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    if (e.target.files[0] && e.target.files[0].type.includes('video')) {
      setVideoFile(e.target.files[0]);
    } else {
      alert('Please select a valid video file.');
    }
  };

  const handleInputTypeChange = (e) => {
    setInputType(e.target.value);
    // Reset fields when switching between URL and file
    setNewLecture((prev) => ({ ...prev, url: '' }));
    setVideoFile(null);
  };
  // Handling the update and Create api calls.
  const handleAddLecture = async () => {
    if (!newLecture.title || !newLecture.description ) {
      toast.error("All Fields are Required");
      return;
    }
    const toastId = toast.loading(editIndex !== null ? "Updating Lecture..." : "Creating Lecture...");
    try {
      // Upload the file to aws if it is available ...
      if(inputType=='file'){
          if(videoFile==null){
              toast.error("Please select a video file!");
              return;
          }
          const toastId2=toast.loading("Uploading file")
         //uploading file here to aws 
          try {
            const formData = new FormData();
            formData.append('file', videoFile); // 'video' is the key your backend expects
        
            // Make the POST request to the backend
            const response = await axios.post(`${uploadEnpoints.UPLOAD_FILE_TO_AWS}`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                 Authorization: `Bearer ${token}`,
              },
              onUploadProgress: (progressEvent) => {
                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setProgress(progress)
              },
            });
            console.log(response?.data?.data)
            // Handle response from the backend
            toast.success("File uploaded Successfully")
            newLecture.url=response?.data?.data
          } catch (error) {
            toast.error("Error uploading File")
            return
          }finally{
             setVideoFile(null);
             setInputType('url')
             toast.dismiss(toastId2)
          }
      }
      // Taking a break here...
      const data = {
        courseId: id,
        ...newLecture,
      };
      let response;
      if (editIndex !== null) {
        // Update existing lecture
        const lectureId = lectures[editIndex].id; // Assuming each lecture has a unique `id`

        // Get existing relatedSurveys and relatedResources from the lecture
        const existingLecture = lectures[editIndex];
        const { relatedSurveys = [], relatedResources = [] } = existingLecture;

        // Send the update request to the server
        response = await apiConnector(
          "PUT",
          `${lectureEndpoints.UPDATE_LECTURE}/${lectureId}`,
          data,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        );

        if (response?.data?.status) {
          // Merge the updated lecture data from the server (mapping _id to id)
          const updatedLecture = {
            ...existingLecture,  // Keep the existing values
            ...response?.data?.data, // Update with new values (title, description, url)
            id: response?.data?.data?._id, // Map _id to id
            relatedSurveys: relatedSurveys, // Retain previous relatedSurveys
            relatedResources: relatedResources // Retain previous relatedResources
          };

          // Update the lectures list with the updated lecture
          const updatedLectures = lectures.map((lecture, index) =>
            index === editIndex ? updatedLecture : lecture
          );

          // Update the state
          setLectures(updatedLectures);
          toast.success("Lecture Updated Successfully");
        } else {
          toast.error("Failed to update the lecture.");
        }
      } else {
        // Create new lecture
        response = await apiConnector(
          "POST",
          `${lectureEndpoints.CREATE_LECTURE}`,
          data,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        );

        if (response?.data?.status) {
          // For a new lecture, map _id to id and initialize relatedSurveys and relatedResources as empty arrays
          const newLecture = {
            ...response?.data?.data, // Includes new values (title, description, url)
            id: response?.data?.data?._id, // Map _id to id
            relatedSurveys: [], // Initialize empty arrays
            relatedResources: []
          };

          // Update the lectures list with the new lecture
          setLectures([...lectures, newLecture]);
          toast.success("Lecture Created Successfully");
        } else {
          toast.error("Failed to create the lecture.");
        }
      }


      setNewLecture({ title: '', description: '', url: '', relatedResources: [], relatedSurveys: [] });
      setIsModalOpen(false);
      setEditIndex(null);
    } catch (error) {
      toast.error(`Failed to ${editIndex !== null ? "Update" : "Add"} Lecture: ${error.message}`);
    } finally {
      toast.dismiss(toastId);
      setProgress(0)
    }
  };
  const handleDeleteLecture = async (index, lectureId) => {
    const flag = window.confirm("Are you sure you want to delete it?")
    if (flag) {
      try {
        // Make API call to delete the lecture
        const response = await apiConnector(
          "DELETE",
          `${lectureEndpoints.DELETE_LECTURE}/${lectureId}`,
          {},
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        );
        const updatedLectures = lectures.filter((_, i) => i !== index);
        setLectures(updatedLectures);

        // Deselect lecture if it's the one being deleted
        if (selectedLecture === index) {
          setSelectedLecture(null);
        }

        // Collapse accordion if it's the one being deleted
        if (expandedIndex === index) {
          setExpandedIndex(null);
        }

        toast.success("Lecture deleted successfully");
      } catch (error) {
        toast.error(`Failed to delete lecture: ${error.message}`);
      }
    }

  };
  // Function to handle editing a lecture (opens modal)
  const handleEditLecture = (index) => {
    setNewLecture(lectures[index]);
    setEditIndex(index);
    setIsModalOpen(true);
  };

  // Function to handle accordion expand/collapse
  const handleAccordionChange = (index) => (event, isExpanded) => {
    setExpandedIndex(isExpanded ? index : null);
  };


  //updating html dynamically ....
  const descriptionElement = document.getElementById('description');
  const descriptionContent = selectedLecture !== null
    ? lectures?.[selectedLecture]?.description
    : courseData?.description;

  if (descriptionElement) {
    if (descriptionContent) {
      descriptionElement.innerHTML = descriptionContent;
    } else {
      descriptionElement.innerHTML = 'No description available.';
    }
  }



  const handleContextMenu = (e) => {
    e.preventDefault(); // Disable right-click
  };
  const handleDeleteSurvey = async (surveyId) => {
    if (window.confirm("Are you sure you want to delete?")) {
      const toastId = toast.loading("Deleting Survey!");
      try {
        // Set up the API endpoint for deleting the survey
        const endpoint = `${surveyEndpoints.DELETE_SURVEY}/${surveyId}`;

        // Make the API call to delete the survey
        const response = await apiConnector(
          "DELETE",
          endpoint,
          null, // No need for a request body when deleting
          { Authorization: `Bearer ${token}` }
        );

        window.location.reload();
        toast.success("Survey Deleted Successfully");
      } catch (error) {
        console.error("Error deleting survey:", error);
        toast.error("Failed to delete survey.");
      } finally {
        toast.dismiss(toastId);
      }
    }
  };

  //variables for ...resource/test modal of lecture ...
  const [selectedTest, setSelectedTest] = useState(null);
  const [isResourceModalOpen, setIsResourceModalOpen] = useState(false);
  const [lectureDetail, setLectureDetail] = useState(null);
  const handleOpenModal = (index, test = null) => {
    setLectureDetail({ _id: lectures[index]?.id, name: lectures[index]?.title })
    setSelectedTest(test);
    setIsResourceModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedTest(null);
    setIsResourceModalOpen(false);
  };

  const handleDeleteTest = async (testId, lecture) => {
    const flag = window.confirm("Are you sure? You want to delete the Test?");

    if (flag) {
      try {
        const response = await apiConnector(
          "DELETE",
          `${testEndpoints.DELETE_TEST}/${testId}`,
          null,
          { Authorization: `Bearer ${token}` }
        );
        console.log(response)
        if (response?.data?.success) {
          if (lecture?.id) {
            // Deleting from a specific lecture's relatedResources
            setLectures((prevLectures) =>
              prevLectures.map((lec) => {
                if (lec.id === lecture.id) {
                  return {
                    ...lec,
                    relatedResources: lec.relatedResources.filter(
                      (resource) => resource._id !== testId
                    ),
                  };
                }
                return lec;
              })
            );
          } else {
            // Deleting from the main tests list
            setTests((prevTests) => prevTests.filter((test) => test._id !== testId));
          }
          toast.success("Test deleted successfully.");
        } else {
          toast.error("Failed to delete the test.");
        }
      } catch (error) {
        console.error("Error deleting the test:", error);
        toast.error(`Error: ${error.message}`);
      }
    }
  };


  const handleUpdateTests = (data, type) => {
    // Adding resource to a course (lecture-specific)
    if (data?.lectureId) {
      // Find the lecture by id
      const reqLecture = lectures.find((lecture) => lecture.id === data.lectureId);

      if (reqLecture) {
        // Initialize relatedResources if it doesn't exist
        reqLecture.relatedResources = reqLecture.relatedResources || [];

        if (type === "update") {
          // If updating, replace the specific test/resource inside the lecture's relatedResources
          reqLecture.relatedResources = reqLecture.relatedResources.map((resource) =>
            resource._id === data._id ? data : resource
          );
        } else {
          // Otherwise, push the new resource to the lecture's relatedResources
          reqLecture.relatedResources.push(data);
        }
      }
    } else {
      // Adding resource to the main test list
      if (type === "update") {
        setTests((prevTests) =>
          prevTests.map((test) => (test._id === data._id ? data : test))
        );
      } else {
        setTests((prevTests) => [...prevTests, data]);
      }
    }
  };

  console.log(selectedLecture)

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant='h4'>{courseData?.name}</Typography>
      <Grid container spacing={4}>
        {/* Video Player and Description Section */}
        <Grid item xs={7}>
          {
            lectures.length == 0 ?
              <img
                src={courseData?.image}
                alt="Course"
                style={{ width: '100%', height: '350px', objectFit: 'cover', border: '1px solid gray', borderRadius: '20px' }}
              /> :
              <div style={{ position: 'relative', minWidth: '580px', minHeight: '350px', backgroundColor: 'gray' }}>
                <div onContextMenu={handleContextMenu}>
                  <ReactPlayer
                    url={lectures[selectedLecture]?.url}
                    controls={true}  // Default player controls
                    config={{ file: { attributes: { controlsList: 'nodownload' } } }}  // Disable download button
                    playing={false}
                    width="100%"
                    height="350px"
                  />
                </div>
                {/* Transparent div to prevent right-click */}
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 1,
                    backgroundColor: 'transparent',
                    pointerEvents: 'none', // Allows interaction with the iframe
                  }}
                  onContextMenu={(e) => e.preventDefault()} // Prevents right-click on iframe
                ></div>
              </div>
          }
          <Typography sx={{ fontSize: '30px', fontWeight: '600', mb: 2 }}>{lectures[selectedLecture]?.title}</Typography>
          <Box id="description">
            {/* //description html */}
          </Box>
        </Grid>

        {/* Tabs Section */}
        <Grid item xs={5} sx={{ height: '80vh', overflowY: 'scroll' }}>
          <Tabs
            value={tabIndex}
            onChange={(e, newIndex) => setTabIndex(newIndex)}
            textColor="inherit"
            indicatorColor="#EF492E"
            aria-label="course tabs"
            sx={{ transition: 'background-color 0.3s ease, color 0.3s ease', marginBottom: '10px' }}
          >
            <Tab
              sx={{
                borderRadius: '10px',
                backgroundColor: tabIndex === 0 ? '#EF492E' : 'transparent',
                color: tabIndex === 0 ? 'white' : 'black',
                transition: 'background-color 0.3s ease, color 0.3s ease',
                '&:hover': {
                  backgroundColor: tabIndex === 0 ? '#EF492E' : 'transparent',
                }
              }}
              label="Lectures"
            />
            <Tab
              sx={{
                borderRadius: '10px',
                backgroundColor: tabIndex === 1 ? '#EF492E' : 'transparent',
                color: tabIndex === 1 ? 'white' : 'black',
                transition: 'background-color 0.3s ease, color 0.3s ease',
                '&:hover': {
                  backgroundColor: tabIndex === 1 ? '#EF492E' : 'transparent',
                }
              }}
              label="Assessments"
            />
            <Tab
              sx={{
                borderRadius: '10px',
                backgroundColor: tabIndex === 2 ? '#EF492E' : 'transparent',
                color: tabIndex === 2 ? 'white' : 'black',
                transition: 'background-color 0.3s ease, color 0.3s ease',
                '&:hover': {
                  backgroundColor: tabIndex === 2 ? '#EF492E' : 'transparent',
                }
              }}
              label="Resources"
            />
          </Tabs>

          {/* Lecture List Accordion */}
          {tabIndex === 0 && (
            <>
              {lectures?.map((lecture, index) => (
                <Accordion
                  key={lecture.id}
                  expanded={expandedIndex === index}
                  onChange={handleAccordionChange(index)}
                  sx={{ backgroundColor: expandedIndex === index ? 'wheat' : 'white' }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="subtitle1">
                      {expandedIndex === index && (
                        <span role="img" aria-label="playing">🎬</span>
                      )}
                      {lecture.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* Lecture Description and Action Buttons */}
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" width="100%">
                      <Box display="flex" alignItems="center" gap={2}>
                        <Button
                          variant="text"
                          color="primary"
                          onClick={() => handleLectureSelect(index)}
                          startIcon={<PlayCircleIcon />}
                        >
                          Play
                        </Button>
                        <Button
                          variant="outlined"
                          color="info"
                          onClick={() => handleEditLecture(index)}
                          startIcon={<EditIcon />}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => handleDeleteLecture(index, lecture.id)}
                          startIcon={<DeleteIcon />}
                        >
                          Delete
                        </Button>
                      </Box>
                    </Box>

                    {/* Display Resources */}
                    {lecture.relatedResources && (
                      <Box mt={2} display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="subtitle2">Resources&Test:</Typography>
                        <IconButton
                          color="primary"
                          onClick={() => handleOpenModal(index)}
                          aria-label="add-resource"
                          size="small"
                        >
                          <AddIcon />
                        </IconButton>
                      </Box>
                    )}

                    {
                      lecture.relatedResources && lecture.relatedResources.length > 0 && (
                        <Box mt={1}>
                          {lecture.relatedResources.map((resource) => (
                            <Box key={resource._id} display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                              <Button
                                variant="text"
                                color="primary"
                                href={resource.url}
                                target="_blank"
                                sx={{
                                  maxWidth: '200px', // Limit width of the button
                                  wordBreak: 'break-word', // Break long words into multiple lines
                                  whiteSpace: 'normal', // Allow text to wrap naturally
                                }}
                              >
                                {resource.name}
                              </Button>
                              <Box display="flex" alignItems="center" gap={2}>
                                <Button
                                  variant="outlined"
                                  color="info"
                                  onClick={() => handleOpenModal(index, resource)}
                                >
                                  <EditIcon />
                                </Button>
                                <Button
                                  variant="outlined"
                                  color="error"
                                  onClick={() => handleDeleteTest(resource._id, lecture)}
                                >
                                  <DeleteIcon />
                                </Button>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      )
                    }

                    {/* Display Surveys */}
                    {lecture.relatedSurveys && (
                      <Box mt={2} display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="subtitle2">Assessments:</Typography>
                        <IconButton
                          color="primary"
                          onClick={() => {
                            window.open(`/add-survey?course-id=${courseData?.id}&course-name=${courseData?.name}&lectureId=${lecture?.id}&lecture-name=${lecture?.title}`, "_blank")
                          }}
                          aria-label="add-survey"
                          size="small"
                        >
                          <AddIcon />
                        </IconButton>
                      </Box>
                    )}

                    {/* Lecture-accordian-survey */}
                    {
                      lecture.relatedSurveys && lecture.relatedSurveys.length > 0 && (
                        <Box mt={1}>
                          {lecture.relatedSurveys.map((survey) => (
                            <Box key={survey._id} display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                              <Button
                                variant="text"
                                color="primary"
                                href={survey.url || '#'}
                                sx={{
                                  maxWidth: '200px', // Limit width of the button
                                  wordBreak: 'break-word', // Break long words into multiple lines
                                  whiteSpace: 'normal', // Allow the text to wrap onto the next line
                                }}
                              >
                                {survey.name}
                              </Button>
                              <Box display="flex" alignItems="center" gap={2}>
                                <Button
                                  variant="outlined"
                                  color="info"
                                  onClick={() => {
                                    window.open(`/survey/${survey?._id}`, "_blank")
                                  }}
                                >
                                  <RemoveRedEyeIcon />
                                </Button>
                                <Button
                                  variant="outlined"
                                  color="error"
                                  onClick={() => handleDeleteSurvey(survey._id)}
                                >
                                  <DeleteIcon />
                                </Button>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      )
                    }

                  </AccordionDetails>
                </Accordion>
              ))}

              {/* Add Lecture Button */}
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                sx={{ mt: 2, width: '100%', backgroundColor: '#EF492E', ":hover": { backgroundColor: '#EF492E' } }}
                onClick={() => setIsModalOpen(true)}
              >
                Add Lecture
              </Button>
            </>
          )}
          {/* All Surveys Related to course */}
          {tabIndex === 1 && (
            <Box sx={{ height: '80vh', display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <FormControl sx={{ marginX: 'auto' }} component="fieldset">
                <RadioGroup
                  value={questionnaireType} // Controlled component state
                  onChange={(e) => setQuestionnaireType(e.target.value)} // Update state function
                  row // Optional: Displays radio buttons in a row
                >
                  <FormControlLabel value="quiz" control={<Radio />} label="Quiz" />
                  <FormControlLabel value="survey" control={<Radio />} label="Survey" />
                </RadioGroup>
              </FormControl>

              {
                renderedSurveys?.map((survey, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        border: '1px solid #E0E0E0',
                        borderRadius: '12px',
                        padding: '16px',
                        backgroundColor: '#F9F9F9',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        display: 'flex',
                        flexDirection: 'column',

                        justifyContent: 'space-between',
                        gap: '16px',
                        transition: 'transform 0.2s',
                        ":hover": {
                          transform: 'translateY(-4px)',
                          boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)'
                        }
                      }}
                    >
                      <Box sx={{ flexGrow: 1 }}>
                        <Typography fontSize={'20px'} sx={{ color: '#333' }}>
                          {survey?.name}
                        </Typography>
                        <Typography fontSize={'14px'} sx={{ color: '#666', mb: '4px' }}>
                          {survey?.description.slice(0, 50)}...
                        </Typography>
                        <Typography fontSize={'12px'} sx={{ color: '#888' }}>
                          Ends on: {new Date(survey?.endDate).toLocaleDateString()}
                        </Typography>
                      </Box>

                      <Box sx={{ display: 'flex', gap: '8px' }}>
                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: '#EF492E',
                            ":hover": { backgroundColor: '#D4412A' },
                            fontWeight: 'bold',
                            padding: '8px 16px',
                            borderRadius: '8px',
                          }}
                          onClick={() => {
                            navigate(`/survey/${survey?._id}`)
                          }}
                        >
                          View
                        </Button>
                        <Button
                          variant="outlined"
                          sx={{
                            borderColor: '#FF6161',
                            color: '#FF6161',
                            ":hover": { backgroundColor: '#FF6161', color: '#fff' },
                            fontWeight: 'bold',
                            padding: '8px 16px',
                            borderRadius: '8px',
                          }}
                          onClick={() => {
                            handleDeleteSurvey(survey?._id)
                          }}
                        >
                          Delete
                        </Button>
                      </Box>
                    </Box>
                  )
                })
              }
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                sx={{ mt: 2, width: '100%', backgroundColor: '#EF492E', ":hover": { backgroundColor: '#EF492E' } }}
                onClick={() => {
                  const url = `/add-survey?course-id=${courseData?.id}&course-name=${courseData?.name}`;
                  navigate(url);
                }}
              >
                Add Assignments
              </Button>
            </Box>
          )}
          {/* All Resources Related To Course */}
          {tabIndex === 2 && (
            <TestComponent tests={tests} courseId={id} setTests={setTests} />
          )}
        </Grid>
      </Grid>


      <Modal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setEditIndex(null); // Reset edit index when modal is closed
          setNewLecture({ title: '', description: '', url: '', relatedSurveys: [], relatedResources: [] });
          setVideoFile(null); // Reset the file when modal is closed
        }}
        aria-labelledby="lecture-modal-title"
        aria-describedby="lecture-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="lecture-modal-title" variant="h6" component="h2">
            {editIndex !== null ? 'Edit Lecture' : 'Add Lecture'}
          </Typography>

          {/* Title Field */}
          <TextField
            label="Title"
            name="title"
            value={newLecture?.title}
            onChange={handleInputChange}
            fullWidth
            sx={{ mt: 2 }}
          />

          {/* Description Field */}
          <TextField
            label="Description"
            name="description"
            value={newLecture?.description}
            onChange={handleInputChange}
            multiline
            rows={4}
            fullWidth
            sx={{ mt: 2 }}
          />

          {/* Dropdown to select URL or File */}
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="input-type-label">Video Source</InputLabel>
            <Select
              labelId="input-type-label"
              value={inputType}
              onChange={handleInputTypeChange}
              label="Video Source"
            >
              <MenuItem value="url">URL</MenuItem>
              <MenuItem value="file">Video File</MenuItem>
            </Select>
          </FormControl>

          {/* Conditional rendering for URL or File input */}
          {inputType === 'url' ? (
            <TextField
              label="Video URL"
              name="url"
              value={newLecture?.url}
              onChange={handleInputChange}
              fullWidth
              sx={{ mt: 2 }}
            />
          ) : (
            <>
              <Button
                variant="contained"
                component="label"
                fullWidth
                sx={{ mt: 2 }}
              >
                Upload Video
                <input
                  type="file"
                  accept="video/*"
                  hidden
                  onChange={handleFileChange}
                />
              </Button>
              {/* Display file name if a video is selected */}
              {videoFile && (
                <Typography sx={{ mt: 1, fontSize: '0.9rem', color: 'gray' }}>
                  Selected file: {videoFile.name} -{progress}%
                </Typography>
              )}
            </>
          )}

          {/* Add or Update Lecture Button */}
          <Button
            variant="contained"
            onClick={handleAddLecture}
            sx={{ mt: 2, backgroundColor: '#EF492E', ":hover": { backgroundColor: '#EF492E' } }}
            fullWidth
          >
            {editIndex !== null ? 'Update Lecture' : 'Add Lecture'}
          </Button>
        </Box>
      </Modal>

      {/* Modal for Adding/editing Resource */}
      <Modal open={isResourceModalOpen} onClose={handleCloseModal}>
        <Box sx={{ backgroundColor: 'white', borderRadius: '8px', width: '500px', margin: 'auto', position: 'absolute', top: '5%', left: '35%', paddingX: '20px', paddingBottom: '10px' }}>
          <AddTest courseId={courseData?.id} test={selectedTest} onClose={handleCloseModal} onUpdate={handleUpdateTests} setTests={setTests} lectureDetail={lectureDetail} />
        </Box>
      </Modal>
    </Container>
  );
};

export default CoursePage;

