import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Grid,
} from "@mui/material";
import { useParams } from "react-router-dom"; // For accessing URL params
import { fetchBrandProfile, updateBrand } from "../../services/Operations/BrandOperation";
import { useSelector } from 'react-redux';
import useAwsFileUpload from '../../hooks/useAwsImageUpload'

const BrandProfile = () => {
  const {uploadFileToAws, uploading} = useAwsFileUpload();
  const token = useSelector((state) => state.auth.token);
  const { id } = useParams(); // Get the brandId from the URL params
  const [editMode, setEditMode] = useState(false);
  const [brandDetails, setBrandDetails] = useState({
    title: "",
    createdAt: "",
    description: "",
    logo: "",
  });
  const [tempDetails, setTempDetails] = useState({ ...brandDetails });

  // Fetch brand details when component mounts
  useEffect(() => {
    const fetchBrandDetails = async () => {
      try {
        const data = await fetchBrandProfile(token, id);
        setBrandDetails(data);
        setTempDetails(data);
      } catch (error) {
        console.error("Error fetching brand details:", error);
      }
    };
    fetchBrandDetails();
  }, [id, token]);

  // Handle changes to form fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTempDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle image change
  const handleImageChange = async(e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      if(window.confirm("Upload file",file.name)){
        let url = await uploadFileToAws(file);
        setTempDetails((prevState) => ({
          ...prevState,
          logo: url, // Preview the image temporarily
        }));
      }
    }
  };

  // Toggle edit mode
  const toggleEditMode = () => {
    if (editMode) {
      // If saving, update the main brand details
      setBrandDetails(tempDetails);
    } else {
      // Exit edit mode without saving
      setTempDetails(brandDetails);
    }
    setEditMode((prev) => !prev);
  };


  const handleUpdate = async() => {
    console.log("Update", tempDetails)
    const data = await updateBrand(token, id,tempDetails);
    setTempDetails(data);
    setBrandDetails(data);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        width: "80%",
        marginX: "auto",
      }}
    >
      <Typography variant="h4" color="error" fontWeight="bold" gutterBottom>
        {editMode ? "Edit Brand" : "Brand Profile"}
      </Typography>

      <Paper
        elevation={3}
        sx={{
          padding: "20px",
          width: "100%",
        }}
      >


        {editMode ? (
          <TextField
            fullWidth
            variant="outlined"
            name="title"
            value={tempDetails?.title}
            onChange={handleInputChange}
          />
        ) : (
          <Typography variant="h6" fontWeight="bold" gutterBottom>
            {tempDetails?.title}
          </Typography>
        )}

        <Grid container spacing={2} mt={2}>
          {/* Image Section */}
          <Grid item xs={12} md={6}>
            <img
              src={tempDetails?.logo}
              alt="Brand Logo"
              style={{
                border: '2px solid wheat',
                width: "100%",
                borderRadius: "8px",
                height: "300px",
                objectFit: "contain",
              }}
            />
            {editMode && (
              <Button
                fullWidth
                variant="outlined"
                component="label"
                sx={{ mt: 2 }}
              >
                Change Image
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={handleImageChange}
                />
              </Button>
            )}
          </Grid>

          {/* Text Fields */}
          <Grid item xs={12} md={6}>
           {/* Id */}
          <Typography fontWeight="bold">Brand-Id:</Typography>
          <Typography>{brandDetails?._id}</Typography>

            {/* Date */}
            <Typography fontWeight="bold">Date:</Typography>
            <Typography>{brandDetails?.createdAt}</Typography>

            {/* Description */}
            <Typography mt={2} fontWeight="bold">
              Description:
            </Typography>
            {editMode ? (
              <TextField
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                name="description"
                value={tempDetails?.description}
                onChange={handleInputChange}
              />
            ) : (
              <Typography>{brandDetails?.description}</Typography>
            )}
          </Grid>
        </Grid>

        {/* Button Section */}
        <Box display="flex" justifyContent="space-between" mt={4}>
          {editMode && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setTempDetails(brandDetails); // Reset changes
                setEditMode(false); // Exit edit mode
              }}
            >
              Cancel
            </Button>
          )}
          {
            editMode ?
              <Button variant="contained" color="error" onClick={() => {
                toggleEditMode()
                handleUpdate()
              }}>Save</Button> :
              <Button variant="contained" color="error" onClick={toggleEditMode}>Edit Details</Button>
          }
        </Box>
      </Paper>
    </Box>
  );
};

export default BrandProfile;
