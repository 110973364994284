import React, { useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

const SignupForm = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [name, setName] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ email, password, name });
  };

  return (
    <Box
      sx={{
        width: '90%',
        minHeight: '300px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          backgroundColor: 'white',
          borderRadius: '8px',
          width: '100%',
          maxWidth: '400px',
          padding: '20px',
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          sx={{ fontWeight: 'bold', color: '#1A1A1A', mb: 2 }}
        >
          Hi, First Time. Register Now
        </Typography>
        {/* Name */}
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            id="name"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your name"
            variant="outlined"
            required
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
              borderRadius: '4px',
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        {/* Email */}
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            id="email"
            label="Email or Phone"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email or phone number"
            variant="outlined"
            required
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
              borderRadius: '4px',
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        {/* Password */}
        <Box sx={{ mb: 2, position: 'relative' }}>
          <TextField
            fullWidth
            id="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
            variant="outlined"
            required
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
              borderRadius: '4px',
            }}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword((prev) => !prev)}
                    edge="end"
                  >
                    {showPassword ? (
                      <AiOutlineEyeInvisible fontSize={24} fill="#AFB2BF" />
                    ) : (
                      <AiOutlineEye fontSize={24} fill="#AFB2BF" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{
            backgroundColor: '#EF492E',
            color: 'white',
            fontSize: '15px',
            fontWeight: 'bold',
            textTransform: 'none',
            borderRadius: '4px',
            '&:hover': {
              backgroundColor: '#de3f27',
            },
            mt: 2,
          }}
        >
          Sign Up
        </Button>
      </Box>
      {/* Already have an account */}
      <Typography
        sx={{
          textAlign: 'center',
          marginTop: '20px',
          fontSize: '12px',
        }}
      >
        Already have an account?{' '}
        <Typography
          component="span"
          sx={{
            color: '#EF492E',
            marginLeft: '8px',
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
          onClick={() => navigate('/login')}
        >
          Sign-In now
        </Typography>
      </Typography>
    </Box>
  );
};

export default SignupForm;
