import React, { useState } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Card
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NumberCard from "../../components/dashboard/NumberCard";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,  } from '@mui/material';
import courses from '../../data/home_dashboard.svg';
import quizSvg from '../../data/quiz_dashboard.svg'
import studentSvg from '../../data/student_dashboard.svg'
import { useDispatch, useSelector } from 'react-redux'
import {fetchDashboard} from '../../services/Operations/DashboardOperation'
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { apiConnector } from "../../services/ApiConnector";
import {studentEndpoints,teacherEndpoints} from '../../services/ApiEndPoints'
import {toast} from 'react-hot-toast'
import { setMetrics } from "../../Store/Slices/dashboardSlice";

const Dashboard = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const dispatch = useDispatch();
  const { metrics } = useSelector((state) => state.dashboardData);

  const handleClick = (event, user) => {
    setAnchorEl(event.currentTarget);
    setSelectedUser(user);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedUser(null);
  };

  
  useEffect(() => {
        if (Object.keys(metrics).length === 0) {
              dispatch(fetchDashboard(token));
            }
  }, []);
 
  console.log(metrics)

  const handleDelete = async () => {
    if(window.confirm("Are you Sur you want to delete")){
    const toastId = toast.loading("Deleting User!");
  
    try {
      // Check the role of the selected user and determine the endpoint
      const endpoint = selectedUser.role === "teacher"
        ? `${teacherEndpoints.DELETE_TEACHER}/${selectedUser._id}`
        : `${studentEndpoints.DELETE_STUDENT}/${selectedUser._id}`;
  
      // Make the API call to delete the user
      await apiConnector(
        "DELETE",
        endpoint,
        {},
        { Authorization: `Bearer ${token}` }
      );
  
      // Show success message
      toast.success("User Deleted Successfully");
  
      // Update the metrics to remove the deleted user
      const updatedMetrics = {
        ...metrics,
        metrics: {
          ...metrics.metrics,
          topStudents: selectedUser.role === "student"
            ? metrics?.metrics?.topStudents.filter((user) => user._id !== selectedUser._id)
            : metrics.metrics.topStudents, // No change if it's a teacher
          topTeachers: selectedUser.role === "teacher"
            ? metrics?.metrics?.topTeachers.filter((user) => user._id !== selectedUser._id)
            : metrics.metrics.topTeachers, // No change if it's a student
        }
      };
  
      // Update the Redux store with the new metrics (if needed)
      // If metrics are managed by Redux, you would dispatch an action here.
      dispatch(setMetrics(updatedMetrics));
  
    } catch (error) {
      console.error(error);
      toast.error("Failed to delete User");
    } finally {
      toast.dismiss(toastId);
      handleClose(); // Close the menu after deletion
    }
  }
  };
  

  return (
    <>
      <Container >
        <Typography variant="h4" component="h1" fontWeight="600">
          Dashboard
        </Typography>
        {/* Dashboard Items */}
          <Grid item xs={12} mt={'20px'} sm={6} md={4} display={'flex'} width={'100%'} flexDirection={'row'} justifyContent={'space-between'} >
            <NumberCard key={1} title={"Schools"} number={metrics?.metrics?.totalSchools} image={courses} textColor={'#FFFF'} bg={'#EF492E'} route={'/schools'}/>
            <NumberCard key={2} title={"Teachers"} number={metrics?.metrics?.totalTeachers} image={quizSvg} textColor={'#EF492E'} bg={'#FFD9CD'} route={'/teachers'}/>
            <NumberCard key={3} title={"Students"} number={metrics?.metrics?.totalStudents} image={studentSvg} textColor={'#EF492E'} bg={'#FFD9CD'} route={'/students'}/>
          </Grid>
      </Container>
      <Container sx={{ my: 4 }}>
        <Grid container spacing={4}>
        {/* student */}
          <Grid item xs={12} md={6} >
            <Typography variant="h6" sx={{mb:'15px' , fontWeight:'600'}}>All Students</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead >
                  <TableRow sx={{backgroundColor:'#FFBE9D'}}>
                    <TableCell><Typography   fontWeight="bold">Name</Typography></TableCell>
                    <TableCell><Typography  fontWeight="bold">Email</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">Actions</Typography></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {metrics?.metrics?.topStudents.map((student) => (
                    <TableRow key={student.id}>
                      <TableCell >{student.name}</TableCell>
                      <TableCell >{student.email}</TableCell>
                      <TableCell>
                        <IconButton onClick={(event) => handleClick(event, student)}>
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {/* Teacher */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" sx={{mb:'15px'  , fontWeight:'600'}}>All Teachers</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow sx={{backgroundColor:'#FFBE9D'}}>
                    <TableCell><Typography  fontWeight="bold">Name</Typography></TableCell>
                    <TableCell><Typography  fontWeight="bold">Email</Typography></TableCell>
                    <TableCell><Typography  fontWeight="bold">Actions</Typography></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {metrics?.metrics?.topTeachers.map((teacher,index) => (
                    <TableRow key={teacher.id}>
                      <TableCell >{teacher.name}</TableCell>
                      <TableCell >{teacher.email}</TableCell>
                      <TableCell>
                        <IconButton onClick={(event) => handleClick(event, teacher)}>
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>

          <MenuItem onClick={() => {
             navigate(`/${selectedUser.role === 'student' ? 'studentprofile' : 'teacherprofile'}/${selectedUser._id}`);
          }}>View</MenuItem>
          <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </Menu>
      </Container>
    </>
  );
};

export default Dashboard;
