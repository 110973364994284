import React, { useState } from "react";
import { useParams } from 'react-router-dom';
import { toast } from "react-hot-toast"
import {
  Container,
  Box,
  Typography,
  Grid,
  Avatar,
  TextField,
  InputAdornment,
  Button,
  InputLabel,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import { useEffect } from "react";
import { apiConnector } from '../../services/ApiConnector';
import { schoolEndpoints } from '../../services/ApiEndPoints';
import {useSelector} from 'react-redux'
import ProfilePaginationpart from "./ProfilePaginationpart";
const SchoolProfile = () => {
  const token = useSelector((state) => state.auth.token);
  const { id } = useParams();
  const [schoolData, setSchoolData] = useState();
  const [updateData,setUpdateData] = useState();
  const [editing, setEditing] = useState(false);

  // Fetching the School Detail 

  useEffect(() => {
    const fetchSchoolProfile = async () => {
      const toastId = toast.loading("Loading school profile...");
      
      try {
        const response = await apiConnector(
          "GET",
          `${schoolEndpoints.GET_SCHOOL_DETAIL}/${id}`,
          null,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        );
        
        setSchoolData(response?.data?.data);
        toast.success("School profile loaded successfully");
        console.log(response.data.data);
      } catch (error) {
        toast.error(`Failed to load school profile: ${error.message}`);
        console.error("FETCH SCHOOL PROFILE ERROR:", error);
      } finally {
        toast.dismiss(toastId);
      }
    };
    
    fetchSchoolProfile()
  }, [id])

  const handleEdit = () => {
    setEditing(true);
  };

  // Update 

  const handleUpdate = async () => {
    // Validation logic
    if (updateData?.name?.trim() === "") {
      toast.error("School name is required.");
      return;
    }
    const phoneRegex = /^[0-9]{10}$/;
    if (updateData?.phone && !phoneRegex.test(updateData?.phone)) {
      toast.error("Invalid contact number. It should be a 10-digit number.");
      return;
    }
  
    const pincodeRegex = /^[0-9]{6}$/;
    if (updateData?.pincode  && !pincodeRegex.test(updateData?.pincode)) {
      toast.error("Invalid pincode. It should be a 6-digit number.");
      return;
    }
  
    setEditing(false);
    console.log(updateData);
  
    // Perform the API Call
    const toastId = toast.loading("Updating School Data...");
    try {
      const response = await apiConnector(
        "PUT",
        `${schoolEndpoints.UPDATE_SCHOOL_DETAIL}/${id}`,
        updateData,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );
  
      setSchoolData(response?.data?.data);
      toast.success("School profile updated successfully");
      console.log(response.data.data);
    } catch (error) {
      toast.error(`Failed to update school profile: ${error.message}`);
      console.error("FETCH SCHOOL PROFILE ERROR:", error);
    } finally {
      toast.dismiss(toastId);
    }
  };
  

  const handleChange = (e) => {
    setSchoolData({
      ...schoolData,
      [e.target.name]: e.target.value,
    });
    setUpdateData({
       ...updateData,
       [e.target.name]:e.target.value
    })
    console.log(updateData);
  };

  return (
    <>
    <Container maxWidth="md" style={{ paddingTop: "20px", paddingBottom: '50px' }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        borderRadius={8}
        p={3}
        bgcolor="white"
        position="relative"
      >
        <Avatar
          alt="School Logo"
          src={schoolData?.image}
          style={{ width: 100, height: 100, marginBottom: "20px" }}
        />
        {
          editing?<>
           <InputLabel>School Name</InputLabel>
          <TextField
              name="name"
              value={schoolData?.name}
              onChange={handleChange}
              variant="outlined"
              sx={{width:'50%',mx:'auto'}}
             
            /></>: <Typography variant="h4" gutterBottom>
          {schoolData?.name}
        </Typography>
        }
  
        {
          editing ? <Button
            onClick={handleUpdate}
            variant="outlined"
            sx={{
              position: "absolute",
              top: 50,
              right: 10,
              color: "#FF6636",
              borderColor: "#FF6636",
              borderRadius: "4px",
              "&:hover": {
                borderColor: "#FF6636",
                backgroundColor: "#FF6636",
                color: "#fff",
              },
            }}
          >
            Update Details
          </Button> : <Button
            onClick={handleEdit}
            variant="outlined"
            sx={{
              position: "absolute",
              top: 50,
              right: 10,
              color: "#FF6636",
              borderColor: "#FF6636",
              borderRadius: "4px",
              "&:hover": {
                borderColor: "#FF6636",
                backgroundColor: "#FF6636",
                color: "#fff",
              },
            }}
          >
            Edit Details
          </Button>
        }
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>Office Contact No.:</strong>
            </Typography>
            <TextField
              name="phone"
              value={schoolData?.phone}
              onChange={handleChange}
              disabled={!editing}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon color="action" />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>SchoolId :</strong>
            </Typography>
            <TextField
              name="phone"
              value={schoolData?._id}     
              disabled={true}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>School Address:</strong>
            </Typography>
            <TextField
              name="address"
              value={schoolData?.address}
              onChange={handleChange}
              disabled={!editing}
              variant="outlined"
              fullWidth
              multiline
              rows={2}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>State:</strong>
            </Typography>
            <TextField
              name="state"
              value={schoolData?.state}
              onChange={handleChange}
              disabled={!editing}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>City:</strong>
            </Typography>
            <TextField
              name="city"
              value={schoolData?.city}
              onChange={handleChange}
              disabled={!editing}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>Landmark:</strong>
            </Typography>
            <TextField
              name="landmark"
              value={schoolData?.landmark}
              onChange={handleChange}
              disabled={!editing}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>Pincode:</strong>
            </Typography>
            <TextField
              name="pincode"
              value={schoolData?.pincode}
              onChange={handleChange}
              disabled={!editing}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>Board of Education:</strong>
            </Typography>
            <TextField
              name="boardOfEducation"
              value={schoolData?.boardOfEducation}
              onChange={handleChange}
              disabled={!editing}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>Classes from:</strong>
            </Typography>
            <TextField
              name="classesFrom"
              value={schoolData?.classesFrom}
              onChange={handleChange}
              disabled={!editing}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>Classes Till:</strong>
            </Typography>
            <TextField
              name="classesTill"
              value={schoolData?.classesTill}
              onChange={handleChange}
              disabled={!editing}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
    <ProfilePaginationpart schoolId={id}/>
    </>
  );
};

export default SchoolProfile;
