import { useEffect } from "react";
import { useLocation } from "react-router-dom";


const DynamicTitle = () => {
    const location = useLocation();
  
    useEffect(() => {
      switch (location.pathname) {
        case '/':
          document.title = "Dashboard";
          break;
        case'/schools':
          document.title = "Schools";
          break;
        case'/students':
          document.title = "Students";
          break;
        case'/teachers':
          document.title = "Teachers";
          break;
        case'/courses':
          document.title = "Courses";
          break;
        case '/profile':
          document.title = "Profile-Admin";
          break;
        case '/reports':
          document.title = "Reports";
          break;
        case '/surveys':
          document.title = "Surveys";
          break;
          case '/notifications':
            document.title = "Notifications";
            break;
        default:
          document.title = "SN-MENTORING";
      }
    }, [location]);
  
    return null;
  };

export default DynamicTitle;