import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    students: [],
    loading: false,
    totalStudents: 0,
    totalPages: 0,
    currentPage: 1,
    schoolId: '',  // Added schoolId field
    searchTerm: '',  // Added searchTerm field similar to schoolsSlice
};

const studentsSlice = createSlice({
    name: 'students',
    initialState,
    reducers: {
        setStudents(state, action) {
            state.students = action.payload.students;
            state.totalStudents = action.payload.totalStudents;
            state.totalPages = action.payload.totalPages;
            state.currentPage = action.payload.currentPage;
            state.schoolId = action.payload.schoolId || state.schoolId; // Update schoolId if provided to filter
            state.loading = false;
        },
        appendStudents(state, action) {
            state.students = [...state.students, ...action.payload.students];
            state.totalStudents = action.payload.totalStudents;
            state.totalPages = action.payload.totalPages;
            state.currentPage = action.payload.currentPage;
            state.loading = false;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        clearStudents(state) {
            state.students = [];
            state.totalStudents = 0;
            state.totalPages = 0;
            state.currentPage = 1;
            state.schoolId = '';  // Reset schoolId when clearing students
            state.searchTerm = '';  // Reset searchTerm when clearing students
        },
        setSchoolId(state, action) {
            state.schoolId = action.payload;
        },
        setSearchTerm(state, action) {
            state.searchTerm = action.payload;
        },
        updateCurrentPage(state, action) {
            state.currentPage = action.payload;
        }
    },
});

export const {
    setStudents,
    appendStudents,
    setLoading,
    clearStudents,
    setSchoolId,
    setSearchTerm,
    updateCurrentPage,
} = studentsSlice.actions;

export default studentsSlice.reducer;
