import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import {
  Container,
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  Avatar,
} from "@mui/material";
import { useSelector } from "react-redux";
import { apiConnector } from "../../services/ApiConnector";
import { certificationEndpoints } from "../../services/ApiEndPoints";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";

const CertificationPage = () => {
  const { id } = useParams();
  const token = useSelector((state) => state.auth.token);
  const [certificationData, setCertificationData] = useState();
  const [editing, setEditing] = useState(false);
  const [updateData, setUpdateData] = useState({});

  // Fetch Certification Data
  useEffect(() => {
    const fetchCertificationData = async () => {
      const toastId = toast.loading("Loading certification data...");
      try {
        const response = await apiConnector(
          "GET",
          `${certificationEndpoints.GET_CERTIFICATION_DETAIL}/${id}`,
          null,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        );
        setCertificationData(response?.data?.data);
        toast.success("Certification data loaded successfully");
      } catch (error) {
        toast.error(`Failed to load certification data: ${error.message}`);
      } finally {
        toast.dismiss(toastId);
      }
    };

    fetchCertificationData();
  }, [id, token]);

  const handleEdit = () => {
    setEditing(true);
  };

  const handleUpdate = async () => {
    setEditing(false);
    const toastId = toast.loading("Updating certification data...");
    try {
      const response = await apiConnector(
        "PUT",
        `${certificationEndpoints.UPDATE_CERTIFICATION}/${id}`,
        updateData,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );
      setCertificationData(response?.data?.data);
      toast.success("Certification updated successfully");
    } catch (error) {
      toast.error(`Failed to update certification: ${error.message}`);
    } finally {
      toast.dismiss(toastId);
    }
  };

  const handleChange = (e) => {
    setUpdateData({
      ...updateData,
      [e.target.name]: e.target.value,
    });
    setCertificationData({
      ...certificationData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Container maxWidth="md" style={{ paddingBottom: "50px" }}>
      <Box display="flex" flexDirection="column" alignItems="center" borderRadius={8} p={3} bgcolor="white">
        {editing ? (
          <TextField
            name="title"
            value={certificationData?.title}
            onChange={handleChange}
            variant="outlined"
            sx={{ width: "50%", marginBottom: "20px" }}
          />
        ) : (
          <Typography color={'primary'} variant="h4" gutterBottom>{certificationData?.title}</Typography>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1"><strong>Description:</strong></Typography>
            <TextField
              name="description"
              value={certificationData?.description}
              onChange={handleChange}
              disabled={!editing}
              variant="outlined"
              fullWidth
              multiline
              rows={3}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="body1"><strong>Course Name:</strong></Typography>
            <TextField
              name="courseName"
              value={certificationData?.courseId?.name}
              disabled
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            <Typography variant="body1"><strong>Course ID:</strong></Typography>
            <TextField
              name="courseId"
              value={certificationData?.courseId?._id}
              disabled
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            <Typography variant="body1"><strong>School Name:</strong></Typography>
            <TextField
              name="schoolName"
              value={certificationData?.schoolId?.name}
              disabled
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            <Typography variant="body1"><strong>School ID:</strong></Typography>
            <TextField
              name="schoolId"
              value={certificationData?.schoolId?._id}
              disabled
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1"><strong>Upload Date:</strong></Typography>
            <TextField
              name="uploadDate"
              value={new Date(certificationData?.createdAt).toLocaleString()}
              disabled
              variant="outlined"
              fullWidth
            />
          </Grid>

          {certificationData?.studentId ? (
            <>
              <Grid item xs={6}>
                <Typography variant="body1"><strong>Student Name:</strong></Typography>
                <TextField
                  name="studentName"
                  value={certificationData?.studentId?.name}
                  disabled={true}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1"><strong>User ID:</strong></Typography>
                <TextField
                  name="userId"
                  value={certificationData?.studentId?.userId}
                  disabled={true}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1"><strong>Grade:</strong></Typography>
                <TextField
                  name="grade"
                  value={certificationData?.grade}
                  onChange={handleChange}
                  disabled={!editing}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </>
          ) : certificationData?.teacherId ? (
            <>
              <Grid item xs={6}>
                <Typography variant="body1"><strong>Teacher Name:</strong></Typography>
                <TextField
                  name="teacherName"
                  value={certificationData?.teacherId?.name}
                  disabled={true}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1"><strong>User ID:</strong></Typography>
                <TextField
                  name="userId"
                  value={certificationData?.teacherId?.userId}
                  disabled={true}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </>
          ) : null}

          <Grid item xs={12}>
            <Typography variant="body1"><strong>Uploaded By (Admin):</strong></Typography>
            <TextField
              name="adminName"
              value={certificationData?.adminId?.name}
              disabled
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<VisibilityIcon />}
              href={certificationData?.img}
              target="_blank"
              fullWidth
              sx={{ marginTop: "10px" }}
            >
              View & Download Certificate
            </Button>
          </Grid>
          <Typography fontSize={'12px'} mt={'5px'}>{certificationData?.img}</Typography>
          
        </Grid>

        {editing ? (
          <Button
            onClick={handleUpdate}
            variant="outlined"
            sx={{
              marginTop: "20px",
              color: "#FF6636",
              borderColor: "#FF6636",
              "&:hover": {
                borderColor: "#FF6636",
                backgroundColor: "#FF6636",
                color: "#fff",
              },
            }}
          >
            Update Details
          </Button>
        ) : (
          <Button
            onClick={handleEdit}
            variant="outlined"
            sx={{
              marginTop: "20px",
              color: "#FF6636",
              borderColor: "#FF6636",
              "&:hover": {
                borderColor: "#FF6636",
                backgroundColor: "#FF6636",
                color: "#fff",
              },
            }}
          >
            Edit Details
          </Button>
        )}
      </Box>
    </Container>
  );
};

export default CertificationPage;
