import React, { useState, useEffect } from 'react';
import { Grid, FormControl, TextField, Autocomplete, CircularProgress, Button, Box, MenuItem } from '@mui/material';
import { useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';
import { apiConnector } from '../services/ApiConnector';
import { teacherEndpoints } from '../services/ApiEndPoints';

const TeacherSelector = ({ size, teacher, setTeacher, school }) => {
  const [teacherOptions, setTeacherOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebounce(searchTerm, 800);
  const token = useSelector((state) => state.auth.token);

  const fetchTeachers = async (currentPage = 1, searchTerm = '', reset = false) => {
    setLoading(true);
    try {
      const response = await apiConnector(
        'GET',
        `${teacherEndpoints.GET_TEACHERS_BY_SCHOOL}/${school?._id}?page=${currentPage}&limit=10&searchTerm=${searchTerm}`,
        null,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );
      if (reset) {
        setTeacherOptions(response.data.data);
      } else {
        setTeacherOptions((prevOptions) => [...prevOptions, ...response.data.data]);
      }
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching teachers:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch teachers when school._id or debouncedSearchTerm changes
  useEffect(() => {
    if (school && school?._id) {
      fetchTeachers(1, debouncedSearchTerm, true);
      setPage(1);
    }
  }, [school?._id, debouncedSearchTerm]); // Trigger API call when school._id or debouncedSearchTerm changes

  useEffect(() => {
    if (page > 1 && school) {
      fetchTeachers(page, debouncedSearchTerm);
    }
  }, [page]);

  const handleLoadMore = () => {
    if (page < totalPages) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setPage(1); // Reset to the first page when search term changes
  };

  return (
    <Grid item xs={12} sm={6}>
      <FormControl fullWidth variant="outlined">
        <Autocomplete
          multiple
          size={size || ''}
          required
          options={teacherOptions}
          getOptionLabel={(option) => option.name || ''}
          disabled={!school}
          loading={loading}
          value={teacher || []}
          onChange={(event, newValue) => {
            setTeacher(newValue)
            setSearchTerm('');
          }}
          renderInput={(params) => (
            <TextField
              size="small"
              {...params}
              label="Teacher"
              variant="outlined"
              
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              onChange={handleSearchChange}
            />
          )}
          ListboxComponent={(props) => (
            <Box {...props} sx={{ '& ul': { padding: 0 } }}>
              {props.children}
              {page < totalPages && (
                <MenuItem>
                  <Button variant="contained" onClick={handleLoadMore} fullWidth disabled={loading}>
                    {loading ? 'Loading...' : 'Load More'}
                  </Button>
                </MenuItem>
              )}
            </Box>
          )}
        />
      </FormControl>
    </Grid>
  );
};

export default TeacherSelector;
