import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    reports: [],          // Array to hold report data
    loading: false,       // Loading state
    totalReports: 0,      // Total number of reports
    totalPages: 0,        // Total number of pages
    currentPage: 1,       // Current page number
    searchTerm: '',       // Search term for filtering reports
};

const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        setReports(state, action) {
            state.reports = action.payload.reports;
            state.totalReports = action.payload.totalReports;
            state.totalPages = action.payload.totalPages;
            state.currentPage = action.payload.currentPage;
            state.loading = false;
        },
        appendReports(state, action) {
            state.reports = [...state.reports, ...action.payload.reports];
            state.totalReports = action.payload.totalReports;
            state.totalPages = action.payload.totalPages;
            state.currentPage = action.payload.currentPage;
            state.loading = false;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        clearReports(state) {
            state.reports = [];
            state.totalReports = 0;
            state.totalPages = 0;
            state.currentPage = 1;
        },
        setSearchTerm(state, action) {
            state.searchTerm = action.payload;
        },
        updateCurrentPage(state, action) {
            state.currentPage = action.payload;
        }
    },
});

export const { setReports, appendReports, setLoading, clearReports, setSearchTerm, updateCurrentPage } = reportsSlice.actions;
export default reportsSlice.reducer;
