import { createSlice } from "@reduxjs/toolkit";
import {jwtDecode} from 'jwt-decode'; // Assuming you're using 'jwt-decode'

const isTokenExpired = (token) => {
  if (!token) return true; // No token means expired or not present
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Convert to seconds
    return decodedToken.exp < currentTime;
  } catch (error) {
    return true; // Invalid token or error in decoding
  }
};

const getInitialUser = () => {
  const token = localStorage.getItem("token");
  if (token && !isTokenExpired(token)) {
    try {
      return JSON.parse(localStorage.getItem("user")) || null;
    } catch {
      // In case JSON parsing fails
      localStorage.removeItem("user");
      return null;
    }
  } else {
    localStorage.removeItem("user");
    return null;
  }
};

const initialState = {
  user: getInitialUser(),
  loading: false,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const { setUser, setLoading } = profileSlice.actions;
export default profileSlice.reducer;
