import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput,
  Checkbox,
  ListItemText
} from "@mui/material";

import AttachFileIcon from '@mui/icons-material/AttachFile';
import useAwsFileUpload from '../../hooks/useAwsImageUpload';
import { useDispatch, useSelector } from "react-redux";
import { addCounselor } from "../../services/Operations/CounselorOperation";
import { useNavigate } from 'react-router-dom';
import BrandSelector from "../../common/BrandSelectorDropdown";
import toast from "react-hot-toast";

const AddCounselor = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const { uploadFileToAws, uploading } = useAwsFileUpload();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [permission, setPermission] = useState([]);
  const [maxAllowedStudent, setMaxAllowedStudent] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [landmark, setLandmark] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [brand, setBrand] = useState(null);

  const handleImageChange = (e) => {
    if (e.target.files.length > 0) {
      setProfilePhoto(e.target.files[0]);
    }
  };

  const permissionsList = ["student-management",
    "school-management",
    "course-management",
    "teacher-management",
    "lecture-management",
    "assessment-management",
    "brand-management",
    "certificate-management",
    "report-management",
    "materials-management"]

  const handlePermissionChange = (event) => {
    const { target: { value } } = event;
    setPermission(typeof value === 'string' ? value.split(',') : value);
  };

  const handleSubmit = async () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const phoneRegex = /^[6-9]\d{9}$/; 
    if (email && !emailRegex.test(email)) {
      toast.error("Invalid email format.");
      return;
    }
    if (phone && !phoneRegex.test(phone)) {
      toast.error("Invalid phone number. Must be a valid 10-digit number.");
      return;
    }
    if (!name  ) {
      toast.error("Name is Required")
      return;
    }
    if (!email  ) {
      toast.error("Email is Required")
      return;
    }
    if (!brand  ) {
      toast.error("Please select atleast a brand")
      return;
    }
    if (!maxAllowedStudent  ) {
      toast.error("Please enter the number of Students this Counselor can have")
      return;
    }
    // upload image
    let imageUrl=""
    if(profilePhoto){
       imageUrl = await uploadFileToAws(profilePhoto);
       if (!imageUrl) {
        throw new Error("Failed to upload profile photo");
      }
    }
   
   
    dispatch(addCounselor(token, {
      name,
      email,
      contactNo:phone,
      permission,
      maxAllowedStudent,
      brandId: brand?._id,
      homeAddress:address,
      city,
      landmark,
      state,
      pincode,
      img: imageUrl
    }, navigate));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        width: '80%',
        marginX: 'auto'
      }}
    >
      <Typography variant="h4" color="error" fontWeight="bold" gutterBottom>
        Add New Counselor
      </Typography>

      <Paper
        elevation={3}
        sx={{
          padding: "20px",
          width: "100%",
        }}
      >
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          Counselor Details
        </Typography>

        <Box >
        <Grid container spacing={1}>
          {/* Name */}
          <Grid item xs={12} md={6}>
          <TextField
             fullWidth
            required={true}
            label="Counselor Name"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter Counselor Name"
            margin="normal"
          /></Grid>
           

          {/* Email */}
          <Grid item xs={12} md={6}>
          <TextField    
            fullWidth     
            required={true}
            type="email"
            label="Email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter Email"
            margin="normal"
          />
          </Grid>

          {/* Phone */}
          <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Phone"
            variant="outlined"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Enter Phone"
            margin="normal"
          />
          </Grid>

          {/* Permissions */}
          <Grid item xs={12} md={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel>Permissions</InputLabel>
            <Select
              multiple
              value={permission}
              onChange={handlePermissionChange}
              input={<OutlinedInput label="Permissions" />}
              renderValue={(selected) => selected.join(', ')}
            >
              {permissionsList.map((perm) => (
                <MenuItem key={perm} value={perm}>
                  <Checkbox checked={permission.indexOf(perm) > -1} />
                  <ListItemText primary={perm} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          </Grid>

          {/* Max Allowed Student */}
          <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            type="Number"
            required={true}
            label="Max Allowed Students"
            variant="outlined"
            value={maxAllowedStudent}
            onChange={(e) => setMaxAllowedStudent(e.target.value)}
            placeholder="Enter Max Allowed Students"
            margin="normal"
          />
          </Grid>

          {/* Address */}
          <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Address"
            variant="outlined"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            placeholder="Enter Address"
            margin="normal"
          />
          </Grid>
          </Grid>


          <Grid container spacing={2}>
            {/* City */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="City"
                variant="outlined"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                placeholder="Enter City"
                margin="normal"
              />
            </Grid>

            {/* Landmark */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Landmark"
                variant="outlined"
                value={landmark}
                onChange={(e) => setLandmark(e.target.value)}
                placeholder="Enter Landmark"
                margin="normal"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            {/* State */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="State"
                variant="outlined"
                value={state}
                onChange={(e) => setState(e.target.value)}
                placeholder="Enter State"
                margin="normal"
              />
            </Grid>

            {/* Pincode */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Pincode"
                variant="outlined"
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
                placeholder="Enter Pincode"
                margin="normal"
              />
            </Grid>
          </Grid>

            <BrandSelector brand={brand} setBrand={setBrand}/>
          {/* Image Upload */}
          <Grid container spacing={2} mt={2}>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="outlined"
                component="label"
                startIcon={<AttachFileIcon />}
              >
                Upload Profile Photo
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </Button>
              <Typography variant="caption">
                {profilePhoto ? profilePhoto.name : "Supported Files: jpg, png"}
              </Typography>
            </Grid>
          </Grid>

          <Box
            display="flex"
            justifyContent="space-between"
            mt={3}
          >
            <Button variant="outlined" color="secondary" onClick={() => navigate('/counselors')}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleSubmit}
              disabled={uploading}
            >
              {uploading ? 'Uploading...' : 'Save Counselor'}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default AddCounselor;
