import React, { useState } from 'react';
import { Avatar, Box, Button, Container, Typography, Paper, CardMedia, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { authEndpoints } from '../../services/ApiEndPoints';
import { apiConnector } from '../../services/ApiConnector';


const Profile = () => {
  const token = useSelector((state)=>state.auth.token)
  const { user } = useSelector((state) => state.profile);

  // State for modal visibility
  const [open, setOpen] = useState(false);
  
  // State for passwords
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // Handle opening and closing the modal
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async () => {
    if (password === "" || confirmPassword === "") {
      toast.error("Password and Confirm Password fields can't be empty!");
      return;
    }
  
    if (password !== confirmPassword) {
      toast.error("Password and Confirm Password should be the same!");
      
      return;
    }
    handleClose();

    const toastId = toast.loading("Updating Password...");
  
    try {
      const data = { newPassword:password };
  
      // Assuming you have an endpoint to update the password
      const response = await apiConnector(
        "POST",
        `${authEndpoints.RESET_PASSWORD}`,
        data,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );
      console.log(response)
      if (response?.data?.status) {
        toast.success("Password updated successfully!");
        handleClose(); // Close modal or reset the form if necessary
      } else {
        throw new Error("Failed to update password");
      }
    } catch (error) {
      toast.error(`Failed to update password: ${error.message}`);
    } finally {
      toast.dismiss(toastId);
    }
  };
  

  return (
    <Container >
      <Paper elevation={3} sx={{ minHeight:'80vh',padding: '1rem', marginTop: '1rem', borderRadius: '10px' }}>
        {/* Profile Header */}
        <Box
          sx={{
            borderBottom:'2px solid #FFD9CD',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '2rem',
          }}
        >
          <Typography variant="h4" color="#EF492E" sx={{ mb:'4px', fontWeight: 600 }}>
            Profile
          </Typography>
        </Box>

        {/* Profile Picture and Name */}
        <Box sx={{ textAlign: 'start', marginBottom: '2rem', marginX:'40px'  }}>
          <Avatar sx={{ width: 100, height: 100,  marginBottom: '1rem' }}>
            <CardMedia
              component="img"
              sx={{ width: 150, height:150, borderRadius: '50%' ,objectFit:'contain' }}
              image={user?.profilePhoto || `https://ui-avatars.com/api/?name=${user?.name}&background=random`}
              alt="profileImage"
            />
          </Avatar>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            {user?.name}
          </Typography>
        </Box>

        {/* Personal Details */}
        <Box sx={{ textAlign: 'left', marginTop: '1rem',marginX:'40px' }}>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            Personal Details
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '0.5rem' }}>
            <strong>Contact No:</strong> {user?.phone}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '0.5rem' }}>
            <strong>Email ID:</strong> {user?.email}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '0.5rem' }}>
            <strong>Role:</strong> {user?.role}
          </Typography>
          <Button variant='outlined' onClick={handleClickOpen}>Update Password</Button>
        </Box>

        {/* Password Update Modal */}
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Update Password</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              label="Password"
              type="password"
              fullWidth
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              margin="dense"
              label="Confirm Password"
              type="password"
              fullWidth
              variant="outlined"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">Cancel</Button>
            <Button onClick={handleSubmit} color="primary">Submit</Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </Container>
  );
};

export default Profile;
