import { createSlice } from "@reduxjs/toolkit";
import {jwtDecode} from 'jwt-decode';

const isTokenExpired = (token) => {
  if (!token) return true; // No token means expired or not present
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Convert to seconds
    return decodedToken.exp < currentTime;
  } catch (error) {
    return true; // Invalid token or error in decoding
  }
};

const initialState = {
  signupData: null,
  loading: false,
  token: (() => {
    const token = localStorage.getItem("token");
    if (token && !isTokenExpired(JSON.parse(token))) {
      return JSON.parse(token);
    } else {
      localStorage.removeItem("token");
      return null;
    }
  })(),
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setSignupData(state, action) {
      state.signupData = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setToken(state, action) {
      const token = action.payload;
      if (token && !isTokenExpired(token)) {
        localStorage.setItem("token", JSON.stringify(token));
        state.token = token;
      } else {
        localStorage.removeItem("token");
        state.token = null;
      }
    },
  },
});

export const { setSignupData, setLoading, setToken } = authSlice.actions;

export default authSlice.reducer;
