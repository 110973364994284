import { toast } from "react-hot-toast";
import { apiConnector } from '../ApiConnector';
import { certificationEndpoints } from '../ApiEndPoints'; // Import your API endpoints for certification
import { setCertifications, appendCertifications, setLoading, clearCertifications, setSearchTerm } from '../../Store/Slices/certificationSlice'; // Adjust import path as necessary

export function fetchCertifications(token, page, limit, searchTerm, reset = false) {
  return async (dispatch, getState) => {
    const { searchTerm: previousSearchTerm } = getState().certifications;

    if (reset || searchTerm !== previousSearchTerm) {
      dispatch(clearCertifications());
      dispatch(setSearchTerm(searchTerm));
    }

    const toastId = toast.loading("Loading certifications...");
    dispatch(setLoading(true));

    try {
      const response = await apiConnector(
        "GET",
        `${certificationEndpoints.GET_ALL_CERTIFICATION}?page=${page}&limit=${limit}&searchTerm=${searchTerm}`,
        null,
        { Authorization: `Bearer ${token}` }
      );
      console.log(response)
      if (response.status !== 200) {
        throw new Error("Could not fetch certification data");
      }

      const result = response?.data?.data;
      const totalCertifications = response?.data?.totalDocuments;
      const totalPages = response?.data?.totalPages;
      
      if (reset || searchTerm !== previousSearchTerm || page === 1) {
        dispatch(setCertifications({ certifications: result, totalCertifications, totalPages, currentPage: page, searchTerm }));
      } else {
        dispatch(appendCertifications({ certifications: result, totalCertifications, totalPages, currentPage: page, searchTerm }));
      }

      toast.success("Certifications fetched successfully");
    } catch (error) {
      toast.error(`Error! ${error?.response?.data?.message}`);
    } finally {
      dispatch(setLoading(false));
      toast.dismiss(toastId);
    }
  };
}
