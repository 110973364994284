import React, { useState, useEffect } from 'react';
import { TextField, Button, Paper, Box, Typography, Grid, IconButton, Checkbox, MenuItem, Select, InputLabel, FormControl, CircularProgress } from '@mui/material';
import { CloudUpload, Cancel } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import CourseSelectedDropDown from '../../common/CourseSelectedDropdown';
import { apiConnector } from '../../services/ApiConnector';
import { notificationEndpoints } from '../../services/ApiEndPoints';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast'
// import useFirebaseImageUpload from '../../hooks/useFirebaseImageUpload';
import useAwsFileUpload from '../../hooks/useAwsImageUpload'

const CreateNotification = () => {
  const {uploadFileToAws, uploading} = useAwsFileUpload();
  // const { uploadImageToFirebase, uploading } = useFirebaseImageUpload();
  const token = useSelector((state) => state.auth.token)
  const navigate = useNavigate();
  const [topic, setTopic] = useState('');
  const [course, setCourse] = useState(null);
  const [description, setDescription] = useState('');
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [teacherList, setTeacherList] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectAllTeachers, setSelectAllTeachers] = useState(false);
  const [selectAllStudents, setSelectAllStudents] = useState(false);
  const [loading, setLoading] = useState(false);

  // Fetch student and teacher lists based on the selected course
  useEffect(() => {
    if (course) {
      fetchStudentAndTeacherList(course._id)
    }
  }, [course]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSelectAllTeachers = (e) => {
    setSelectAllTeachers(e.target.checked);
    setSelectedTeachers(e.target.checked ? teacherList?.map(teacher => teacher._id) : []);
  };

  const handleSelectAllStudents = (e) => {
    setSelectAllStudents(e.target.checked);
    setSelectedStudents(e.target.checked ? studentList?.map(student => student._id) : []);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure topic and message are not empty
    if (topic === "" || message === "") {
      toast.error("Topic and message are required");
      return;
    }

    // Ensure that a course is selected
    if (!course) {
      toast.error("Please select a course before submitting.");
      return;
    }

    // Ensure that either selected teachers or selected students are present
    if (!selectedTeachers.length && !selectedStudents.length) {
      toast.error("Please select at least one teacher or student.");
      return;
    }
    const toastId = toast.loading("Sending notification to all Recipents..")
    try {
      let imageUrl = "";

      // If a file is provided, upload the image using the Firebase upload hook
      if (file) {
        imageUrl = await uploadFileToAws(file);
        if (!imageUrl) {
          throw new Error("Failed to upload image");
        }
      }

      // Creating the notification data
      const notificationData = {
        name: topic, // Using 'name' to match your API structure
        type: "Created By Admin Directly!",
        message, // Notification message
        description, // Optional description field
        url: imageUrl, // Uploaded image URL, if present
        teacherIds: selectedTeachers, // Correct assignment for teacher IDs
        studentIds: selectedStudents, // Correct assignment for student IDs
      };

      console.log("Notification Data:", notificationData);

      // Send the notification data to the server
      const response = await apiConnector(
        "POST",
        notificationEndpoints.CREATE_CUSTOM_NOTIFICATION,
        notificationData,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );

      // Check response status
      if (response.status === 200 || response.status === 201) {
        toast.success("Notification created successfully!");
      } else {
        toast.error("Failed to create notification. Please try again.");
      }

      console.log("Notification creation response:", response);

    } catch (error) {
      console.error("CREATE NOTIFICATION ERROR:", error);
      toast.error(`Failed to create notification: ${error.message}`);
    } finally {
      toast.dismiss(toastId)
      navigate('/notifications')
    }
  };



  const fetchStudentAndTeacherList = async (courseId) => {
    setLoading(true);
    try {
      const response = await apiConnector(
        "GET",
        `${notificationEndpoints.GET_USERS_BY_COURSEID}?courseId=${courseId}`,
        null,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );
      console.log(response)
      // Assuming the API returns both studentList and teacherList
      const { students, teachers } = response?.data?.data || {};

      // Set the lists in state or wherever they need to be used
      setStudentList(students);
      setTeacherList(teachers);
    } catch (error) {
      console.error("FETCH STUDENT AND TEACHER LIST ERROR:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper sx={{ width: "80%", margin: "auto", padding: 4 }}>
      <Typography variant="h5" fontWeight="bold" mb={3}>
        Create Notifications
      </Typography>

      <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off" sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        <TextField
          size='small'
          label="Topic"
          variant="outlined"
          fullWidth
          value={topic}
          onChange={(e) => setTopic(e.target.value)}
          placeholder="Enter notification topic"
        />

        <TextField
          size='small'
          label="Message"
          variant="outlined"
          fullWidth
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Enter notification message"
        />

        <Box>
          <Typography fontWeight={'bold'} mb={1}>Course Description</Typography>
          <TextField
            multiline
            minRows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter course description"
            fullWidth
            variant="outlined"
            sx={{ padding: '2px', borderRadius: '10px' }}
          />
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography mb={1}>Upload File (Optional)</Typography>
            <label htmlFor="upload-file">
              <input
                id="upload-file"
                type="file"
                accept="image/*" // Accepts only image files
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <IconButton
                color="primary"
                component="span"
                sx={{ border: "1px dashed grey", padding: "7px", width: "100%", borderRadius: "10px", justifyContent: "flex-start" }}
              >
                <CloudUpload sx={{ mr: 2, fontSize: "19px" }} />
                {file ? <Typography sx={{ fontSize: '15px' }}>{file.name}</Typography> : <Typography sx={{ fontSize: '15px' }}>Choose File</Typography>}
              </IconButton>
              <Typography variant="caption" display="block" color="textSecondary">
                Supported Files: jpg, png
              </Typography>
            </label>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography mb={1}>Course</Typography>
            <CourseSelectedDropDown size={'small'} course={course} setCourse={setCourse} />
          </Grid>

          {/* Student Dropdown */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth disabled={!course}>
              <InputLabel>Students{loading && <CircularProgress />}</InputLabel>

              <Select
                size='small'
                multiple
                value={selectedStudents}
                onChange={(e) => setSelectedStudents(e.target.value)}
                renderValue={(selected) => selected.length ? 'Selected Students' : 'Select students'}
              >
                <MenuItem value="selectAll">
                  <Checkbox
                    checked={selectAllStudents}
                    onChange={handleSelectAllStudents}
                  />
                  Select All
                </MenuItem>
                {studentList?.map((student) => (
                  <MenuItem key={student._id} value={student._id}>
                    <Checkbox checked={selectedStudents.includes(student._id)} />
                    {student.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Teacher Dropdown */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth disabled={!course}>
              <InputLabel>Teachers {loading && <CircularProgress />}</InputLabel>
              <Select
                size='small'
                multiple
                value={selectedTeachers}
                onChange={(e) => setSelectedTeachers(e.target.value)}
                renderValue={(selected) => selected.length ? 'watch Selected Teachers' : 'Select teachers'}
              >
                <MenuItem value="selectAll">
                  <Checkbox
                    checked={selectAllTeachers}
                    onChange={handleSelectAllTeachers}
                  />
                  Select All
                </MenuItem>
                {teacherList?.map((teacher) => (
                  <MenuItem key={teacher._id} value={teacher._id}>
                    <Checkbox checked={selectedTeachers.includes(teacher._id)} />
                    {teacher.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="space-between" mt={4}>
          <Button
            variant="outlined"
            startIcon={<Cancel />}
            onClick={() => { navigate("/notifications") }}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="warning"
            type='submit'
            sx={{ backgroundColor: "#EF492E", "&:hover": { backgroundColor: "#d14026" } }}
          >
            Send Notification
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};



export default CreateNotification;
