// import React, { useState } from 'react';
// import { Box, Button, Typography, Modal } from '@mui/material';
// import AddIcon from '@mui/icons-material/Add';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import AddTest from './AddTest';
// import { apiConnector } from '../../services/ApiConnector';
// import { testEndpoints } from '../../services/ApiEndPoints';
// import { useSelector } from 'react-redux';
// import { toast } from 'react-hot-toast';
// import Pdf_viewer from './Pdf_viewer';

// const TestComponent = ({ tests, courseId, setTests }) => {
//   const token = useSelector((state) => state.auth.token);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [selectedTest, setSelectedTest] = useState(null);
//   const [viewerContent, setViewerContent] = useState({ type: '', url: '',name:'' });
//   const [isPdfViewerOpen, setIsPdfViewerOpen] = useState(false);
//   const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);

//   const handleOpenModal = (test = null) => {
//     setSelectedTest(test);
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setSelectedTest(null);
//     setIsModalOpen(false);
//   };

//   const handleDeleteTest = async (testId) => {
//     const flag = window.confirm("Are you sure? You want to delete the Test?");

//     if (flag) {
//       try {
//         const response = await apiConnector(
//           "DELETE",
//           `${testEndpoints.DELETE_TEST}/${testId}`,
//           null,
//           { Authorization: `Bearer ${token}` }
//         );
//         if (response?.data?.success) {
//           setTests((prevTests) => prevTests.filter((test) => test._id !== testId));
//           toast.success("Test deleted successfully.");
//         } else {
//           toast.error("Failed to delete the test.");
//         }
//       } catch (error) {
//         console.error("Error deleting the test:", error);
//         toast.error(`Error: ${error.message}`);
//       }
//     }
//   };

//   const handleUpdateTests = (updatedTest, type) => {
//     if (type === "update") {
//       setTests((prevTests) =>
//         prevTests.map((test) => (test._id === updatedTest._id ? updatedTest : test))
//       );
//     } else {
//       setTests((prevTests) => [...prevTests, updatedTest]);
//     }
//   };

//   // Determine if it's a PDF, image, or URL
//   const handleGo = (test) => {
//     const url = test?.url;
//     const type = test?.type;
//     const name = test?.name

//     if (type) {
//       if (type === 'pdf') {
//         // Open PDF in modal via iframe, disabling toolbar for PDFs
//         setViewerContent({ type, url,name });
//         setIsPdfViewerOpen(true);
//       } else if (type === 'image') {
//         // Open image modal
//         setViewerContent({ type, url ,name});
//         setIsImageViewerOpen(true);
//       } else {
//         // If it's a URL, open it in a new tab
//         window.open(url, '_blank');
//       }
//     }
//   };

//   const handleClosePdfViewer = () => {
//     setIsPdfViewerOpen(false);
//     setViewerContent({ type: '', url: '',name:'' });
//   };

//   const handleCloseImageViewer = () => {
//     setIsImageViewerOpen(false);
//     setViewerContent({ type: '', url: '',name:'' });
//   };


//   return (
//     <Box sx={{ height: '70vh', overflowY: 'scroll' }}>
//       {tests?.map((test, index) => (
//         <Box key={index} sx={{ mb: 2, p: 2, border: '1px solid #ccc', borderRadius: '4px' }}>
//           <Typography variant="h6" sx={{ color: '#EF492E' }}>{test.name.slice(0, 30)}...</Typography>
//           <Typography variant="body1">{test.description.slice(0, 50)}...</Typography>
//           <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
//             <Button width={'30%'} variant="outlined" startIcon={<EditIcon />} sx={{ color: "#EF492E" }} onClick={() => handleOpenModal(test)}>
//               Edit
//             </Button>
//             <Button width={'30%'} variant="outlined" startIcon={<DeleteIcon />} sx={{ color: "#EF492E" }} onClick={() => handleDeleteTest(test._id)}>
//               Delete
//             </Button>
//             <Button width={'30%'} variant="outlined" startIcon={<VisibilityIcon />} sx={{ color: "#EF492E" }} onClick={() => handleGo(test)}>
//               Go
//             </Button>
//           </Box>
//         </Box>
//       ))}

//       <Button
//         variant="contained"
//         startIcon={<AddIcon />}
//         sx={{ mt: 2, width: '100%', backgroundColor: '#EF492E', ":hover": { backgroundColor: '#EF492E' } }}
//         onClick={() => handleOpenModal()}
//       >
//         Add Resource
//       </Button>

//       {/* Modal for PDF Viewer */}
//       <Modal open={isPdfViewerOpen} onClose={handleClosePdfViewer}>
//         <Box
//           onContextMenu={(e) => e.preventDefault()} // Disable right-click
//           sx={{
//             p: 2,
//             backgroundColor: 'white',
//             borderRadius: '8px',
//             height: '90%',
//             minWidth:'50%',
//             maxWidth: '1000px',
//             margin: 'auto',
//             position: 'absolute',
//             top: '50%',
//             left: '50%',
//             transform: 'translate(-50%, -50%)', // Center the modal
//             overflowY: 'scroll',
//           }}
//         >
//           <Pdf_viewer url={viewerContent?.url} />
//         </Box>
//       </Modal>

//       {/* Modal for Image Viewer */}
//       <Modal open={isImageViewerOpen} onClose={handleCloseImageViewer}>
//         <Box sx={{ p: 2, backgroundColor: 'white', borderRadius: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80%', height: '80%', margin: 'auto', position: 'absolute', top: '10%', left: '10%',display:'flex',flexDirection:'column',backgroundColor:'wheat' }}>
//           <Typography variant='h5'>{viewerContent?.name}</Typography>
//           <img
//             src={viewerContent.url}
//             alt="Image Viewer"
//             style={{ maxWidth: '100%', maxHeight: '100%' }}
//             onContextMenu={(e) => e.preventDefault()}  // Prevent right-click
//             draggable="false"  // Prevent drag
//           />
//         </Box>
//       </Modal>
//       {/* Modal for Resource */}
//       <Modal open={isModalOpen} onClose={handleCloseModal}>
//         <Box sx={{ backgroundColor: 'white', borderRadius: '8px', width: '500px', margin: 'auto', position: 'absolute', top: '20%', left: '35%',paddingX:'20px',paddingBottom:'10px' }}>
//           <AddTest courseId={courseId} test={selectedTest} onClose={handleCloseModal} onUpdate={handleUpdateTests} setTests={setTests} />
//         </Box>
//       </Modal>
//     </Box>
//   );
// };

// export default TestComponent;


import React, { useState } from 'react';
import { Box, Button, Typography, Modal } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddTest from './AddTest';
import { apiConnector } from '../../services/ApiConnector';
import { testEndpoints } from '../../services/ApiEndPoints';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';

const TestComponent = ({ tests, courseId, setTests }) => {
  const token = useSelector((state) => state.auth.token);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTest, setSelectedTest] = useState(null);

  const handleOpenModal = (test = null) => {
    setSelectedTest(test);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedTest(null);
    setIsModalOpen(false);
  };

  const handleDeleteTest = async (testId) => {
    const flag = window.confirm("Are you sure? You want to delete the Test?");
    
    if (flag) {
      try {
        const response = await apiConnector(
          "DELETE",
          `${testEndpoints.DELETE_TEST}/${testId}`,
          null,
          { Authorization: `Bearer ${token}` }
        );
        if (response?.data?.success) {
          setTests((prevTests) => prevTests.filter((test) => test._id !== testId));
          toast.success("Test deleted successfully.");
        } else {
          toast.error("Failed to delete the test.");
        }
      } catch (error) {
        console.error("Error deleting the test:", error);
        toast.error(`Error: ${error.message}`);
      }
    }
  };

  const handleUpdateTests = (updatedTest,type) => {
    if (type == "update") {
      // Update existing test
      setTests((prevTests) => 
        prevTests.map((test) => test._id === updatedTest._id ? updatedTest : test)
      );
    } else {
      // Add new test
      setTests((prevTests) => [...prevTests, updatedTest]);
    }
  };

  return (
    <Box sx={{height:'70vh'}}>
      {tests?.map((test, index) => (
        <Box key={index} sx={{ mb: 2, p: 2, border: '1px solid #ccc', borderRadius: '4px' }}>
          <Typography variant="h6" sx={{ color: '#EF492E' }}>{test.name.slice(0, 30)}...</Typography>
          <Typography variant="body1">{test.description.slice(0, 50)}...</Typography>
          <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
            <Button width={'30%'} variant="outlined" startIcon={<EditIcon />} sx={{ color: "#EF492E" }} onClick={() => handleOpenModal(test)}>
              Edit
            </Button>
            <Button width={'30%'} variant="outlined" startIcon={<DeleteIcon />} sx={{ color: "#EF492E" }} onClick={() => handleDeleteTest(test._id)}>
              Delete
            </Button>
            <Button width={'30%'} variant="outlined" startIcon={<VisibilityIcon />} sx={{ color: "#EF492E" }} onClick={() => window.open(test?.url, '_blank')}>
              Go
            </Button>
          </Box>
        </Box>
      ))}

      <Button
        variant="contained"
        startIcon={<AddIcon />}
        sx={{ mt: 2, width: '100%', backgroundColor: '#EF492E', ":hover": { backgroundColor: '#EF492E' } }}
        onClick={() => handleOpenModal()}
      >
        Add Resource
      </Button>

      {/* Modifying its name to resouce and it will not only contain test link it will 
      contain the url of any doc or any other website link */}

      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box sx={{ p: 2, backgroundColor: 'white', borderRadius: '8px', width: '500px', margin: 'auto', position: 'absolute',top:'10%', left: '35%' }}>
          <AddTest courseId={courseId} test={selectedTest} onClose={handleCloseModal} onUpdate={handleUpdateTests} setTests={setTests} />
        </Box>
      </Modal>
    </Box>
  );
};

export default TestComponent;