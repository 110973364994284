import { toast } from "react-hot-toast"; 
import { apiConnector } from '../ApiConnector';
import { counselorEndpoints } from '../ApiEndPoints';
import { setCounselors, appendCounselors, setLoading, clearCounselors, setSearchTerm } from '../../Store/Slices/counselorSlice';

// Fetch Counselors (with Pagination and Search)
export function fetchCounselors(token, page, limit, searchTerm, reset = false) {
    return async (dispatch, getState) => {
        const { searchTerm: previousSearchTerm } = getState().counselors;

        if (reset || searchTerm !== previousSearchTerm) {
            dispatch(clearCounselors());
            dispatch(setSearchTerm(searchTerm));
        }

        const toastId = toast.loading("Loading...");
        dispatch(setLoading(true));

        try {
            const response = await apiConnector(
                "GET",
                `${counselorEndpoints.GET_ALL_COUNSELORS}?page=${page}&perPage=${limit}&search=${searchTerm}`,
                null,
                { Authorization: `Bearer ${token}` }
            );
        
            if (response.status !== 200) {
                throw new Error("Could not fetch counselor data");
            }

            const result = response?.data?.data;
            const totalCounselors = response.data.totalCounceller;
            const totalPages = response.data.totalPages;

            if (reset || searchTerm !== previousSearchTerm || page === 1) {
                dispatch(setCounselors({ counselors: result, totalCounselors, totalPages, currentPage: page, searchTerm }));
            } else {
                dispatch(appendCounselors({ counselors: result, totalCounselors, totalPages, currentPage: page, searchTerm }));
            }

            toast.success("Counselors fetched successfully");
        } catch (error) {
            toast.error(`Error! ${error?.response?.data?.message}`);
            console.error("FETCH COUNSELORS ERROR....", error);
        } finally {
            dispatch(setLoading(false));
            toast.dismiss(toastId);
        }
    };
}

// Delete a Counselor
export function deleteCounselor(token, counselorId) {
    return async (dispatch, getState) => {
        const toastId = toast.loading("Deleting counselor...");
        try {
            const response = await apiConnector("DELETE", `${counselorEndpoints.DELETE_COUNSELOR_DETAIL}/${counselorId}`, null, {
                Authorization: `Bearer ${token}`
            });

            if (response.status !== 200) {
                throw new Error("Could not delete counselor");
            }

            const updatedCounselors = getState().counselors.counselors.filter((counselor) => counselor._id !== counselorId);
            const totalCounselors = getState().counselors.totalCounselors - 1;
            dispatch(setCounselors({ counselors: updatedCounselors, totalCounselors, totalPages: getState().counselors.totalPages, currentPage: getState().counselors.currentPage }));
            toast.success("Counselor deleted successfully");
        } catch (error) {
            toast.error(`Error! ${error?.response?.data?.message}`);
            console.error("DELETE COUNSELOR ERROR....", error);
        } finally {
            toast.dismiss(toastId);
        }
    };
}

// Add a Counselor
export function addCounselor(token, counselorData, navigate) {
    return async (dispatch, getState) => {
        const toastId = toast.loading("Adding counselor...");
        try {
            const response = await apiConnector("POST", counselorEndpoints.ADD_COUNSELOR, counselorData, {
                Authorization: `Bearer ${token}`
            });

            if (response.status !== 201) {
                throw new Error("Could not add counselor");
            }

            const newCounselor = response?.data?.data;
            toast.success("Counselor added successfully");
            navigate('/counselors');
        } catch (error) {
            toast.error(`Error! ${error?.response?.data?.message}`);
            console.error("ADD COUNSELOR ERROR....", error);
        } finally {
            toast.dismiss(toastId);
        }
    };
}

// Fetch Counselor Profile
export const fetchCounselorProfile = async (token, counselorId) => {
    const toastId = toast.loading("Fetching counselor profile...");

    try {
        const response = await apiConnector(
            "GET",
            `${counselorEndpoints.GET_COUNSELOR_DETAIL}/profile/${counselorId}`,
            null,
            { Authorization: `Bearer ${token}` }
        );

        if (response.status !== 200) {
            throw new Error("Could not fetch counselor profile");
        }

        const counselorProfile = response?.data?.data;
        toast.success("Counselor profile fetched successfully");

        return counselorProfile;
    } catch (error) {
        toast.error(`Error! ${error?.response?.data?.message || 'Something went wrong'}`);
        console.error("FETCH COUNSELOR PROFILE ERROR:", error);
    } finally {
        toast.dismiss(toastId);
    }
};


// Update Counselor
export const updateCounselor = async (token, counselorId, counselorData) => {
    const toastId = toast.loading("Updating counselor...");

    try {
        const response = await apiConnector(
            "PUT",
            `${counselorEndpoints.UPDATE_COUNSELOR_DETAIL}/${counselorId}`,
            counselorData,
            { Authorization: `Bearer ${token}` }
        );

        if (response.status !== 200) {
            throw new Error("Could not update counselor");
        }

        toast.success("Counselor updated successfully");

        return response.data.data;
    } catch (error) {
        toast.error(`Error! ${error?.response?.data?.message || 'Something went wrong'}`);
        console.error("UPDATE COUNSELOR ERROR:", error);
    } finally {
        toast.dismiss(toastId);
    }
};

//update permission 
export const updateCounselorPermissions = async (token, counselorId, includedPermissions, revokedPermissions) => {
    const toastId = toast.loading("Updating permissions...");

    try {
        const response = await apiConnector(
            "PUT",
            `${counselorEndpoints.UPDATE_COUNSELOR_PERMISSION}/${counselorId}`,
            {
                permissionsToAdd:includedPermissions,
                permissionsToRemove:revokedPermissions,
            },
            { Authorization: `Bearer ${token}` }
        );

        if (response.status !== 200) {
            throw new Error("Could not update permissions");
        }

        toast.success("Permissions updated successfully");

        return response.data.data;
    } catch (error) {
        toast.error(`Error! ${error?.response?.data?.message || 'Something went wrong'}`);
        console.error("UPDATE PERMISSIONS ERROR:", error);
    } finally {
        toast.dismiss(toastId);
    }
};

//fetch councellor students...
export const fetchClientsOfCounselor = async (token, counselorId, page = 1, perPage = 10, search = "") => {
    const toastId = toast.loading("Fetching clients...");

    try {
        // Build query parameters for pagination and search
        const queryParams = new URLSearchParams({
            page: page.toString(),
            perPage: perPage.toString(),
            search: search,
        });

        const response = await apiConnector(
            "GET",
            `${counselorEndpoints.GET_COUNSELOR_STUDENTS}/${counselorId}?${queryParams.toString()}`,
            null, // No data to send in the GET request
            { Authorization: `Bearer ${token}` }
        );

        if (response.status !== 200) {
            throw new Error("Could not fetch clients");
        }

        toast.success("Clients fetched successfully");

        return response.data 
    } catch (error) {
        toast.error(`Error! ${error?.response?.data?.message || 'Something went wrong'}`);
        console.error("FETCH COUNSELOR CLIENTS ERROR:", error);
    } finally {
        toast.dismiss(toastId);
    }
};
