import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Button,
  TextField,
  Box,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  Select,
  MenuItem,
  Modal,
  Card,
  CardContent,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { toast } from 'react-hot-toast'
import { useSelector } from "react-redux";
import { useDebounce } from "use-debounce";
import SchoolDropdown from "../../common/SchoolSelectorDropdown";
import { apiConnector } from "../../services/ApiConnector";
import { courseEndpoints } from "../../services/ApiEndPoints";
import StudentModal from "../../components/courses/course-resipants/StudentInsertModal"; // Import the new StudentModal component

const CourseStudent = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const { courseId } = useParams(); // Extract courseId from URL
  const [studentsPerPage, setStudentsPerPage] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [studentsData, setStudentsData] = useState([]);
  const [school, setSchool] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 800); // Debouncing search input
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [courseName, setCourseName] = useState("");
  const [totalStudents, setTotalStudents] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [isCardOpen, setIsCardOpen] = useState(false);

  const fetchStudents = async (currentPage = 1, searchQuery = "", reset = false) => {
    setLoading(true);
    const toastId = toast.loading("fetching Enrolled Students")
    try {
      const response = await apiConnector(
        "GET",
        `${courseEndpoints.COURSE_STUDENTS}/${courseId}?schoolId=${school?._id}&page=${currentPage}&limit=${studentsPerPage}&searchQuery=${searchQuery}`,
        null,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );
      if (reset) {
        setStudentsData(response?.data.students);
      } else {
        setStudentsData((prev) => [...prev, ...response?.data.students]);
      }
      toast.success("Successfully fetched students")
      setCourseName(response?.data.courseName || "Course");
      setTotalPages(response?.data.pagination.totalPages || 1);
      setTotalStudents(response?.data.pagination.totalStudents || 0);
    } catch (error) {
      toast.error("failed to fetch the students")
      console.error("Failed to fetch students", error);
    } finally {
      setLoading(false);
      toast.dismiss(toastId)
    }
  };

  useEffect(() => {
    if (courseId) {
      fetchStudents(1, debouncedSearchTerm, true); // Reset and fetch new data
    }
  }, [courseId, studentsPerPage, school, debouncedSearchTerm]);

  useEffect(() => {
    if (currentPage > 1) {
      fetchStudents(currentPage, debouncedSearchTerm); // Load more data when page changes
    }
  }, [currentPage]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to page 1 when search term changes
  };

  const handlePageSizeChange = (event) => {
    setStudentsPerPage(event.target.value);
    setCurrentPage(1); // Reset to page 1 when page size changes
  };

  const handleLoadMore = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleMoreClick = (student) => {
    setSelectedStudent(student);
    setIsCardOpen(true);
  };

  const EnrollInCourse = async (studentIds) => {
    const toastId = toast.loading("Loading...");
    try {
      const response = await apiConnector(
        "POST",
        `${courseEndpoints.COURSE_BULK_USER_INSERT}`,
        { userIds: studentIds, courseId, role: "student" },
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );
      console.log(response)
      // Show success toast
      toast.success("Enrolled Successfully! Please refresh to see results");
      fetchStudents(1,"",true);
    } catch (error) {
      toast.error(`Failed to Enroll in Course`);
      toast.dismiss(toastId);
      console.error(error);
    }finally{
      toast.dismiss(toastId);
    }
  };

  const handleRemoveFromCourse = async (studentId) => {
    const toastId = toast.loading("Removing student...");
    try {
      const response = await apiConnector(
        "POST",
        `${courseEndpoints.COURSE_REMOVE_USER}`, // Replace with the correct endpoint for removing a user
        { userId: studentId, courseId, role: "student" }, // Include courseId and role if necessary
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );
      console.log(response);
      setIsCardOpen(false)
      // Show success toast
      toast.success("Removed Successfully! Please refresh to see results");
      fetchStudents(1, "", true); // Refresh the student list or adjust this based on your implementation
    } catch (error) {
      toast.error(`Failed to Remove from Course`);
      console.error(error);
    } finally {
      toast.dismiss(toastId);
    }
  };

  const handleEnroll = (students,setSelectedStudents) => {
    console.log("Enrolled Students:", students);
    setIsModalOpen(false);
    setSelectedStudents([]);
    let studentIds = students?.map((student) => student._id);
    console.log(studentIds)
    EnrollInCourse(studentIds)
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

 

  return (
    <Container sx={{ mt: 4 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
        <Typography variant="h4">
          {courseName}
          <Typography component="span" color="textSecondary" sx={{ fontSize: "0.875rem" }}>
            ({totalStudents} Students)
          </Typography>
        </Typography>
        <Button
          variant="contained"
          sx={{ backgroundColor: "#EF492E", color: "#fff", ml: 1, "&:hover": { backgroundColor: "#D44428" } }}
          startIcon={<AddIcon />}
          onClick={() => setIsModalOpen(true)}
        >
          Add New Student
        </Button>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <TextField
          size="small"
          variant="outlined"
          placeholder="Search Students"
          sx={{ width: "30%" }}
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <Box sx={{ width: "20%" }}>
          <SchoolDropdown setSchool={setSchool} school={school} size="small" />
        </Box>
      </Box>

      <TableContainer component={Paper} sx={{ borderRadius: "8px", border: "1px solid #f5a48b", marginTop: "10px", overflowX: "auto" }}>
        <Table sx={{ tableLayout: "auto" }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#FFBE9D" }}>
              <TableCell sx={{ fontWeight: "bold" }} width={"50px"}>S.No</TableCell>
              <TableCell sx={{ fontWeight: "bold", cursor: "pointer" }} width={"150px"}>Name</TableCell>
              <TableCell sx={{ fontWeight: "bold" }} width={"250px"}>Email</TableCell>
              <TableCell sx={{ fontWeight: "bold" }} width={"250px"}>UserId</TableCell>
              <TableCell sx={{ fontWeight: "bold" }} width={"60px"}>Class</TableCell>
              <TableCell sx={{ fontWeight: "bold" }} width={"200px"}>School</TableCell>
              <TableCell sx={{ fontWeight: "bold" }} width={"100px"}>City</TableCell>
              <TableCell sx={{ fontWeight: "bold" }} width={"50px"}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {studentsData?.length > 0 ? (
              studentsData?.map((student, index) => (
                <TableRow key={student._id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{student.name}</TableCell>
                  <TableCell>{student.email}</TableCell>
                  <TableCell>{student.userId}</TableCell>
                  <TableCell>{student.class}</TableCell>
                  <TableCell>{student?.schoolId?.name}</TableCell>
                  <TableCell>{student.city}</TableCell>
                  <TableCell>
                    <Button onClick={() => handleMoreClick(student)}>More</Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  No students found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box mt={3} display={"flex"} justifyContent={"end"} alignItems={"center"} gap={"10px"} textAlign="center">
        <Typography>Page - {currentPage}/{totalPages}</Typography>
        {currentPage < totalPages && (
          <Button variant="outlined" color="primary" onClick={handleLoadMore} disabled={loading}>
            Load More
          </Button>
        )}
        <FormControl>
          <Select value={studentsPerPage} onChange={handlePageSizeChange} displayEmpty size="small">
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Modal for Add New Student */}
      <StudentModal
        courseId={courseId}
        open={isModalOpen}
        onClose={handleCancel}
        onEnroll={handleEnroll}
      />

      {/* Card for More Actions */}
      <Modal
        open={isCardOpen}
        onClose={() => setIsCardOpen(false)}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Card sx={{ width: 300 }}>
          <CardContent>
            <Typography variant="h6" textAlign={'center'}>Student Actions</Typography>
            <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'center' }}>
              <Button variant="outlined" onClick={()=>{navigate(`/studentprofile/${selectedStudent._id}`)}}>
                View
              </Button>
              <Button variant="contained" color="error" onClick={() =>{handleRemoveFromCourse(selectedStudent._id)}}>
                Remove
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Modal>
    </Container>
  );
};

export default CourseStudent;
