// src/components/MainLayout.js
import React, { useEffect, useState } from "react";
import { Box, CssBaseline } from "@mui/material";
import Sidebar from "../common/Sidebar";
import Header from "../common/Navbar";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

const MainLayout = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  // Access token from Redux state
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    // Redirect to login if no token is present
    if (!token) {
      navigate('/login');
    } else {
      setLoading(false); // Token exists, proceed to render the layout
    }
  }, [token, navigate]);

  if (loading) {
    return <div>loading</div>; // Optionally show a loading indicator
  }
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <CssBaseline />
      <Sidebar />
      <Box
        component="main"
        sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: '10px' }}
      >
        <Header />
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;