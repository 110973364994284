import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    counselors: [],    
    loading: false,     
    totalCounselors: 0, 
    totalPages: 0,      
    currentPage: 1,     
    searchTerm: '',     
};

const counselorsSlice = createSlice({
    name: 'counselors',
    initialState,
    reducers: {
        setCounselors(state, action) {
            state.counselors = action.payload.counselors;
            state.totalCounselors = action.payload.totalCounselors;
            state.totalPages = action.payload.totalPages;
            state.currentPage = action.payload.currentPage;
            state.loading = false;
        },
        appendCounselors(state, action) {
            state.counselors = [...state.counselors, ...action.payload.counselors];
            state.totalCounselors = action.payload.totalCounselors;
            state.totalPages = action.payload.totalPages;
            state.currentPage = action.payload.currentPage;
            state.loading = false;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        clearCounselors(state) {
            state.counselors = [];
            state.totalCounselors = 0;
            state.totalPages = 0;
            state.currentPage = 1;
        },
        setSearchTerm(state, action) {
            state.searchTerm = action.payload;
        },
        updateCurrentPage(state, action) {
            state.currentPage = action.payload;
        }
    },
});

export const { setCounselors, appendCounselors, setLoading, clearCounselors, setSearchTerm, updateCurrentPage } = counselorsSlice.actions;
export default counselorsSlice.reducer;
