import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    teachers: [],
    loading: false,
    totalTeachers: 0,
    totalPages: 0,
    currentPage: 1,
    schoolId: '',  // Added schoolId field
    searchTerm: '',  // Added searchTerm field
};

const teachersSlice = createSlice({
    name: 'teachers',
    initialState,
    reducers: {
        setTeachers(state, action) {
            state.teachers = action.payload.teachers;
            state.totalTeachers = action.payload.totalTeachers;
            state.totalPages = action.payload.totalPages;
            state.currentPage = action.payload.currentPage;
            state.schoolId = action.payload.schoolId || state.schoolId; // Update schoolId if provided
            state.loading = false;
        },
        appendTeachers(state, action) {
            state.teachers = [...state.teachers, ...action.payload.teachers];
            state.totalTeachers = action.payload.totalTeachers;
            state.totalPages = action.payload.totalPages;
            state.currentPage = action.payload.currentPage;
            state.loading = false;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        clearTeachers(state) {
            state.teachers = [];
            state.totalTeachers = 0;
            state.totalPages = 0;
            state.currentPage = 1;
            state.schoolId = '';  // Reset schoolId when clearing teachers
            state.searchTerm = '';  // Reset searchTerm when clearing teachers
        },
        setSchoolId(state, action) {
            state.schoolId = action.payload;
        },
        setSearchTerm(state, action) {
            state.searchTerm = action.payload;
        },
        updateCurrentPage(state, action) {
            state.currentPage = action.payload;
        }
    },
});

export const {
    setTeachers,
    appendTeachers,
    setLoading,
    clearTeachers,
    setSchoolId,
    setSearchTerm,
    updateCurrentPage,
} = teachersSlice.actions;

export default teachersSlice.reducer;
