import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Menu, MenuItem, Button, Typography, Toolbar, TextField, AppBar, Box, InputAdornment, FormControl, Select } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { green, red } from "@mui/material/colors";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import {toast} from 'react-hot-toast'


// Pagination ..
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from 'react-redux';
import { fetchCourses } from '../../services/Operations/CourseOperation';
import { clearCourses, setCourses, setSearchTerm, updateCurrentPage } from '../../Store/Slices/courseSlice';
import { useDebounce } from 'use-debounce';
import { courseEndpoints } from "../../services/ApiEndPoints";
import { apiConnector } from "../../services/ApiConnector";



const CourseTable = ({ courses = [], totalCourses, currentPage, totalPages,coursesPerPage }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState({});
  const [selectedCourse, setSelectedCourse] = React.useState(null);

  const handleMenuClick = (event, course) => {
    setAnchorEl((prev) => ({ ...prev, [course._id]: event.currentTarget }));
    setSelectedCourse(course); // Store the full course object
  };

  const handleMenuClose = (courseId) => {
    setAnchorEl((prev) => ({ ...prev, [courseId]: null }));
    setSelectedCourse(null);
  };

  const handleView = (id) => {
    navigate(`/course/${id}`);
    handleMenuClose(id);
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this course?")) {
      const toastId = toast.loading("Deleting Course!");

      try {
        // Set up the API endpoint for deleting the course
        const endpoint = `${courseEndpoints.DELETE_COURSE}/${selectedCourse._id}`;

        // Make the API call to delete the course
        await apiConnector(
          "DELETE",
          endpoint,
          {},
          { Authorization: `Bearer ${token}` }
        );

        // Show success message
        toast.success("Course Deleted Successfully");

        // Filter out the deleted course from the current courses list
        const updatedCourses = courses.filter(
          (course) => course._id !== selectedCourse._id
        );

        // Dispatch the updated course data to Redux
        dispatch(
          setCourses({
            courses: updatedCourses,
            totalCourses: totalCourses - 1, // Update the total count of courses
            totalPages: Math.ceil((totalCourses - 1) / coursesPerPage), // Adjust based on new total
            currentPage: currentPage, // Keep current page the same or adjust if needed
          })
        );
      } catch (error) {
        console.error("Error deleting course:", error);
        toast.error("Failed to delete Course");
      } finally {
        toast.dismiss(toastId);
        handleMenuClose(selectedCourse._id);
      }
    }
  };

  // Copy COurse

  const handleCourseCopy = async (courseId) => {
    if(!window.confirm("Are you Sure you want to copy this Course")){
         return;
    }
    const toastId = toast.loading("Copying Course...");
  
    try {
      const copyEndpoint = `${courseEndpoints.COPY_COURSE}/${courseId}`;
  
      // Make the API call to copy the course
      const response = await apiConnector(
        "POST",
        copyEndpoint,
        {},
        { Authorization: `Bearer ${token}` }
      );
      //add courseName from response
      // Show success toast message
      toast.success("Course copied successfully!");
  
      //Update the state ....of Course
    } catch (error) {
      console.error("Error copying course:", error);
  
      // Show error toast message
      toast.error("Failed to copy course.");
    } finally {
      // Dismiss the loading toast
      toast.dismiss(toastId);
    }
  };
  

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: "#FFBE9D" }}>
            <TableCell sx={{ fontWeight: "bold" }}>Sr. No</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Course Name</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Category</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Sub-Category</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Language</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Id</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {courses.map((course, index) => (
            <TableRow key={course?._id || index}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{course?.name || "N/A"}</TableCell>
              <TableCell>{course?.category || "N/A"}</TableCell>
              <TableCell>{course?.subCategory || "N/A"}</TableCell>
              <TableCell>{course?.language || "N/A"}</TableCell>
              <TableCell>{course?._id || "N/A"}</TableCell>
              <TableCell>
                <IconButton onClick={(event) => handleMenuClick(event, course)}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl[course?._id]}
                  open={Boolean(anchorEl[course?._id])}
                  onClose={() => handleMenuClose(course?._id)}
                >
                   <MenuItem onClick={() => handleCourseCopy(course?._id)}>
                    Copy Course
                  </MenuItem>
                  <MenuItem onClick={() => handleView(course?._id)}>
                    View Course
                  </MenuItem>
                  <MenuItem onClick={() =>{navigate(`/course-students/${selectedCourse._id}`)}}>
                    Students
                  </MenuItem>
                  <MenuItem onClick={() =>{navigate(`/course-teachers/${selectedCourse._id}`)}}>
                    teachers
                  </MenuItem>
                  <MenuItem onClick={handleDelete}>Delete</MenuItem>
                </Menu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

function App() {
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Ensure useNavigate is properly initialized
  const { courses, totalCourses, currentPage, loading, totalPages, searchTerm } = useSelector((state) => state.courses);
  const [coursePerPage,setCoursePerPage] =useState(10); // Adjust this number as needed
  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);

  const handleChange = (event) => {
    setCoursePerPage(event.target.value);
  };

  // Effect for handling search term change
  useEffect(() => {
    const fetchCourseData = async () => {
      dispatch(fetchCourses(token, 1, coursePerPage, debouncedSearchTerm, true)); // Reset Course on new search term
    };

    if (!loading) {
      fetchCourseData();
    }
  }, [debouncedSearchTerm, dispatch,coursePerPage]);

  // Effect for handling pagination
  useEffect(() => {
    const fetchCourseData = async () => {
      dispatch(fetchCourses(token, currentPage, coursePerPage, debouncedSearchTerm));
    };

    if (!loading && currentPage > 1) {
      fetchCourseData();
    }
  }, [currentPage, debouncedSearchTerm, dispatch]);

  // Effect for handling component unmount
  useEffect(() => {
    return () => {
      dispatch(clearCourses());
    };
  }, [dispatch]);

  // Handle Load More functionality
  const handleLoadMore = () => {
    if (currentPage < totalPages) {
      dispatch(updateCurrentPage(currentPage + 1));
    }
  };

  // Handle search term change
  const handleSearchChange = (e) => {
    dispatch(setSearchTerm(e.target.value));
    dispatch(updateCurrentPage(1)); // Reset to first page on new search
  };

  return (
    <div style={{padding:'20px'}}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={5}>
        <Typography variant="h5" fontWeight="bold">
          All Courses <Typography component="span">({totalCourses} Courses Available)</Typography>
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <TextField
            variant="outlined"
            placeholder="Search Course"
            size="small"
            onChange={handleSearchChange}
            value={searchTerm}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="error"
            startIcon={<AddIcon />}
            sx={{ backgroundColor: "#EF492E" }}
            onClick={()=>{navigate('/add-course')}}
          >
            Add Course
          </Button>
        </Box>
      </Box>
      <CourseTable courses={courses} totalCourses={totalCourses} currentPage={currentPage}  totalPages={totalPages} coursesPerPage={coursePerPage} />
      <Box mt={3} display={'flex'} justifyContent={'end'} alignItems={'center'} gap={'10px'} textAlign="center">
        <Typography>Page -{currentPage}/{totalPages}</Typography>
        <Button
          onClick={handleLoadMore}
          disabled={currentPage >= totalPages}
          variant="outlined"
          color="primary"
        >
          Load More
        </Button>
        {/* page size */}
        <FormControl>

          <Select
            value={coursePerPage}
            onChange={handleChange}
            displayEmpty
            size="small"
            renderValue={(value) => `Page Size - ${value}`}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>

        </FormControl>
      </Box>
    </div>
  );
}

export default App;



