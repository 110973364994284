import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import {uploadEnpoints} from '../services/ApiEndPoints';
import {useSelector} from 'react-redux'

const useAwsFileUpload = () => {
  const token = useSelector((state)=>state.auth.token)
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);

  const uploadFileToAws = async (file) => {
    
    if (!file) {
      setError("No file selected");
      toast.error("Please select a file to upload.");
      return null;
    }
    const toastId =toast.loading("uploading image")

    setUploading(true);
    setError(null);

    try {
      const formData = new FormData();
      formData.append('file', file); // 'file' is the key your backend expects

      const response = await axios.post(`${uploadEnpoints.UPLOAD_FILE_TO_AWS}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: (progressEvent) => {
          const progressPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(progressPercentage);
        },
      });

      const fileUrl = response?.data?.data;
      toast.success("File uploaded successfully!");
      return fileUrl; // Returning the uploaded file's URL
    } catch (uploadError) {
      console.error("Error uploading file:", uploadError);
      setError(uploadError.message);
      toast.error("Failed to upload file.");
      return null;
    } finally {
      setUploading(false);
      setProgress(0); // Reset progress after upload
      toast.dismiss(toastId)
    }
  };

  return { uploadFileToAws, uploading, progress, error };
};

export default useAwsFileUpload;
