import React from 'react'
import { Box } from '@mui/material'
import Navbar from '../../common/Navbar'
import Sidebar from '../../common/Sidebar'
import StudentProfile from '../../components/student/StudentProfile'

function StudentProfilePage() {
  return (
    <>
     
        <StudentProfile />
      
    </>
  );
}

export default StudentProfilePage;
