import React, { useState } from 'react';
import {
  Box, Button, Checkbox, FormControlLabel, IconButton, InputAdornment,
  TextField, Typography, Dialog, DialogActions, DialogContent, DialogTitle
} from '@mui/material';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { login } from '../../services/Operations/AuthOperations';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { authEndpoints } from '../../services/ApiEndPoints';
import { apiConnector } from '../../services/ApiConnector';


const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [openForgotPassword, setOpenForgotPassword] = useState(false); // Modal state for forgot password
  const [forgotEmail, setForgotEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(email, password);
    dispatch(login(email, password, navigate));
  };

  const handleForgotPassword = () => {
    setOpenForgotPassword(true);
  };

 

  const handleForgotPasswordSubmit = async () => {
    if (forgotEmail === "") {
      toast.error("Email field can't be empty");
      return;
    }
  
    const toastId = toast.loading("Connecting to Server...");
  
    try {
      const data = { email: forgotEmail };
  
      // Assuming you have an endpoint for forgot password
      const response = await apiConnector(
        "PUT",
        `${authEndpoints.FORGET_PASSWORD}`,
        data,
        {
          "Content-Type": "application/json",
        }
      );
      
      if (response?.data?.status) {
        toast.success("Password reset email sent successfully");
      } else {
        throw new Error("Something went wrong. Please try again.");
      }
    } catch (error) {
      toast.error(`Failed to send reset email: ${error.message}`);
    } finally {
      toast.dismiss(toastId);
    }
  };
  

  return (
    <Box
      sx={{
        width: '90%',
        minHeight: '300px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      {/* Login Form */}
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          backgroundColor: 'white',
          borderRadius: '8px',
          width: '100%',
          maxWidth: '400px',
          padding: '20px',
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          sx={{ fontWeight: 'bold', color: '#1A1A1A', mb: 2 }}
        >
          Welcome!
        </Typography>
        {/* Email Input */}
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            required
            label="Email or Phone"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
            sx={{ backgroundColor: 'rgba(0, 0, 0, 0.04)', borderRadius: '4px' }}
          />
        </Box>
        {/* Password Input */}
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            required
            label="Password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ backgroundColor: 'rgba(0, 0, 0, 0.04)', borderRadius: '4px' }}
          />
        </Box>
        {/* Remember Me & Forgot Password */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <FormControlLabel
            control={
              <Checkbox checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />
            }
            label="Remember Me"
          />
          <Typography
            sx={{ color: '#EF492E', cursor: 'pointer' }}
            onClick={handleForgotPassword}
          >
            Forgot Password?
          </Typography>
        </Box>
        {/* Sign In Button */}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{
            backgroundColor: '#EF492E',
            color: 'white',
            textTransform: 'none',
            '&:hover': { backgroundColor: '#de3f27' },
          }}
        >
          Sign In
        </Button>
      </Box>

      {/* Forgot Password Modal */}
      <Dialog open={openForgotPassword} onClose={() => setOpenForgotPassword(false)}>
        <DialogTitle>Forgot Password</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Enter your email"
            type="email"
            required={true}
            fullWidth
            variant="outlined"
            value={forgotEmail}
            onChange={(e) => setForgotEmail(e.target.value)}
          />
          <Typography sx={{ mt: 2 }}>
            You will receive your new password on your registered email.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenForgotPassword(false)}>Cancel</Button>
          <Button onClick={handleForgotPasswordSubmit} color="primary">Submit</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default LoginForm;


// import React, { useState } from 'react';
// import {
//   Box, Button, Checkbox, FormControlLabel, IconButton, InputAdornment,
//   TextField, Typography, Dialog, DialogActions, DialogContent, DialogTitle
// } from '@mui/material';
// import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
// import { useNavigate } from 'react-router-dom';
// import { login } from '../../services/Operations/AuthOperations';
// import { useDispatch } from 'react-redux';
// import toast from 'react-hot-toast';
// import { authEndpoints } from '../../services/ApiEndPoints';
// import { apiConnector } from '../../services/ApiConnector';

// const LoginForm = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [rememberMe, setRememberMe] = useState(false);
//   const [showPassword, setShowPassword] = useState(false);
//   const [openForgotPassword, setOpenForgotPassword] = useState(false); // Modal state for forgot password
//   const [forgotEmail, setForgotEmail] = useState('');
//   const [role, setRole] = useState('admin'); // New state for role

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log(email, password, role);
//     dispatch(login(email, password, navigate));
//   };

//   const handleForgotPassword = () => {
//     setOpenForgotPassword(true);
//   };

//   const handleForgotPasswordSubmit = async () => {
//     if (forgotEmail === "") {
//       toast.error("Email field can't be empty");
//       return;
//     }

//     const toastId = toast.loading("Connecting to Server...");

//     try {
//       const data = { email: forgotEmail };

//       // Assuming you have an endpoint for forgot password
//       const response = await apiConnector(
//         "PUT",
//         `${authEndpoints.FORGET_PASSWORD}`,
//         data,
//         {
//           "Content-Type": "application/json",
//         }
//       );
      
//       if (response?.data?.status) {
//         toast.success("Password reset email sent successfully");
//       } else {
//         throw new Error("Something went wrong. Please try again.");
//       }
//     } catch (error) {
//       toast.error(`Failed to send reset email: ${error.message}`);
//     } finally {
//       toast.dismiss(toastId);
//     }
//   };

//   // Handle role switching with a smooth transition effect
//   const handleRoleSwitch = (newRole) => {
//     setRole(newRole);
//   };

//   return (
//     <Box
//       sx={{
//         width: '90%',
//         minHeight: '300px',
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         flexDirection: 'column',
//       }}
//     >
//       {/* Login Form */}
//       <Box
//         component="form"
//         onSubmit={handleSubmit}
//         sx={{
//           backgroundColor: 'white',
//           borderRadius: '8px',
//           width: '100%',
//           maxWidth: '400px',
//           padding: '20px',
//         }}
//       >
//         <Typography
//           variant="h5"
//           component="h2"
//           sx={{ fontWeight: 'bold', color: '#1A1A1A', mb: 2 }}
//         >
//           Welcome!
//         </Typography>
        
       
//         {/* Email Input */}
//         <Box sx={{ mb: 2 }}>
//           <TextField
//             fullWidth
//             required
//             label="Email or Phone"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             variant="outlined"
//             sx={{ backgroundColor: 'rgba(0, 0, 0, 0.04)', borderRadius: '4px' }}
//           />
//         </Box>
        
//         {/* Password Input */}
//         <Box sx={{ mb: 2 }}>
//           <TextField
//             fullWidth
//             required
//             label="Password"
//             type={showPassword ? 'text' : 'password'}
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             InputProps={{
//               endAdornment: (
//                 <InputAdornment position="end">
//                   <IconButton onClick={() => setShowPassword(!showPassword)}>
//                     {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
//                   </IconButton>
//                 </InputAdornment>
//               ),
//             }}
//             sx={{ backgroundColor: 'rgba(0, 0, 0, 0.04)', borderRadius: '4px' }}
//           />
//         </Box>

//          {/* Role Tabs */}
//          <Box sx={{ display: 'flex', justifyContent: 'start', mb: 2 }}>
//           <Button
//             variant={role === 'admin' ? 'contained' : 'outlined'}
//             onClick={() => handleRoleSwitch('admin')}
//             sx={{ transition: 'background-color 0.3s', mr: 1 }}
//           >
//             Admin
//           </Button>
//           <Button
//             variant={role === 'counceller' ? 'contained' : 'outlined'}
//             onClick={() => handleRoleSwitch('counceller')}
//             sx={{ transition: 'background-color 0.3s' }}
//           >
//             Counselor
//           </Button>
//         </Box>

//         {/* Remember Me & Forgot Password */}
//         <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
//           <FormControlLabel
//             control={
//               <Checkbox checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />
//             }
//             label="Remember Me"
//           />
//           <Typography
//             sx={{ color: '#EF492E', cursor: 'pointer' }}
//             onClick={handleForgotPassword}
//           >
//             Forgot Password?
//           </Typography>
//         </Box>

//         {/* Sign In Button */}
//         <Button
//           type="submit"
//           fullWidth
//           variant="contained"
//           sx={{
//             backgroundColor: '#EF492E',
//             color: 'white',
//             textTransform: 'none',
//             '&:hover': { backgroundColor: '#de3f27' },
//           }}
//         >
//           Sign In
//         </Button>
//       </Box>

//       {/* Forgot Password Modal */}
//       <Dialog open={openForgotPassword} onClose={() => setOpenForgotPassword(false)}>
//         <DialogTitle>Forgot Password</DialogTitle>
//         <DialogContent>
//           <TextField
//             autoFocus
//             margin="dense"
//             label="Enter your email"
//             type="email"
//             required={true}
//             fullWidth
//             variant="outlined"
//             value={forgotEmail}
//             onChange={(e) => setForgotEmail(e.target.value)}
//           />
//           <Typography sx={{ mt: 2 }}>
//             You will receive your new password on your registered email.
//           </Typography>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenForgotPassword(false)}>Cancel</Button>
//           <Button onClick={handleForgotPasswordSubmit} color="primary">Submit</Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// };

// export default LoginForm;

