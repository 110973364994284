import React, { useEffect, useState } from 'react';
import { TextField, Button, Grid, Typography, MenuItem, Box, InputAdornment, FormLabel, FormControl, RadioGroup, Radio, FormControlLabel, InputLabel, Select } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CalendarTodayTwoToneIcon from '@mui/icons-material/CalendarTodayTwoTone';
import CourseSelectedDropdown from '../../common/CourseSelectedDropdown';
import { toast } from 'react-hot-toast';
import { apiConnector } from '../../services/ApiConnector';
import { surveyEndpoints } from '../../services/ApiEndPoints';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useAwsFileUpload from '../../hooks/useAwsImageUpload'
// import useFirebaseImageUpload from '../../hooks/useFirebaseImageUpload'
import ReactQuill from 'react-quill';
import LectureSelector from '../../common/LectureSelectedDropdown';


const surveyType = [
  { id: 1, name: 'quiz' },
  { id: 2, name: 'survey' }
];
const NewSurveyForm = () => {
  const {uploadFileToAws, uploading} = useAwsFileUpload();
  // const { uploadImageToFirebase, uploading } = useFirebaseImageUpload();
  const location = useLocation();
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const courseId = urlParams.get('course-id');
  const courseName = urlParams.get('course-name');
  const lectureName = urlParams.get('lecture-name');
  const lectureId = urlParams.get('lectureId');


  const [course, setCourse] = useState(null);
  const [fromCourse, setFromCourse] = useState(false);

  useEffect(() => {
    if (courseId && courseName) {
      setFromCourse(true);
      setCourse({
        _id: courseId,
        name: courseName,
      });
    } else {
      setFromCourse(false);
    }
  }, [courseId, courseName]);

  useEffect(() => {
    if (lectureId && lectureName) {
      setLecture({
        _id: lectureId,
        title: lectureName,
      });
    } 
  }, [lectureId, lectureName]);

  const token = useSelector((state) => state.auth.token);
  const [surveyTitle, setSurveyTitle] = useState('');
  const [description, setDescription] = useState('');
  const [instruction, setInstruction] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [surType, setSurType] = useState('quiz');
  const [selectedImage, setSelectedImage] = useState(null);
  const [passingMarks, setPassingMarks] = useState(null);
  const [duration, setDuration] = useState(null);
  const [lecture, setLecture] = useState(null);
  // const [course, setCourse] = useState(null);

  const navigate = useNavigate();

  console.log(lecture)

  const handleCreateSurvey = async (e) => {
    const toastId = toast.loading("Creating Assessments...");
    e.preventDefault();

    try {
      let imageUrl = "";

      if (selectedImage) {
        // Use the hook to upload the image
        imageUrl = await uploadFileToAws(selectedImage);
        if (!imageUrl) {
          throw new Error("Failed to upload image");
        }
      }
      const surveyData = {
        name: surveyTitle,
        description: description,
        Instruction: instruction,
        startDate: startDate ? startDate.toISOString() : "",
        endDate: endDate ? endDate.toISOString() : "",
        courseId: course?._id,
        type: surType,
        image: imageUrl,
        duration: "",
        passingMark: "",
        lectureId:lecture?lecture._id:""
      };
      // If the type is quiz, add duration and passingMarks
      if (surType === "quiz") {
        surveyData.duration = duration;
        surveyData.passingMark = passingMarks;
      }

      const response = await apiConnector(
        "POST",
        `${surveyEndpoints.CREATE_SURVEY}`, // Replace with your actual survey creation endpoint
        surveyData,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Ensure token is available in your context
        }
      );

      toast.dismiss(toastId);
      toast.success("Assessments Created Successfully!");

      if (fromCourse) navigate(`/course/${course?._id}`);
      else navigate(`/survey/${response?.data?.data?._id}`);
    } catch (error) {
      toast.dismiss(toastId);
      toast.error("Failed to create Assessments. Please try again.");
      console.error("Error creating Assessments:");
    }
  };

  const handleImageUpload = (e) => {
    if (e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };

 

  return (
    <Box sx={{ maxWidth: 800, margin: 'auto', padding: 4, boxShadow: 2, borderRadius: 2 }}>
      <Typography variant="h4" align="center" gutterBottom color="#EF492E" fontWeight={'600'}>
        New Assessments
      </Typography>
      <form onSubmit={handleCreateSurvey}>
        <Grid container spacing={3}>

          <Grid item xs={12} sm={6}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel required >Assessments Type:</FormLabel>
              <RadioGroup
                row // This makes the radio buttons display in a row
                value={surType}
                onChange={(e) => setSurType(e.target.value)}
                sx={{
                  border: '1px solid #ccc',  // Adds a border
                  borderRadius: '8px',       // Rounds the corners of the border
                  padding: '9px',
                  paddingLeft: '10px'        // Adds padding inside the border
                }}
              >
                {surveyType.map((surveyTy) => (
                  <FormControlLabel
                    key={surveyTy.id}
                    value={surveyTy?.name}
                    control={<Radio size="small" />}
                    label={surveyTy.name}
                    sx={{ marginRight: '16px' }} // Adds space between each radio button
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>



          <Grid item xs={12} sm={6}>
            <FormLabel required>Assessments Title</FormLabel>
            <TextField
              
              fullWidth
              required
              variant="outlined"
              value={surveyTitle}
              onChange={(e) => setSurveyTitle(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              
              fullWidth
              required
              label="Description"
              variant="outlined"
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel required sx={{ fontWeight: 'bold', fontSize: '14px' }}>Instruction </InputLabel>
            <ReactQuill
              theme="snow"
              value={instruction}
              onChange={(content) => { setInstruction(content); }} // `content` is the updated value
              style={{ height: '150px', marginBottom: '40px' }}
            />
          </Grid>
          {/* Row with Image Upload, File Name, and Date Pickers */}
          <Grid item xs={12} container alignItems="center" spacing={2}>
          {/* image has been removed according to instruction */}
            {/* <Grid item xs={12} sm={3}>
              <Button
                variant="outlined"
                component="label"
                sx={{ color: "black", borderColor: "black", width: '100%', padding: '10px', backgroundColor: '#D9D9D9' }}
              >
                <AttachFileIcon />
                Choose image
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleImageUpload}
                />
              </Button>
            </Grid> */}

            {/* <Grid item xs={12} sm={3}>
              <Typography sx={{ color: '#8C94A3' }}>
                {selectedImage ? selectedImage.name : 'No file chosen'}
              </Typography>
            </Grid> */}

            <Grid item xs={12} sm={6}>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                customInput={
                  <TextField
                    label="Start Date"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CalendarTodayTwoToneIcon sx={{ fontSize: 20 }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                }
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                customInput={
                  <TextField
                    label="End Date"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CalendarTodayTwoToneIcon sx={{ fontSize: 18 }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                }
              />
            </Grid>
          </Grid>

          {/* Passing marks and duration only visible when the type is quiz */}
          {
            surType == 'quiz' &&
            <>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  type='number'
                  label="Passing-Marks-percentage"
                  variant="outlined"
                  value={passingMarks}
                  onChange={(e) => setPassingMarks(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  
                  fullWidth
                  required
                  type='number'
                  label="Duration(In minutes)"
                  variant="outlined"
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                />
              </Grid>
            </>
          }

          <CourseSelectedDropdown  course={course} setCourse={setCourse}  />

        
          <LectureSelector  lecture={lecture} setLecture={setLecture} courseId={course?._id}/>

          <Grid item xs={12} align="end">
            <Button type="submit" variant="contained" size="large" sx={{
              backgroundColor: '#EF492E', color: 'white', ":hover": {
                backgroundColor: '#EF492E'
              }
            }} >
              Create Assessments
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default NewSurveyForm;
