import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardMedia, Typography, Box } from '@mui/material';

const CustomCard = ({ title, textColor, image, number, bg, route }) => {
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        width: '32%',
        minHeight: '130px',
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer',
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
          transform: 'scale(1.05)',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        },
        backgroundColor: bg,
        borderRadius: '16px',
      }}
      onClick={() => navigate(route)}
    >
      <Box sx={{ width: '65%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', p: 2 }}>
        <CardMedia
          component="img"
          sx={{ width: '40px', height: '40px' }}
          image={image}
          alt={title}
        />
        <Typography variant="h6" sx={{ color: textColor, fontWeight: 'bold', mt: 1 }}>
          {title}
        </Typography>
      </Box>
      <Box sx={{ width: '35%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box
          sx={{
            width: '70px',
            height: '70px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            border: `6px solid ${textColor}`,
            color: textColor,
            fontSize: '25px',
            fontWeight: 'bold',
          }}
        >
          {number}
        </Box>
      </Box>
    </Card>
  );
};

export default CustomCard;
