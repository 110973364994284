import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TextField, Button, Typography, Box } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { fetchClientsOfCounselor } from '../../../services/Operations/CounselorOperation';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

const ClientsTable = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const { id } = useParams();

  const [clientsData, setClientsData] = useState([]);
  const [page, setPage] = useState(1);           // Current page
  const [perPage] = useState(5);                // Items per page
  const [search, setSearch] = useState("");      // Search input
  const [debouncedSearch] = useDebounce(search, 500); // Debouncing the search with 500ms delay
  const [totalClients, setTotalClients] = useState(0); // Total number of clients
  const [isLoadingMore, setIsLoadingMore] = useState(false); // Loading more state
  const [totalPage, setTotalPage] = useState(0);  // Total pages

  // Fetch clients data
  const fetchClients = async (newPage = page) => {
    const response = await fetchClientsOfCounselor(token, id, newPage, perPage, debouncedSearch);
    
    if (newPage === 1) {
      // For the first page, replace the data
      setClientsData(response?.data);
    } else {
      // For subsequent pages, append new data
      setClientsData(prevData => [...prevData, ...response?.data]);
    }
     
    setTotalPage(response?.totalPages);  // Assuming totalPages is returned from API
    setTotalClients(response?.totalCounceller);  // Assuming totalCounceller is the total number of clients
    setIsLoadingMore(false);  // Reset loading state after fetching
  };

  // Fetch clients whenever search or page changes
  useEffect(() => {
    setPage(1); // Reset to the first page when search changes
    fetchClients(1); // Fetch clients for the first page
  }, [debouncedSearch]);

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  // Handle "Load More" click
  const handleLoadMore = () => {
    setIsLoadingMore(true);
    setPage(prevPage => {
      const newPage = prevPage + 1;
      fetchClients(newPage); // Fetch clients for the next page
      return newPage;
    });
  };

  console.log(clientsData)

  return (
    <Box minHeight={'70vh'}>
      {/* Search input */}
      <TextField 
        label="Search Clients" 
        variant="outlined" 
        fullWidth 
        value={search} 
        onChange={handleSearchChange} 
        sx={{ marginBottom: 2 }}
      />

      {/* Clients Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#FFBE9D" }}>
              <TableCell sx={{ fontWeight: 'bold' }}>Sr. No</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Email</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Class</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>City</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clientsData?.map((client, index) => (
              <TableRow key={client._id}>
                <TableCell>{index + 1}</TableCell> {/* Sequential numbering */}
                <TableCell>{client?.name}</TableCell>
                <TableCell>{client?.email}</TableCell>
                <TableCell>{client?.class}</TableCell>
                <TableCell>{client?.city}</TableCell>
                <TableCell>
                <IconButton color="primary" onClick={()=>{
                     window.open(`/studentprofile/${client?._id}`,"_blank")
                }}>
                  <VisibilityIcon />
                </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Current Page and Total Page Info */}
      <Typography sx={{ marginTop: 2, textAlign: 'center' }}>
        Page {page} of {totalPage}
      </Typography>

      {/* Load More Button */}
      {clientsData.length < totalClients && ( // Show Load More button if there are more clients to load
        <Button 
          variant="contained" 
          fullWidth 
          onClick={handleLoadMore} 
          disabled={isLoadingMore} 
          sx={{ marginTop: 2 }}
        >
          {isLoadingMore ? "Loading..." : "Load More"}
        </Button>
      )}
    </Box>
  );
};

export default ClientsTable;
