import React, { useState } from 'react';
import { toast } from "react-hot-toast"
import {
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Grid,
  Box,
  Paper,
  Container,
  Input,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import styles for react-quill
import ViewCourse from '../../components/courses/ViewCourse';
import { courseEndpoints } from "../../services/ApiEndPoints";
import { apiConnector } from "../../services/ApiConnector";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router';

// import useFirebaseImageUpload from '../../hooks/useFirebaseImageUpload'
import useAwsFileUpload from '../../hooks/useAwsImageUpload'
function NewCourseForm() {
  const {uploadFileToAws, uploading} = useAwsFileUpload();
  // const { uploadImageToFirebase } = useFirebaseImageUpload();  
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const [loading,setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    category: '',
    subCategory: '',
    language: '',
    startDate: null,
    endDate: null,
    status: '',
    image:"",
  });
  const [courseImage,setCourseImage] = useState(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleDescriptionChange = (value) => {
    setFormData({
      ...formData,
      description: value,
    });
  };

  const handleDateChange = (date, field) => {
    setFormData({
      ...formData,
      [field]: date,
    });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    const toastId = toast.loading("Loading...");
    setLoading(true);
    // Convert startDate and endDate to ISO strings
    try {
      let imageUrl = "";

      if (courseImage) {
        // Use the hook to upload the image
        imageUrl = await uploadFileToAws(courseImage);
        if (!imageUrl) {
          throw new Error("Failed to upload image");
        }
      }
      const formattedFormData = {
        ...formData,
        image:imageUrl,
        startDate: formData.startDate ? formData.startDate.toISOString() : '',
        endDate: formData.endDate ? formData.endDate.toISOString() : '',
        status:"active"?true:false
      };
      const response = await apiConnector(
        "POST",
        `${courseEndpoints.CREATE_COURSE}`,
        formattedFormData,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );
      console.log(response)
  
      // Dismiss the loading toast
      toast.dismiss(toastId);
  
      // Show success toast
      toast.success("course Created Successfully!", {
        id: toastId, // You can reuse the same ID if needed, or remove this line
      });

      setLoading(false)
      navigate(`/course/${response?.data?.data?._id}`)
    } catch (error) {
      // Dismiss the loading toast
      toast.dismiss(toastId);
      setLoading(false);
      // Show error toast
      toast.error(`Failed to create Course.${error?.response?.data?.message}`, {
        id: toastId, // You can reuse the same ID if needed, or remove this line
      });
     setLoading(false)
      console.error("Error creating Course:", error);
    }
  };


  const handlePhotoChange = (e) => {
    setCourseImage(e.target.files[0]);
  };
  

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
           <Paper elevation={3} sx={{ mb: '20px', p: 4, maxWidth: 800, mx: 'auto', mt: 4 }}>
              <Typography variant="h4" align="center" gutterBottom fontWeight={'600'} color="#EF492E">
                New Course
             </Typography>
             <form onSubmit={handleSubmit}>
                 <Grid container spacing={3}>

                  <Grid item xs={6}>
                   <InputLabel required sx={{ fontWeight: 'bold', fontSize: '14px' }}>Course Title</InputLabel>
                     <TextField
                      size="small"
                      fullWidth
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel sx={{ fontWeight: 'bold', fontSize: '14px' }}>Select Thumbnail Image</InputLabel>
                    <Input
                      type="file"
                      sx={{border:'1px solid gray',paddingBottom:'5px',paddingLeft:'20px',borderRadius:'5px'}}
                      fullWidth
                      name="image"
                      onChange={handlePhotoChange} // Adjust this handler to manage file uploads
                      inputProps={{ accept: 'image/*' }} // Restrict to image files
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel sx={{ fontWeight: 'bold', fontSize: '14px' }}>Course Description</InputLabel>
                    <ReactQuill
                      theme="snow"
                      value={formData.description}
                      onChange={handleDescriptionChange}
                      style={{ height: '150px', marginBottom: '40px' }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <InputLabel required sx={{ fontWeight: 'bold', fontSize: '14px' }}>Course Category</InputLabel>
                    <TextField
                      size="small"
                      fullWidth
                      name="category"
                      value={formData.category}
                      onChange={handleChange}
                      variant="outlined"
                      required
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <InputLabel sx={{ fontWeight: 'bold', fontSize: '14px' }}>Course Sub-category</InputLabel>
                    <TextField
                      size="small"
                      fullWidth
                      name="subCategory"
                      value={formData.subCategory}
                      onChange={handleChange}
                      variant="outlined"
                      
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <InputLabel required sx={{ fontWeight: 'bold', fontSize: '14px' }}>Language</InputLabel>
                    <FormControl fullWidth size="small" variant="outlined" required>
                      <Select
                        name="language"
                        value={formData.language}
                        onChange={handleChange}
                      >
                        <MenuItem value="">Select...</MenuItem>
                        <MenuItem value="english">English</MenuItem>
                        <MenuItem value="spanish">Spanish</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <InputLabel sx={{ fontWeight: 'bold', fontSize: '14px' }}>Status</InputLabel>
                    <FormControl fullWidth size="small" variant="outlined" >
                      <Select
                        name="status"
                        value={formData.status}
                        onChange={handleChange}
                      >
                        <MenuItem value="">Select...</MenuItem>
                        <MenuItem value="active">Active</MenuItem>
                        <MenuItem value="inactive">Inactive</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <InputLabel sx={{ fontWeight: 'bold', fontSize: '14px' }}>Start Date</InputLabel>
                    <DatePicker
                      value={formData.startDate}
                      onChange={(date) => handleDateChange(date, 'startDate')}
                      renderInput={(params) => <TextField {...params} fullWidth size="small" variant="outlined" />}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <InputLabel sx={{ fontWeight: 'bold', fontSize: '14px' }}>End Date</InputLabel>
                    <DatePicker
                      value={formData.endDate}
                      onChange={(date) => handleDateChange(date, 'endDate')}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          size="small"
                          variant="outlined"
                          InputProps={{
                            sx: {
                              padding: '6px 10px', // Adjust the padding for height
                              fontSize: '14px',    // Adjust the font size if needed
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>


                  <Grid item xs={12} sx={{ textAlign: 'right' }}>
                    <Button variant="contained" sx={{ backgroundColor: '#EF492E', ":hover": { backgroundColor: '#EF492E' } }} type="submit" disabled={loading}>
                      Create Course
                    </Button>
                    <Button variant="outlined" color="secondary" sx={{ ml: 2 }} onClick={()=>{navigate('/courses')}}>
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </LocalizationProvider>
  );
}

export default NewCourseForm;
