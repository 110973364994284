import React, { useState, useEffect } from 'react';
import { Grid, FormControl, TextField, Autocomplete, CircularProgress, Button, Box, MenuItem } from '@mui/material';
import { useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';
import { apiConnector } from '../services/ApiConnector';
import { schoolEndpoints } from '../services/ApiEndPoints';

const SchoolDropdown = ({size,school, setSchool,handleSchoolChange }) => {
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1); // Pagination state
  const [totalPages, setTotalPages] = useState(1); // Track total pages from the API
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebounce(searchTerm, 800); // Debouncing search term with a 500ms delay
  const token = useSelector((state) => state.auth.token);
  


  const fetchSchools = async (currentPage = 1, searchTerm = '', reset = false) => {
    setLoading(true);
    try {
      const response = await apiConnector(
        'GET',
        `${schoolEndpoints.GET_SCHOOL_NAME}?page=${currentPage}&limit=10&searchTerm=${searchTerm}`,
        null,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );
      if (reset) {
        setSchoolOptions(response.data.data);
      } else {
        setSchoolOptions((prevOptions) => [...prevOptions, ...response.data.data]);
      }
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching schools:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSchools(1, debouncedSearchTerm, true); // Fetch initial page with debounced search term
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (page > 1) {
      fetchSchools(page, debouncedSearchTerm); // Fetch next page with debounced search term
    }
  }, [page]);

  const handleLoadMore = () => {
    if (page < totalPages) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setPage(1); // Reset pagination when search term changes
  };

  return (
    <Grid item xs={12} sm={6}>
      <FormControl  fullWidth variant="outlined">
        <Autocomplete
         fullWidth
          size={size?size:''}
          required={true}
          options={schoolOptions}
          getOptionLabel={(option) => option.name || ''}
          loading={loading}
          value={school}
          onChange={(event, newValue) => {
            setSchool(newValue)
           if(handleSchoolChange) handleSchoolChange(newValue)
          }}
          renderInput={(params) => (
            <TextField
            size='small'
              {...params}
              label="School"
              variant="outlined"
              required
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              onChange={handleSearchChange}
            />
          )}
          ListboxComponent={(props) => (
            <Box {...props} sx={{ '& ul': { padding: 0 } }}>
              {props.children}
              {page < totalPages && (
                <MenuItem>
                  <Button variant="contained" onClick={handleLoadMore} fullWidth disabled={loading}>
                    {loading ? 'Loading...' : 'Load More'}
                  </Button>
                </MenuItem>
              )}
            </Box>
          )}
        />
      </FormControl>
    </Grid>
  );
};

export default SchoolDropdown;
