import { toast } from "react-hot-toast";
import { apiConnector } from '../ApiConnector';
import { schoolEndpoints } from '../ApiEndPoints';
import { setSchools, appendSchools, setLoading, clearSchools, setSearchTerm } from '../../Store/Slices/schoolSlice';

export function fetchSchools(token,page, limit, searchTerm, reset = false) {
  return async (dispatch, getState) => {
    const { searchTerm: previousSearchTerm } = getState().schools;

    if (reset || searchTerm !== previousSearchTerm) {
      dispatch(clearSchools());
      dispatch(setSearchTerm(searchTerm));
    }

    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));

    try {
      const response = await apiConnector("GET", `${schoolEndpoints.ALL_SCHOOLS_DATA_API}?page=${page}&limit=${limit}&searchTerm=${searchTerm}`, null, {Authorization: `Bearer ${token}`});
      if (response.status !== 200) {
        throw new Error("Could not fetch school data");
      }

      const result = response?.data?.data;
      const totalSchools = response.data.totalSchools;
      const totalPages = response.data.totalPages;
      
      if (reset || searchTerm !== previousSearchTerm || page === 1) {
        dispatch(setSchools({ schools: result, totalSchools, totalPages, currentPage: page, searchTerm }));
      } else {
        dispatch(appendSchools({ schools: result, totalSchools, totalPages, currentPage: page, searchTerm }));
      }

      toast.success("Schools fetched successfully");
    } catch (error) {
      toast.error(`Error! ${error?.response?.data?.message}`)
      console.error("FETCH SCHOOLS ERROR....", error);
    } finally {
      dispatch(setLoading(false));
      toast.dismiss(toastId);
    }
  };
}
