// import React from 'react';
// import { Box, colors, Grid } from '@mui/material';
// import templateImage from '../../data/login_page_image.jpeg';
// import LoginForm from './LoginForm';
// import SignupForm from './SignUpForm';
// import ApplicationLogo from '../../data/Logo.png'

// const Template = ({ formType }) => {
//   return (
//     <Box
//       sx={{
//         display: 'flex',
//         minHeight: '100vh',
//         width: '100%',
//         position:'relative',
//         backgroundColor:'red'
//       }}
//     >
//       <Grid container>
//         {/* Left Side - Image */}
//         <Grid
//           item
//           xs={12}
//           md={7.5}
//           sx={{
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             height: '100vh',

//           }}
//         >
//           <Box
//             component="img"
//             src={templateImage}
//             alt="AuthTemplateImage"
//             sx={{
//               width: '100%',
//               height: '100vh',
//               objectFit: 'cover',
//             }}
//           />
//         </Grid>

//         {/* Right Side - Form */}
//         <Grid
//           item
//           xs={12}
//           md={4.5}
//           sx={{
//             position: 'relative',
//             display: 'flex',
//             flexDirection: 'column',
//             justifyContent: 'center',
//             alignItems: 'center',
//             minHeight: '100vh',
//           }}
//         >
//           {formType === 'signup' ? <SignupForm /> : <LoginForm />}
//           <Box sx={{ position: 'absolute', bottom: '10px', display: 'flex', alignItems: 'center',color:'gray',fontWeight:'bold' }}>
//             <span >@ Powered by SN-Mentoring</span>
//           </Box>
//         </Grid>
//       </Grid>
//       <img src={ApplicationLogo} alt="SN-Mentoring Logo" style={{ position:'relative',top:0,left:10,width:"150px",height:'50px' }} />
//     </Box>
//   );
// };

// export default Template;

import React from 'react';
import { Box, Grid } from '@mui/material';
import templateImage from '../../data/login_page_image.jpeg';
import LoginForm from './LoginForm';
import SignupForm from './SignUpForm';
import ApplicationLogo from '../../data/Logo.png';

const Template = ({ formType }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
        width: '100%',
        position: 'relative',
      }}
    >
      <Grid container>
        {/* Left Side - Image */}
        <Grid
          item
          xs={12}
          md={7.5}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <Box
            component="img"
            src={templateImage}
            alt="AuthTemplateImage"
            sx={{
              width: '100%',
              height: '100vh',
              objectFit: 'cover',
            }}
          />
        </Grid>

        {/* Right Side - Form */}
        <Grid
          item
          xs={12}
          md={4.5}
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
          }}
        >
          {formType === 'signup' ? <SignupForm /> : <LoginForm />}
          <Box
            sx={{
              position: 'absolute',
              bottom: '10px',
              display: 'flex',
              alignItems: 'center',
              color: 'gray',
              fontWeight: 'bold',
            }}
          >
            <span>@ Powered by SN-Mentoring</span>
          </Box>
        </Grid>
      </Grid>

      {/* Top-Left Logo */}
      <img
        src={ApplicationLogo}
        alt="SN-Mentoring Logo"
        style={{
          position: 'absolute',
          top: '10px',
          left: '40px',
          height: '70px',
        }}
      />
    </Box>
  );
};

export default Template;
