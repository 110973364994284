import React from 'react'
import { Box } from '@mui/material';
import Sidebar from '../../common/Sidebar'
import Navbar from '../../common/Navbar'
import SchoolProfile from "../../components/school/SchoolProfile";

export default function SchoolProfilePage() {
  return (
   
        <SchoolProfile />
      
  );
}
