import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  InputAdornment,
  IconButton,
  Grid
} from "@mui/material";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import useAwsFileUpload from '../../hooks/useAwsImageUpload'
import { useDispatch, useSelector } from "react-redux";
import { addBrand } from "../../services/Operations/BrandOperation";
 import {useNavigate} from 'react-router-dom'
const AddBrand = () => {
  const navigate = useNavigate()
  const token = useSelector((state)=>state.auth.token)
  const dispatch = useDispatch();
  const {uploadFileToAws, uploading} = useAwsFileUpload();
  const [brandName, setBrandName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (e) => {
    if (e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const handleSubmit = async() => {
    console.log({ brandName, description, selectedImage });
    // upload image to aws s3...
    let imageUrl = await uploadFileToAws(selectedImage);
    if (!imageUrl) {
      throw new Error("Failed to upload image");
    }
    dispatch(addBrand(token,{
        title:brandName,
        description,
        logo:imageUrl
    },navigate));
  };

  
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        width:'80%',
        marginX:'auto'
      }}
    >
      <Typography variant="h4" color="error" fontWeight="bold" gutterBottom>
        Add New Brand
      </Typography>

      <Paper
        elevation={3}
        sx={{
          padding: "20px",
          width: "100%",
        }}
      >
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          Brand Details
        </Typography>

        <Box sx={{ mt: 3 }}>
          {/* Brand Name */}
          <TextField
            fullWidth
            label="Brand Name"
            variant="outlined"
            value={brandName}
            onChange={(e) => setBrandName(e.target.value)}
            placeholder="Enter Brand Name"
            margin="normal"
          />

          <Grid container spacing={2} mt={1}>
            {/* Image Upload */}
            <Grid item xs={12} md={6}>
              <Button
                fullWidth
                sx={{paddingY:'10px'}}
                variant="outlined"
                component="label"
                startIcon={<AttachFileIcon />}
              >
                Choose Image
                <input
                  size={'medium'}
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </Button>
              <Typography variant="caption">
              {selectedImage?selectedImage.name:"Supported Files: jpg, png"}
              </Typography>
            </Grid>
          </Grid>

          {/* Description */}
          <TextField
            fullWidth
            label="Description"
            variant="outlined"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter Description"
            multiline
            rows={4}
            margin="normal"
          />

          <Box
            display="flex"
            justifyContent="space-between"
            mt={3}
          >
            <Button variant="outlined" color="secondary">
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleSubmit}
            >
              Save Brand
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default AddBrand;
