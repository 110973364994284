import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Button,
  TextField,
  Box,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  Select,
  MenuItem,
  Modal,
  Card,
  CardContent,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { toast } from 'react-hot-toast'
import { useSelector } from "react-redux";
import { useDebounce } from "use-debounce";
import SchoolDropdown from "../../common/SchoolSelectorDropdown";
import { apiConnector } from "../../services/ApiConnector";
import { courseEndpoints } from "../../services/ApiEndPoints"; // Change to teacher endpoints
import TeacherModal from "../../components/courses/course-resipants/TeacherInsertModal"; // Import Teacher Modal

const CourseTeacher = () => { // Change to CourseTeacher
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const { courseId } = useParams(); // Extract courseId from URL
  const [teachersPerPage, setTeachersPerPage] = useState(10); // Change to teachersPerPage
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [teachersData, setTeachersData] = useState([]); // Change to teachersData
  const [school, setSchool] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 800); // Debouncing search input
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [courseName, setCourseName] = useState("");
  const [totalTeachers, setTotalTeachers] = useState(0); // Change to totalTeachers
  const [loading, setLoading] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState(null); // Change to selectedTeacher
  const [isCardOpen, setIsCardOpen] = useState(false);

  const fetchTeachers = async (currentPage = 1, searchQuery = "", reset = false) => { // Change to fetchTeachers
    setLoading(true);
    const toastId = toast.loading("Fetching Enrolled Teachers");
    try {
      const response = await apiConnector(
        "GET",
        `${courseEndpoints.COURSE_TEACHERS}/${courseId}?schoolId=${school?._id}&page=${currentPage}&limit=${teachersPerPage}&searchQuery=${searchQuery}`, // Change to teacherEndpoints
        null,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );
      if (reset) {
        setTeachersData(response?.data.teachers); // Change to teachersData
      } else {
        setTeachersData((prev) => [...prev, ...response?.data.teachers]); // Change to teachersData
      }
      toast.success("Successfully fetched teachers");
      setCourseName(response?.data.courseName || "Course");
      setTotalPages(response?.data.pagination.totalPages || 1);
      setTotalTeachers(response?.data.pagination.totalTeachers || 0); // Change to totalTeachers
    } catch (error) {
      toast.error("Failed to fetch the teachers");
      console.error("Failed to fetch teachers", error);
    } finally {
      setLoading(false);
      toast.dismiss(toastId);
    }
  };

  useEffect(() => {
    if (courseId) {
      fetchTeachers(1, debouncedSearchTerm, true); // Reset and fetch new data
    }
  }, [courseId, teachersPerPage, school, debouncedSearchTerm]);

  useEffect(() => {
    if (currentPage > 1) {
      fetchTeachers(currentPage, debouncedSearchTerm); // Load more data when page changes
    }
  }, [currentPage]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to page 1 when search term changes
  };

  const handlePageSizeChange = (event) => {
    setTeachersPerPage(event.target.value); // Change to setTeachersPerPage
    setCurrentPage(1); // Reset to page 1 when page size changes
  };

  const handleLoadMore = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleMoreClick = (teacher) => { // Change to teacher
    setSelectedTeacher(teacher); // Change to setSelectedTeacher
    setIsCardOpen(true);
  };

  const EnrollInCourse = async (teacherIds) => { // Change to teacherIds
    const toastId = toast.loading("Loading...");
    try {
      const response = await apiConnector(
        "POST",
        `${courseEndpoints.COURSE_BULK_USER_INSERT}`, // Change to teacherEndpoints
        { userIds: teacherIds, courseId, role: "teacher" }, // Change role to "teacher"
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );
      console.log(response);
      toast.success("Enrolled Successfully! Please refresh to see results");
      fetchTeachers(1, "", true); // Refresh teacher list
    } catch (error) {
      toast.error(`Failed to Enroll in Course`);
      toast.dismiss(toastId);
      console.error(error);
    } finally {
      toast.dismiss(toastId);
    }
  };

  const handleRemoveFromCourse = async (teacherId) => { // Change to teacherId
    const toastId = toast.loading("Removing teacher...");
    try {
      const response = await apiConnector(
        "POST",
        `${courseEndpoints.COURSE_REMOVE_USER}`, // Replace with teacher remove endpoint
        { userId: teacherId, courseId, role: "teacher" }, // Change role to "teacher"
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );
      console.log(response);
      setIsCardOpen(false);
      toast.success("Removed Successfully! Please refresh to see results");
      fetchTeachers(1, "", true); // Refresh teacher list
    } catch (error) {
      toast.error(`Failed to Remove from Course`);
      console.error(error);
    } finally {
      toast.dismiss(toastId);
    }
  };

  const handleEnroll = (teachers, setSelectedTeachers) => { // Change to teachers and setSelectedTeachers
    setIsModalOpen(false);
    setSelectedTeachers([]);
    let teacherIds = teachers?.map((teacher) => teacher._id); // Change to teacherIds
    EnrollInCourse(teacherIds);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
        <Typography variant="h4">
          {courseName}
          <Typography component="span" color="textSecondary" sx={{ fontSize: "0.875rem" }}>
            ({totalTeachers} Teachers) {/* Change to totalTeachers */}
          </Typography>
        </Typography>
        <Button
          variant="contained"
          sx={{ backgroundColor: "#EF492E", color: "#fff", ml: 1, "&:hover": { backgroundColor: "#D44428" } }}
          startIcon={<AddIcon />}
          onClick={() => setIsModalOpen(true)}
        >
          Add New Teacher {/* Change label */}
        </Button>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <TextField
          size="small"
          variant="outlined"
          placeholder="Search Teachers" // Change placeholder
          sx={{ width: "30%" }}
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <Box sx={{ width: "20%" }}>
          <SchoolDropdown setSchool={setSchool} school={school} size="small" />
        </Box>
      </Box>

      <TableContainer component={Paper} sx={{ borderRadius: "8px", border: "1px solid #f5a48b", marginTop: "10px", overflowX: "auto" }}>
        <Table sx={{ tableLayout: "auto" }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#FFBE9D" }}>
              <TableCell sx={{ fontWeight: "bold" }} width={"50px"}>S.No</TableCell>
              <TableCell sx={{ fontWeight: "bold", cursor: "pointer" }} width={"150px"}>Name</TableCell>
              <TableCell sx={{ fontWeight: "bold" }} width={"250px"}>Email</TableCell>
              <TableCell sx={{ fontWeight: "bold" }} width={"250px"}>UserId</TableCell>
              <TableCell sx={{ fontWeight: "bold" }} width={"60px"}>Qualification</TableCell> {/* Change to Qualification */}
              <TableCell sx={{ fontWeight: "bold" }} width={"150px"}>Teacher Type</TableCell> {/* Change to Teacher Type */}
              <TableCell sx={{ fontWeight: "bold" }} width={"250px"}>School</TableCell> {/* Add relevant fields */}
              <TableCell sx={{ fontWeight: "bold" }} width={"100px"}>City</TableCell>
              <TableCell sx={{ fontWeight: "bold" }} width={"100px"}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teachersData?.length > 0 ? ( // Change to teachersData
              teachersData?.map((teacher, index) => ( // Change to teachers
                <TableRow key={teacher._id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{teacher?.name || "No Name"}</TableCell>
                  <TableCell>{teacher?.email || "N/A"}</TableCell>
                  <TableCell>{teacher?.userId || "N/A"}</TableCell>
                  <TableCell>{teacher?.Qualification[0]?.course}-{(teacher?.Qualification[0]?.degreeType) || "N/A"}</TableCell> {/* Add qualification */}
                  <TableCell>{teacher?.teacherType || "N/A"}</TableCell> {/* Add teacher type */}
                  <TableCell>{teacher?.schoolId?.name || "No School"}</TableCell>
                  <TableCell>{teacher?.city || "N/A"}</TableCell>
                  <TableCell>
                    <Button size="small" variant="outlined" onClick={() => handleMoreClick(teacher)}> {/* Change to handleMoreClick */}
                      More
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  No teachers enrolled yet. {/* Change label */}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box mt={3} display={"flex"} justifyContent={"end"} alignItems={"center"} gap={"10px"} textAlign="center">
        <Typography>Page - {currentPage}/{totalPages}</Typography>
        {currentPage < totalPages && (
          <Button variant="outlined" color="primary" onClick={handleLoadMore} disabled={loading}>
            Load More
          </Button>
        )}
        <FormControl>
          <Select value={teachersPerPage} onChange={handlePageSizeChange} displayEmpty size="small">
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
      </Box>


        {/* Add Modals */}
        <TeacherModal
          onEnroll={handleEnroll}
          onClose={handleCancel}
          open={isModalOpen}
          courseId={courseId}
        />
      <Modal 
       open={isCardOpen}
       onClose={() => setIsCardOpen(false)} 
       sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
       >
        <Card sx={{ width: 300 }}>
          <CardContent>
            <Typography variant="h6" textAlign={'center'}>Teacher Actions</Typography>
            <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'center' }}>
              <Button variant="outlined" onClick={()=>{navigate(`/teacherprofile/${selectedTeacher._id}`)}}>
                View
              </Button>
              <Button variant="contained" color="error" onClick={() =>{handleRemoveFromCourse(selectedTeacher._id)}}>
                Remove
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Modal>
    </Container>
  );
};

export default CourseTeacher; // Change to CourseTeacher
