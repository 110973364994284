import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Box,
} from "@mui/material";
import {
  Home as HomeIcon,
  School as SchoolIcon,
  People as PeopleIcon,
  GroupAdd as GroupAddIcon,
  Notifications as NotificationsIcon,
  ExitToApp as ExitToAppIcon,
  Quiz as QuizIcon,
  LibraryBooks as LibraryBooksIcon,
} from "@mui/icons-material";
import FitbitIcon from '@mui/icons-material/Fitbit';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import Logo from "../data/Logo.png";
import { useNavigate } from "react-router-dom";

import { useDispatch } from 'react-redux'
import { logout } from "../services/Operations/AuthOperations";
const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeElem, setActiveElem] = useState('/')
  const menuItems = [
    { text: "Dashboard", icon: <HomeIcon />, path: '/' },
    { text: "Brands", icon: <FitbitIcon />, path: '/brands' },
    { text: "Counselors", icon: <AccessibilityNewIcon />, path: '/counselors' },
    { text: "Schools", icon: <SchoolIcon />, path: '/schools' },
    { text: "Students", icon: <PeopleIcon />, path: '/students' },
    { text: "Teachers", icon: <GroupAddIcon />, path: '/teachers' },
    { text: "Courses", icon: <LibraryBooksIcon />, path: '/courses' },
    { text: "Assessments", icon: <SchoolIcon />, path: '/surveys' },
    { text: "Reports", icon: <QuizIcon />, path: '/reports' },
    { text: "Certificates", icon: <WorkspacePremiumIcon />, path: '/certifications' },
    { text: "Notifications", icon: <NotificationsIcon />, path: '/notifications' },
  ];

  const handleSignOut = async () => {
    if (window.confirm("Are you Sure you want to Log Out?")) {
      dispatch(logout(navigate));
    }
  }

  return (
    <Drawer
      variant="permanent"
      anchor="left"

      sx={{
        width: 240,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: 240,
          backgroundColor: "#1D2026",
          color: "#fff",
        },
      }}
    >
      <Box display="flex" flexDirection="column" height="100%">
        <Box display="flex" justifyContent="center" alignItems="center" >
          <img
            src={Logo}
            alt="Company Logo"
            style={{ maxWidth: "100%", height: "50px", marginTop: '2px' }}
          />
        </Box>
        <Divider />
        <List>
          {menuItems.map((item, index) => (
            <ListItemButton
              key={index}
              onClick={() => {
                navigate(item.path)
                setActiveElem(item.path)
              }}
              sx={{
                backgroundColor: activeElem === item.path ? '#FF6636' : 'transparent', // Background for active state
                "&:hover": {
                  backgroundColor: activeElem === item.path ? '#FF6636' : '#FF6636', // Background on hover
                  color: "#fff", // Text color on hover
                },
              }}
            >
              <ListItemIcon sx={{ color: "inherit" }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          ))}
          {/* Signout button  */}
          <ListItemButton
            onClick={handleSignOut}
            sx={{
              font: 'bold',
              backgroundColor: 'white',
              color: '#EF492E',
              "&:hover": {
                backgroundColor: "#FF6636",
                color: "#fff",
              },
            }}
          >
            <ListItemIcon sx={{ color: "inherit" }}>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={"Sign Out"} />
          </ListItemButton>
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;


