import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SurveyDetail from '../../components/survey/SurveyDetail'
import {useParams} from 'react-router-dom'
import { useSelector } from 'react-redux'
import {toast} from 'react-hot-toast'
import {surveyEndpoints} from '../../services/ApiEndPoints';
import { apiConnector } from '../../services/ApiConnector'


const SurveyDetailPage = () => {
    const token = useSelector((state) => state.auth.token);
    const [surveyData,setSurveyData] = useState(null);
    const [questions, setQuestions] = useState(null);
    const [questionaireType,setQuestionnaireType] = useState(false);
    const {id} = useParams();

   
    useEffect(() => {
        const fetchSchoolProfile = async () => {
          const toastId = toast.loading("Loading Questionnaire Details...");
          try {
            const response = await apiConnector(
              "GET",
              `${surveyEndpoints.SURVEY_DETAIL}/${id}`,
              null,
              {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              }
            );
            console.log(response)
            setSurveyData(response?.data?.data);
            setQuestions(response?.data?.data?.questions)
            setQuestionnaireType(response?.data?.data?.survey?.type)
            toast.success("Questionnaire Detail loaded successfully");
            
          } catch (error) {
            toast.error(`Failed to load Questionnaire: ${error.message}`);
            console.error("FETCH Questionnaire ERROR:", error);
          } finally {
            toast.dismiss(toastId);
          }
        };
        
        fetchSchoolProfile()
      }, [id,token])


  return (
    <SurveyDetail questionaireType={questionaireType} id={id} questions={questions} setQuestions={setQuestions} surveyData={surveyData} />
  )
}

export default SurveyDetailPage