import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    notifications: [],        // Array to hold notification data
    loading: false,           // Loading state
    totalNotifications: 0,    // Total number of notifications
    totalPages: 0,            // Total number of pages
    currentPage: 1,           // Current page number
    searchTerm: '',           // Search term for filtering notifications
};

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setNotifications(state, action) {
            state.notifications = action.payload.notifications;
            state.totalNotifications = action.payload.totalNotifications;
            state.totalPages = action.payload.totalPages;
            state.currentPage = action.payload.currentPage;
            state.loading = false;
        },
        appendNotifications(state, action) {
            state.notifications = [...state.notifications, ...action.payload.notifications];
            state.totalNotifications = action.payload.totalNotifications;
            state.totalPages = action.payload.totalPages;
            state.currentPage = action.payload.currentPage;
            state.loading = false;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        clearNotifications(state) {
            state.notifications = [];
            state.totalNotifications = 0;
            state.totalPages = 0;
            state.currentPage = 1;
        },
        setSearchTerm(state, action) {
            state.searchTerm = action.payload;
        },
        updateCurrentPage(state, action) {
            state.currentPage = action.payload;
        }
    },
});

export const { setNotifications, appendNotifications, setLoading, clearNotifications, setSearchTerm, updateCurrentPage } = notificationsSlice.actions;
export default notificationsSlice.reducer;
