import React from 'react'
import Template from '../../components/auth/Template';
const Auth = () => {
    const urlPath = window.location.pathname; 
    const string = urlPath.split("/")[1]; 
    
    return (
        <Template
        formType={string}
      />
    )
}

export default Auth