import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, MenuItem } from '@mui/material';
import { apiConnector } from '../../services/ApiConnector';
import { testEndpoints } from '../../services/ApiEndPoints';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
// import useFirebaseImageUpload from "../../hooks/useFirebaseImageUpload";
import useAwsFileUpload from '../../hooks/useAwsImageUpload'
const AddTest = ({ courseId, test, onClose, onUpdate,lectureDetail }) => {
  console.log(test)
  const {uploadFileToAws,uploading} = useAwsFileUpload();
  // const { uploadImageToFirebase, uploading } = useFirebaseImageUpload(); 
  const token = useSelector((state) => state.auth.token);
  const [testDetails, setTestDetails] = useState({
    name: '',
    type: 'url',  // Default type
    content: '',
    description: '',
  });
  const [file, setFile] = useState(null); // For file handling (PDF or Image)

  useEffect(() => {
    if (test) {
      console.log(test)
      setTestDetails({
        name: test.name || '',
        type: test.type || 'url',  // Fetch type if available
        content: test.url || '', //content is url ...
        description: test.description || '',
      });
    }
  }, [test]);

  const handleChange = (e) => {
    setTestDetails({
      ...testDetails,
      [e.target.name]: e.target.value,
    });
  };

  // Handle file selection
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async () => {
    const toastId = toast.loading("Resource adding!");
  
    // Basic validation
    if (!testDetails.name || !testDetails.type ) {
      toast.error("Please fill complete details");
      toast.dismiss(toastId);
      return;  // Stop execution if validation fails
    }
    
    
    // Use a plain object for formData
    const formData = {
      courseId: courseId,
      name: testDetails.name,
      type: testDetails.type,
      description: testDetails.description,
    };
    
    try {
      let fileUrl = "";
  
      // If file is required (for pdf or image), upload the file
      if (testDetails.type !== 'url' && file) {
        fileUrl = await uploadFileToAws(file);
        if (!fileUrl) {
          throw new Error("Failed to upload Resource");
        }
        formData.url = fileUrl;  // Add the file URL to formData
      } else if (testDetails.type === 'url') {
        // Validate the URL field
        if (!test && testDetails.content === "") {
          toast.error("URL can't be empty in case of URL field");
          toast.dismiss(toastId);  // Dismiss the toast
          return;
        }
        formData.url = testDetails.content;  // Append the URL
      }
      
      let response;
      if (test) {
        // Update existing test
        response = await apiConnector("PUT", `${testEndpoints.UPDATE_TEST}/${test._id}`, formData, {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        });
        toast.success("Test updated successfully.");
        onUpdate(response?.data, "update");
      } else {
        // Create new test
        if(lectureDetail){
            formData.lectureDetail=lectureDetail
        }
        response = await apiConnector("POST", testEndpoints.CREATE_TEST, formData, {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        });
        toast.success("Test created successfully.");
        onUpdate(response?.data, "new");
      }
      onClose();  // Close the form after success
    } catch (error) {
      console.error("Failed to save test:", error);
      toast.error(`Failed to save test: ${error.message}`);
    } finally {
      toast.dismiss(toastId);  // Always dismiss the loading toast
    }
  };
  

  return (
    <Box sx={{  width:'100%',paddingY:'20px' }}>
      <Typography textAlign={'center'} variant="h5" mb={2}>{test ? "Edit Resource" : "Add New Resource"}</Typography>
      {
      lectureDetail?._id && <TextField
        required
        fullWidth
        label="Lecture-Name"
        name="name"
        value={lectureDetail.name}
        disabled={true}
        sx={{ mb: 2 }}
      />
      }
      {/* Resource Name */}
      <TextField
        required
        fullWidth
        label="Name"
        name="name"
        value={testDetails.name}
        onChange={handleChange}
        sx={{ mb: 2 }}
      />
      {/* Dropdown for type selection */}
      <TextField
        required
        fullWidth
        select
        label="Type"
        name="type"
        value={testDetails.type}
        onChange={handleChange}
        sx={{ mb: 2 }}
      >
        <MenuItem value="pdf">PDF</MenuItem>
        <MenuItem value="url">URL</MenuItem>
        <MenuItem value="image">Image</MenuItem>
      </TextField>

      {/* Conditionally render input based on selected type */}
      {testDetails.type === 'pdf' ? (
        <Box sx={{ mb: 2 }}>
          <Typography>Upload PDF</Typography>
          <input
            type="file"
            accept="application/pdf"
            onChange={handleFileChange}
          />
        </Box>
      ) : testDetails.type === 'url' ? (
        <TextField
          fullWidth
          label="URL"
          name="content"
          value={testDetails.content}
          onChange={handleChange}
          sx={{ mb: 2 }}
        />
      ) : (
        <Box sx={{ mb: 2 }}>
          <Typography>Upload Image</Typography>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
          />
        </Box>
      )}
       {test && <Typography className='mb-2' fontSize={'12px'}>{test?.url}</Typography>}
      <TextField
        fullWidth
        label="Description"
        name="description"
        value={testDetails.description}
        onChange={handleChange}
        sx={{ mb: 2,mt:2 }}
      />
      <Button variant="contained" onClick={handleSubmit} sx={{ backgroundColor: "#EF492E", ":hover": { backgroundColor: "#EF492E" } }}>
        {test ? "Update Resource" : "Add Resource"}
      </Button>
    </Box>
  );
};

export default AddTest;
