import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Button, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AssignmentIcon from '@mui/icons-material/Assignment';
import {useNavigate} from 'react-router-dom'
const QuestionnaireTable = ({Assessments}) => {
  const navigate = useNavigate();
  if(Assessments?.length==0){
       return (<Typography sx={{height:"100px",display:'flex',justifyContent:'center',alignContent:'center'}}>No Questionnaire Found!</Typography>)
  }
  return (
    <TableContainer sx={{minHeight:'70vh'}}  component={Paper}>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: "#FFBE9D" }}>
            <TableCell sx={{ fontWeight: 'bold' }}>Sr. No</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Assessments Name</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Type</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>EndDate</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Duration</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody >
          {Assessments?.map((quiz, index) => (
            <TableRow key={quiz?._id}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{quiz?.name}</TableCell>
              <TableCell>{quiz?.type}</TableCell>
              <TableCell>{quiz?.endDate}</TableCell>
              <TableCell>{quiz?.duration}</TableCell>
              <TableCell>
                <IconButton color="primary" onClick={()=>{
                     if(quiz?.type=='survey'){
                      window.open(`/quiz/${quiz._id}`,"_blank")
                     }else{
                      window.open(`/survey/${quiz._id}`,"_blank")
                     }
                }}>
                  <VisibilityIcon />
                </IconButton>
                {/* <IconButton color="error">
                  <DeleteIcon />
                </IconButton> */}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default QuestionnaireTable;
