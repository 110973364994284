import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    courses: [],        
    loading: false,     
    totalCourses: 0,    
    totalPages: 0,      
    currentPage: 1,    
    searchTerm: '',     
};

const courseSlice = createSlice({
    name: 'courses',
    initialState,
    reducers: {
        setCourses(state, action) {
            state.courses = action.payload.courses;
            state.totalCourses = action.payload.totalCourses;
            state.totalPages = action.payload.totalPages;
            state.currentPage = action.payload.currentPage;
            state.loading = false;
        },
        appendCourses(state, action) {
            state.courses = [...state.courses, ...action.payload.courses];
            state.totalCourses = action.payload.totalCourses;
            state.totalPages = action.payload.totalPages;
            state.currentPage = action.payload.currentPage;
            state.loading = false;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        clearCourses(state) {
            state.courses = [];
            state.totalCourses = 0;
            state.totalPages = 0;
            state.currentPage = 1;
        },
        setSearchTerm(state, action) {
            state.searchTerm = action.payload;
        },
        updateCurrentPage(state, action) {
            state.currentPage = action.payload;
        }
    },
});

export const { setCourses, appendCourses, setLoading, clearCourses, setSearchTerm, updateCurrentPage } = courseSlice.actions;
export default courseSlice.reducer;
