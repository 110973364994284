import React, { useState, useEffect } from "react";
import { Modal, Box, Typography, TextField, Button, Checkbox, List, ListItem, ListItemText } from "@mui/material";
import { useDebounce } from 'use-debounce';
import { apiConnector } from "../../../services/ApiConnector";
import { courseEndpoints } from "../../../services/ApiEndPoints";
import { useSelector } from "react-redux";

const TeacherModal = ({ open, onClose, onEnroll, courseId }) => {
  const token = useSelector((state) => state.auth.token);
  const [teachers, setTeachers] = useState([]);
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 800); // Debounce for 800ms
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  const fetchTeachers = async (page = 1, query = "", reset = false) => {
    setLoading(true);
    try {
      const response = await apiConnector(
        "GET",
        `${courseEndpoints.COURSE_UNENROLLED_TEACHER}/${courseId}?page=${page}&perPage=10&search=${query}`,
        null,
        { Authorization: `Bearer ${token}` }
      );
      console.log(response)
      if (reset) {
        setTeachers(response?.data?.data);
      } else {
        setTeachers((prev) => [...prev, ...(response?.data?.data)]);
      }

      setTotalPages(response?.data.totalPages || 1);
    } catch (error) {
      console.error("Failed to fetch teachers", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchTeachers(1, debouncedSearchTerm, true);
      setCurrentPage(1);
    }
  }, [open, debouncedSearchTerm]);

  useEffect(() => {
    if (currentPage > 1 && open) {
      fetchTeachers(currentPage, debouncedSearchTerm);
    }
  }, [currentPage]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handleLoadMore = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleSelectTeacher = (teacher) => {
    setSelectedTeachers((prev) => {
      if (prev.includes(teacher._id)) {
        return prev.filter((id) => id !== teacher._id);
      } else {
        return [...prev, teacher._id];
      }
    });
  };

  const handleEnrollClick = () => {
    const selected = teachers.filter((teacher) =>
      selectedTeachers.includes(teacher._id)
    );
    onEnroll(selected, setSelectedTeachers);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 500,
          height: "80vh",
          overflowY: "auto",
          bgcolor: "background.paper",
          p: 3,
          borderRadius: 1,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Select Teachers
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search teachers"
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{ mb: 2 }}
        />
        <List>
          {teachers?.map((teacher, index) => (
            <ListItem
              key={index + 1}
              button
              onClick={() => handleSelectTeacher(teacher)}
            >
              <Checkbox checked={selectedTeachers.includes(teacher._id)} />
              <ListItemText primary={teacher.name} />
              <ListItemText sx={{ textAlign: 'end' }} primary={teacher.userId} />
            </ListItem>
          ))}
        </List>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
          }}
        >
          <Button variant="outlined" onClick={handleEnrollClick}>
            Enroll
          </Button>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          {currentPage < totalPages && (
            loading ? (
              <Button variant="text" disabled>
                Loading...
              </Button>
            ) : (
              <Button variant="text" onClick={handleLoadMore}>
                Load More
              </Button>
            )
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default TeacherModal;
