import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    schools: [],        
    loading: false,     
    totalSchools: 0,    
    totalPages: 0,      
    currentPage: 1,    
    searchTerm: '', 
};

const schoolsSlice = createSlice({
    name: 'schools',
    initialState,
    reducers: {
        setSchools(state, action) {
            state.schools = action.payload.schools;
            state.totalSchools = action.payload.totalSchools;
            state.totalPages = action.payload.totalPages;
            state.currentPage = action.payload.currentPage;
            state.loading = false;
        },
        appendSchools(state, action) {
            state.schools = [...state.schools, ...action.payload.schools];
            state.totalSchools = action.payload.totalSchools;
            state.totalPages = action.payload.totalPages;
            state.currentPage = action.payload.currentPage;
            state.loading = false;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        clearSchools(state) {
            state.schools = [];
            state.totalSchools = 0;
            state.totalPages = 0;
            state.currentPage = 1;
        },
        setSearchTerm(state, action) {
            state.searchTerm = action.payload;
        },
        updateCurrentPage(state, action) {
            state.currentPage = action.payload;
        }
    },
});

export const { setSchools, appendSchools, setLoading, clearSchools, setSearchTerm, updateCurrentPage } = schoolsSlice.actions;
export default schoolsSlice.reducer;



