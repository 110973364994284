import React, { useState } from 'react'
import ViewCoursePage from '../../components/courses/ViewCourse'

const ViewCourse = () => {
  return (
    <div>
      <ViewCoursePage />
    </div>
  )
}

export default ViewCourse