// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import {
//   Box,
//   Button,
//   Typography,
//   TextField,
//   Card,
//   CardContent,
//   CardActions,
//   Stack,
//   Divider,
//   IconButton,
// } from "@mui/material";
// import AddIcon from '@mui/icons-material/Add';
// import RefreshIcon from '@mui/icons-material/Refresh';

// const Notification = () => {
//   const navigate = useNavigate();
//   const [searchTerm, setSearchTerm] = useState("");
//   const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
//   const [localCurrentPage, setLocalCurrentPage] = useState(1);
//   const notificationPerPage = 2;

//   // Static notification data (array of objects)
//   const notifications = [
//     {
//       _id: "1",
//       name: "New Assignment Uploaded",
//       description:
//         "Your new assignment for the math course is now available. Please check and submit before the deadline.",
//       createdAt: "2024-08-01",
//       publishedTo: "Student",
//     },
//     {
//       _id: "2",
//       name: "Exam Schedule Released",
//       description:
//         "The exam schedule for the upcoming semester has been released. Make sure to check the details and prepare accordingly.",
//       createdAt: "2024-08-05",
//       publishedTo: "Student",
//     },
//     {
//       _id: "3",
//       name: "Course Material Updated",
//       description:
//         "New materials have been added to your science course. Please review the content before the next class.",
//       createdAt: "2024-08-10",
//       publishedTo: "Student",
//     },
//     // Add more notifications here...
//   ];

//   const totalPages = Math.ceil(notifications.length / notificationPerPage);

//   // Debounce the search term
//   useEffect(() => {
//     const handler = setTimeout(() => {
//       setDebouncedSearchTerm(searchTerm);
//     }, 500); // Adjust the delay as needed

//     return () => clearTimeout(handler);
//   }, [searchTerm]);

//   // Filter notifications based on search term
//   const filteredNotifications = notifications.filter((notification) =>
//     notification.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
//   );

//   // Paginate notifications
//   const startIndex = (localCurrentPage - 1) * notificationPerPage;
//   const endIndex = startIndex + notificationPerPage;
//   const paginatedNotifications = filteredNotifications.slice(
//     startIndex,
//     endIndex
//   );

//   const handleNextPage = () => {
//     if (localCurrentPage < totalPages) {
//       setLocalCurrentPage((prev) => prev + 1);
//     }
//   };

//   const handleRefresh = () => {
//     setLocalCurrentPage(1);
//     setSearchTerm("");
//   };

//   return (
//     <Box
//       sx={{
//         width: "90%",
//         backgroundColor: "#fff",
//         px: 5,
//         py: 4,
//         borderRadius: "20px",
//         fontFamily: "Raleway",
//         mx: "auto",
//         boxShadow: 3,
//         marginY:'10px'
//       }}
//     >
//       <Stack direction="row" justifyContent="space-between" alignItems={'center'} mb={3}>
//         <IconButton onClick={handleRefresh} color="#EF492E">
//           <RefreshIcon />
//         </IconButton>
//         <Typography variant="h5" fontWeight="medium">
//           All Notifications
//         </Typography>
//         <TextField
//           size="small"
//           variant="outlined"
//           placeholder="Search Notifications"
//           value={searchTerm}
//           onChange={(e) => setSearchTerm(e.target.value)}
//           sx={{ width: "40%" }}
//         />
//         <IconButton
//           size="small"
//           sx={{
//             backgroundColor:'#EF492E',
//             color:'white',
//             borderRadius: "4px", 
//             padding: "8px 16px", 
//             ":hover":{
//                color:'#EF492E',
//             }
//           }}
//           onClick={()=>{
//              navigate('/create-notification')
//           }}
//         >
//           <AddIcon sx={{ marginRight: "8px" }} />
//           <Typography>Create Notification</Typography>
//         </IconButton>
//       </Stack>

//       {paginatedNotifications.map((notification, index) => (
//         <Card
//           key={index}
//           sx={{
//             mb: 4,
//             p: 2,
//             cursor: "pointer",
//             ":hover": { backgroundColor: "#f5f5f5" },
//           }}
//           onClick={() => navigate(`/notification/12`)}
//         >
//           <CardContent>
//             <Typography variant="h6" sx={{ color: '#EF492E' }} fontWeight="bold">
//               {notification.name}
//             </Typography>
//             <Typography variant="body2" color="textSecondary" mt={1}>
//               {notification.description.slice(0, 100)}{" "}
//               <span style={{ color: "#EF492E" }}>...Read More</span>
//             </Typography>
//           </CardContent>
//           <Divider />
//           <CardActions sx={{ justifyContent: "space-between", mt: 2 }}>
//             <Typography variant="body2" color="#EF492E" fontWeight="bold">
//               Published To:{" "}
//               <span
//                 style={{
//                   backgroundColor: "#EF492E",
//                   color: "white",
//                   padding: "4px 8px",
//                   borderRadius: "4px",
//                 }}
//               >
//                 {notification.publishedTo}
//               </span>
//             </Typography>
//             <Typography variant="body2" color="textSecondary">
//               {notification.createdAt}
//             </Typography>
//           </CardActions>
//         </Card>
//       ))}

//       {localCurrentPage < totalPages && (
//         <Box display="flex" justifyContent="center" mt={2}>
//           <Button
//             sx={{ color: "#EF492E", borderColor: '#EF492E' }}
//             variant="outlined"
//             onClick={handleNextPage}
//             disabled={localCurrentPage >= totalPages}
//           >
//             Load More
//           </Button>
//         </Box>
//       )}
//     </Box>
//   );
// };

// export default Notification;




import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  Card,
  CardContent,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-debounce";
import { fetchNotifications } from '../../services/Operations/NotificationOperation';
import { clearNotifications, setSearchTerm, updateCurrentPage } from '../../Store/Slices/notificationSlice';
import { format } from "date-fns";
const Notification = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const { notifications, totalNotifications, currentPage, loading, totalPages, searchTerm } = useSelector((state) => state.notifications);
  const [notificationsPerPage, setNotificationsPerPage] = useState(10);  // Page size
  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);

  // Handle notifications per page change
  const handleChange = (event) => {
    setNotificationsPerPage(event.target.value);
  };

  // Fetch notifications based on search term or page size
  useEffect(() => {
    const fetchNotificationData = async () => {
      dispatch(fetchNotifications(token, 1, notificationsPerPage, debouncedSearchTerm, true));
    };
    if (!loading) {
      fetchNotificationData();
    }
  }, [debouncedSearchTerm, notificationsPerPage, dispatch]);

  // Handle pagination
  useEffect(() => {
    const fetchNotificationData = async () => {
      dispatch(fetchNotifications(token, currentPage, notificationsPerPage, debouncedSearchTerm));
    };
    if (!loading && currentPage > 1) {
      fetchNotificationData();
    }
  }, [currentPage, debouncedSearchTerm, dispatch]);

  // Clean up on component unmount
  useEffect(() => {
    return () => {
      dispatch(clearNotifications());
    };
  }, [dispatch]);

  // Handle load more functionality
  const handleLoadMore = () => {
    if (currentPage < totalPages) {
      dispatch(updateCurrentPage(currentPage + 1));
    }
  };

  // Handle search term change
  const handleSearchChange = (e) => {
    dispatch(setSearchTerm(e.target.value));
    dispatch(updateCurrentPage(1));  // Reset to first page on new search
  };


  console.log(notifications)
  return (
    <div style={{ padding: "20px" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={5}>
        <Typography variant="h5" fontWeight="bold">
          All Notifications <Typography component="span">({totalNotifications} Notifications Available)</Typography>
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <TextField
            variant="outlined"
            placeholder="Search Notifications"
            onChange={handleSearchChange}
            value={searchTerm}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="error"
            startIcon={<AddIcon />}
            sx={{ backgroundColor: "#EF492E" }}
            onClick={() => { navigate("/create-notification"); }}
          >
            Create Notification
          </Button>
        </Box>
      </Box>

      {/* Empty map for notifications where you will insert your card component */}
      <Box>
        {notifications?.map((notification, index) => (
          <Card key={index} sx={{ width:'100%', mb: 2,":hover":{backgroundColor:'#F5F5F5'},cursor:'pointer' }} onClick={()=>{navigate(`/notification/${notification._id}`)}}>
      <CardContent>
        <Typography variant="h6" component="div" color={'#EF492E'}>
          {notification?.name}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 1.5 }}>
          {notification?.type}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 1.5 }}>
          {format(new Date(notification?.createdAt), "MMMM d, yyyy h:mm a")}
        </Typography>
        <Typography variant="body1" component="div">
          {notification?.description.slice(0,100)}...
        </Typography>
      </CardContent>
    </Card>
        ))}
      </Box>

      <Box mt={3} display={"flex"} justifyContent={"end"} alignItems={"center"} gap={"10px"} textAlign="center">
        <Typography>Page - {currentPage} / {totalPages}</Typography>
        <Button
          onClick={handleLoadMore}
          disabled={currentPage >= totalPages}
          variant="outlined"
          color="primary"
        >
          Load More
        </Button>

        <FormControl>
          <Select
            value={notificationsPerPage}
            onChange={handleChange}
            displayEmpty
            size="small"
            renderValue={(value) => `Page Size - ${value}`}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </div>
  );
};

export default Notification;
