import React, { useState, useEffect } from 'react';
import { Grid, FormControl, TextField, Autocomplete, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { apiConnector } from '../services/ApiConnector';
import { brandEndpoints } from '../services/ApiEndPoints'; // Adjust this import as needed

const BrandSelector = ({ size, brand, setBrand }) => {
  const [brandOptions, setBrandOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.auth.token);

  const fetchBrands = async () => {
    setLoading(true);
    try {
      const response = await apiConnector(
        'GET',
        brandEndpoints.BRAND_NAME_LIST, 
        null,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );
      setBrandOptions(response.data.data);
    } catch (error) {
      console.error('Error fetching brands:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch brands when the component mounts
  useEffect(() => {
    fetchBrands();
  }, []);

  return (
    <Grid item xs={12} sm={6}>
      <FormControl fullWidth variant="outlined">
        <Autocomplete
          size={size || ''}
          options={brandOptions}
          required={true}
          getOptionLabel={(option) => option.title || ''} // Adjust the label field as necessary
          loading={loading}
          value={brand}
          onChange={(event, newValue) => setBrand(newValue)}
          renderInput={(params) => (
            <TextField
              size="small"
              {...params}
              label="Brand"
              variant="outlined"
              required={true}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </FormControl>
    </Grid>
  );
};

export default BrandSelector;
