import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast"
import {
  Container,
  Button,
  TextField,
  Box,
  Typography,
  Modal,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Menu,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router";
// import * as XLSX from "xlsx";
import { apiConnector } from "../../services/ApiConnector";
import { useDispatch, useSelector } from "react-redux";
import { studentEndpoints } from "../../services/ApiEndPoints";
import { handleImportExcel } from "../../utils/utils";
import SchoolSelectorDropdown from '../../common/SchoolSelectorDropdown'

import { fetchStudents } from '../../services/Operations/StudentOperation';
import { clearStudents, setSearchTerm, updateCurrentPage, setSchoolId, setStudents } from '../../Store/Slices/studentSlice';
import { useDebounce } from 'use-debounce';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { sortByName } from "../../utils/utils";

const AllStudents = () => {
  const token = useSelector((state) => state.auth.token);
  const [bulkLoading, setBulkLoading] = useState(false);
  const navigate = useNavigate();
  const [studentsData, setStudentsData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Pagination Section for all Students Page

  const [school, setSchool] = useState(null); //{_id: '66d189c415925f62112adf7b', name: 'St. Joseph School '}
  const dispatch = useDispatch();
  const { students, totalStudents, currentPage, loading, totalPages, searchTerm, schoolId } = useSelector((state) => state.students);
  const [studentsPerPage,setStudentPerPage] = useState(10); // Adjust this number as needed
  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);
  const [debouncedSchoolId] = useDebounce(school?._id || '', 1000);
  const [sorted,setSorted] = useState(false);

  const handleChange = (event) => {
    setStudentPerPage(event.target.value);
  };

  // Effect for handling search term or schoolId change

  

  useEffect(() => {
    const fetchStudentData = async () => {
      dispatch(fetchStudents(token, 1, studentsPerPage, debouncedSearchTerm, debouncedSchoolId, true)); // Reset students on new search term or schoolId
    };

    if (!loading) {
      fetchStudentData();
    }
  }, [debouncedSearchTerm, debouncedSchoolId, dispatch,studentsPerPage]);

  // Effect for handling pagination
  useEffect(() => {
    const fetchStudentData = async () => {
      dispatch(fetchStudents(token, currentPage, studentsPerPage, debouncedSearchTerm, debouncedSchoolId));
    };

    if (!loading && currentPage > 1) {
      fetchStudentData();
    }
  }, [currentPage, debouncedSearchTerm, debouncedSchoolId, dispatch]);

  // Effect for handling component unmount
  useEffect(() => {
    return () => {
      dispatch(clearStudents());
    };
  }, [dispatch]);

  //Pagination Code End Here

  // Handle Load More functionality
  const handleLoadMore = () => {
    if (currentPage < totalPages) {
      dispatch(updateCurrentPage(currentPage + 1));
    }
  };

  // Handle search term change
  const handleSearchChange = (e) => {
    dispatch(setSearchTerm(e.target.value));
    dispatch(updateCurrentPage(1)); // Reset to first page on new search
  };

  // // Handle school selection change
  const handleSchoolChange = (selectedSchool) => {
    setSchool(selectedSchool);
    dispatch(setSchoolId(selectedSchool?._id || ''));
    dispatch(updateCurrentPage(1)); // Reset to first page on new school selection
  };



  const handleCreateAll = async () => {
    setIsModalOpen(false);
    const toastId = toast.loading("Loading...");
    setBulkLoading(true);
  
    // Regular expressions for validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email validation
    const contactNoRegex = /^[0-9]{10}$/; // Assuming contactNo should be 10 digits
  
    // Validate studentsData and collect error messages
    const invalidStudents = studentsData.reduce((acc, student,index) => {
      let error = '';
  
      // Check if studentName is present (mandatory)
      if (!student.studentName  || student.studentName.trim() === '') {
        error += `${student.email || `Unknown Email index-${index+2}`}  Student Name Missing)`;
      }

      // If contactNo is present, validate it
      if (student.contactNo!='undefined' && !contactNoRegex.test(student.contactNo)) {
        if(student.contactNo){
          if (error) error += `, `;
          error += `${student.studentName || `Unknown Name row-${index+2}`} (Contact Number Invalid) `;
        }
      }
  
      // If email is present, validate it
      if (student.email && !emailRegex.test(student.email)) {
        if (error) error += `, `;
        error += `${student.studentName || `Unknown Name row-${index+2}`} (Email Invalid)`;
      }
  
      // Check if school (schoolId) is present (mandatory)
      if (!student.school || student.school.trim() === '') {
        if (error) error += `, `;
        error += `${student.studentName || `Unknown Name row-${index+2}`} (School Missing)`;
      }
  
      // If there's an error, add it to the accumulator
      if (error) acc.push(error);
  
      return acc;
    }, []);
  
    if (invalidStudents.length > 0) {
      // Show all errors in a single alert
      alert(`Invalid data for the following students:\n${invalidStudents.join('\n')}`);
      toast.dismiss(toastId);
      setBulkLoading(false);
      return; // Stop the function if there are invalid students
    }
  
    // Proceed with the API call if no validation errors
    try {
      const response = await apiConnector(
        "POST",
        `${studentEndpoints.BULK_CREATE_STUDENT}`,
        studentsData,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );
  
      // Dismiss the loading toast
      toast.dismiss(toastId);
  
      // Show success toast
      toast.success("Student Created Successfully!", {
        id: toastId,
      });
  
      console.log("STUDENTS Created:", response.data);
      setBulkLoading(false);
      navigate('/students');
    } catch (error) {
      // Dismiss the loading toast
      toast.dismiss(toastId);
      setBulkLoading(false);
  
      // Show error toast
      toast.error(`Failed to create Students. ${error?.response?.data?.message}`, {
        id: toastId,
      });
  
      console.error("Error creating Students:", error);
    }
    setBulkLoading(false);
  };
  


  const handleCloseModal = () => {
    setIsModalOpen(false);
    setStudentsData([]);
  };
  //Send FIle to Excel to json Convertor
  const handleFile = async (e) => {
    try {
      const data = await handleImportExcel(e);
      console.log(data);
      setStudentsData(data);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error reading file", error);
    }
  };

  const handleDeleteModalElem = (index) => {
    const updatedData = studentsData.filter((_, i) => i !== index);
    setStudentsData(updatedData);
  };

  //Students Table Logic With anchor .
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleClick = (event, user) => {
    setAnchorEl(event.currentTarget);
    setSelectedUser(user);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedUser(null);
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete?")) {
      const toastId = toast.loading("Deleting User!");
  
      try {
        // Set up the API endpoint for deleting the student
        const endpoint = `${studentEndpoints.DELETE_STUDENT}/${selectedUser._id}`;
  
        // Make the API call to delete the student
        await apiConnector(
          "DELETE",
          endpoint,
          {},
          { Authorization: `Bearer ${token}` }
        );
  
        // Show success message
        toast.success("User Deleted Successfully");
  
        // Filter out the deleted student from the current students list
        const updatedStudents = students.filter((student) => student._id !== selectedUser._id);
  
        // Dispatch the updated students data to Redux, assuming payload needs `students`
        dispatch(setStudents({
          students: updatedStudents,
          totalStudents: (totalStudents-1), // Adjust if needed
          totalPages: totalPages, // Adjust page calculation as per your pagination logic
          currentPage: currentPage, // Keep current page the same
          schoolId: schoolId, // Keep the schoolId same, update if needed
        }));
  
      } catch (error) {
        console.error("Error deleting student:", error);
        toast.error("Failed to delete User");
      } finally {
        toast.dismiss(toastId);
        handleClose(); // Close the menu after deletion
      }
    }
  };
  
  // Here is logic to sort the inputs by name
  const handleSort = () => {
    console.log("Tried to sort");
    setSorted(true);
  
    // Sort students array
    const sortedArr = sortByName(students);
  
    // Dispatch the updated state
    dispatch(setStudents({
      students: sortedArr, // Corrected to use 'students' property
      totalStudents,       // Make sure these variables are defined in scope
      totalPages, 
      currentPage, 
      schoolId
    }));
  };
  

  return (
    <Container sx={{ mt: 4 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
        <Typography variant="h4">
          All Students{" "}
          <Typography component="span" color="textSecondary" sx={{ fontSize: "0.875rem" }}>
            ({totalStudents} Students)
          </Typography>
        </Typography>
        <Box>
          <input
            accept=".xlsx, .xls"
            id="import-excel"
            type="file"
            style={{ display: "none" }}
            onChange={handleFile}
          />
          <label htmlFor="import-excel">
            <Button
              variant="contained"
              sx={{ backgroundColor: "#EF492E", color: "#fff", ml: 1, "&:hover": { backgroundColor: "#D44428" } }}
              startIcon={<ImportExportIcon />}
              component="span"
              disabled={bulkLoading}
            >
              Import From Excel
            </Button>
          </label>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#EF492E", color: "#fff", ml: 1, "&:hover": { backgroundColor: "#D44428" } }}
            startIcon={<AddIcon />}
            onClick={() => {
              navigate("/addstudent");
            }}
          >
            Add New Student
          </Button>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <TextField
          size="small"
          variant="outlined"
          onChange={handleSearchChange}
          value={searchTerm}
          placeholder="Search Students"
          sx={{ width: "30%" }}
        />
        <Box sx={{ width: '20%' }}>
          <SchoolSelectorDropdown size={'small'} school={school} setSchool={setSchool} handleSchoolChange={handleSchoolChange} />
        </Box>
      </Box>

      {/* Table Showing the Students */}
      <TableContainer component={Paper} sx={{ borderRadius: "8px", border: "1px solid #f5a48b", marginTop: '10px', overflowX: 'auto' }}>
        <Table sx={{ tableLayout: 'auto' }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#FFBE9D" }}>
              <TableCell sx={{ fontWeight: 'bold' }} width={'50px'}>S.No</TableCell>
              <TableCell sx={{ fontWeight: 'bold',cursor:'pointer',display:'flex',justifyContent:'center',alignItems:'center'}} width={'150px'} onClick={handleSort}>Name{sorted&&<ArrowUpwardIcon/>}</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} width={'250px'}>Email</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} width={'250px'}>UserId</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} width={'60px'}>Class</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} width={'200px'}>School</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} width={'100px'}>City</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} width={'50px'}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ fontSize: "16px", fontWeight: "bold" }}>
            {students?.map((student, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}.</TableCell>
                <TableCell>{student.name}</TableCell>
                <TableCell>{student.email}</TableCell>
                <TableCell>{student?.userId}</TableCell>
                <TableCell>{student.class}</TableCell>
                <TableCell>{student.schoolName}</TableCell>
                <TableCell>{student.city}</TableCell>
                <TableCell>
                  <IconButton onClick={(event) => handleClick(event, student)}>
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => {
          console.log("View", selectedUser)
          navigate(`/studentprofile/${selectedUser._id}`)
        }}>View</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>

      {/* Load More Button */}
      <Box mt={3} display={'flex'} justifyContent={'end'} alignItems={'center'} gap={'10px'} textAlign="center">
       <Typography>Page -{currentPage}/{totalPages}</Typography>
        <Button
          onClick={handleLoadMore}
          disabled={currentPage >= totalPages}
          variant="outlined"
          color="primary"
        >
          Load More
        </Button>
         {/* page size */}
        <FormControl>
       
      <Select
        value={studentsPerPage}
        onChange={handleChange}
        displayEmpty
        size="small"
        renderValue={(value) => `Page Size - ${value}`}
      >
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={20}>20</MenuItem>
        <MenuItem value={50}>50</MenuItem>
      </Select>

    </FormControl>
      </Box>
      {/* Modal for displaying imported data */}
      <Modal
        sx={{ marginLeft: '90px' }}
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Paper sx={{
          maxWidth: 800,
          width: '90%',
          margin: "auto",
          mt: 4,
          p: 3,
          maxHeight: '90vh',
          overflowY: 'auto' // Add this line
        }}>
          <Typography variant="h6" id="modal-title" sx={{ mb: 2 }}>
            Imported Students Data
          </Typography>
          <TableContainer sx={{ maxHeight: 400 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Contact No</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {studentsData.map((student, index) => (
                  <TableRow key={index}>
                    <TableCell>{student.studentName}</TableCell>
                    <TableCell>{student.email}</TableCell>
                    <TableCell>{student.contactNo}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleDeleteModalElem(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button variant="contained" color="error" onClick={handleCloseModal} sx={{ mr: 2 }}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleCreateAll}>
              Create All Students
            </Button>
          </Box>
        </Paper>
      </Modal>
    </Container>
  );
};

export default AllStudents;

