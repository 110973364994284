import React, { useState, useEffect } from 'react';
import { Grid, FormControl, TextField, Autocomplete, CircularProgress, Button, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { apiConnector } from '../services/ApiConnector';
import { surveyEndpoints } from '../services/ApiEndPoints'; // Adjust this import as needed

const LectureSelector = ({ size, lecture, setLecture, courseId }) => {
  const [lectureOptions, setLectureOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.auth.token);
  console.log(lecture)
  const fetchLectures = async () => {
    setLoading(true);
    try {
      const response = await apiConnector(
        'GET',
        `${surveyEndpoints.GET_LECTURE_NAME}?courseId=${courseId}`,
        null,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );
      console.log(response)
      setLectureOptions(response.data.data);
    } catch (error) {
      console.error('Error fetching lectures:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch lectures when courseId changes
  useEffect(() => {
    if (courseId) {
      fetchLectures();
    }
  }, [courseId]);

  return (
    <Grid item xs={12} sm={6}>
      <FormControl fullWidth variant="outlined">
        <Autocomplete
          size={size || ''}
          options={lectureOptions}
          getOptionLabel={(option) => option.title || ''}
          disabled={!courseId}
          loading={loading}
          value={lecture}
          onChange={(event, newValue) => setLecture(newValue)}
          renderInput={(params) => (
            <TextField
              size="small"
              {...params}
              label="Lecture"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </FormControl>
    </Grid>
  );
};

export default LectureSelector;
