import React, { useEffect } from "react";
import {
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  Grid,
  Box,
  InputAdornment,
  Avatar,
  CardMedia,
  Menu,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from 'react-redux';
import { fetchSchools } from '../../services/Operations/SchoolOperation';
import { clearSchools, setSearchTerm, updateCurrentPage } from '../../Store/Slices/schoolSlice';
import { useDebounce } from 'use-debounce';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useState } from "react";


const AllSchools = () => {
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Ensure useNavigate is properly initialized
  const { schools, totalSchools, currentPage, loading, totalPages, searchTerm } = useSelector((state) => state.schools);
  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);
  const [schoolsPerPage, setSchoolsPerPage] = useState(10);

  const handleChange = (event) => {
    setSchoolsPerPage(event.target.value);
  };
  // Effect for handling search term change
  useEffect(() => {
    const fetchSchoolData = async () => {
      dispatch(fetchSchools(token, 1, schoolsPerPage, debouncedSearchTerm, true)); // Reset schools on new search term
    };

    if (!loading) {
      fetchSchoolData();
    }
  }, [debouncedSearchTerm, dispatch,schoolsPerPage]);

  // Effect for handling pagination
  useEffect(() => {
    const fetchSchoolData = async () => {
      dispatch(fetchSchools(token, currentPage, schoolsPerPage, debouncedSearchTerm));
    };

    if (!loading && currentPage > 1) {
      fetchSchoolData();
    }
  }, [currentPage, debouncedSearchTerm, dispatch]);

  // Effect for handling component unmount
  useEffect(() => {
    return () => {
      dispatch(clearSchools());
    };
  }, [dispatch]);

  // Handle Load More functionality
  const handleLoadMore = () => {
    if (currentPage < totalPages) {
      dispatch(updateCurrentPage(currentPage + 1));
    }
  };

  // Handle search term change
  const handleSearchChange = (e) => {
    dispatch(setSearchTerm(e.target.value));
    dispatch(updateCurrentPage(1)); // Reset to first page on new search
  };

  return (
    <div style={{ padding: "20px" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={5}>
        <Typography variant="h5" fontWeight="bold">
          All Schools <Typography component="span">({totalSchools} Schools Available)</Typography>
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <TextField
            variant="outlined"
            placeholder="Search School"
            onChange={handleSearchChange}
            value={searchTerm}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="error"
            startIcon={<AddIcon />}
            sx={{ backgroundColor: "#EF492E" }}
            onClick={() => navigate('/addschool')}
          >
            Add School
          </Button>
        </Box>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead >
            <TableRow sx={{ backgroundColor: "#FFBE9D" }}>
              <TableCell sx={{ fontWeight: 'bold' }}>Image</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Address</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>SchoolId</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>View</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {schools?.map((school, index) => (
              <TableRow
                key={index}
              >
                <TableCell>
                  {school?.image ? (
                    <CardMedia
                      component="img"
                      sx={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '50%' }}
                      image={school?.image}
                      alt="profileImage"
                    />
                  ) : (
                    <Typography
                      variant="body1"
                      sx={{
                        backgroundColor: 'wheat',
                        width: '50px',
                        height: '50px',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflow: 'hidden',
                        color: '#FF6636',
                        fontSize: '20px',
                        lineHeight: '80px',
                      }}
                    >
                      {school?.name.charAt(0)}
                    </Typography>
                  )}
                </TableCell>
                <TableCell sx={{  }}>{school.name}</TableCell>
                <TableCell sx={{ color: 'gray',  }}>{school.address}</TableCell>
                <TableCell sx={{ color: 'gray',  }}>{school._id}</TableCell>
                <TableCell sx={{ color: 'gray', fontWeight: 'bold',cursor:'pointer',":hover":{color:"red"} }} onClick={() =>{
                    navigate(`/schoolprofile/${school._id}`)
                }}> View</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={3} display={'flex'} justifyContent={'end'} alignItems={'center'} gap={'10px'} textAlign="center">
       {/* <Typography>total Schools -{(totalSchools)}</Typography> */}
       <Typography>Page -{currentPage}/{totalPages}</Typography>
        <Button
          onClick={handleLoadMore}
          disabled={currentPage >= totalPages}
          variant="outlined"
          color="primary"
        >
          Load More
        </Button>
         {/* page size */}
        <FormControl>
       
      <Select
        value={schoolsPerPage}
        onChange={handleChange}
        displayEmpty
        size="small"
        renderValue={(value) => `Page Size - ${value}`}
      >
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={20}>20</MenuItem>
        <MenuItem value={50}>50</MenuItem>
      </Select>

    </FormControl>
      </Box>
    </div>
  );
};

export default AllSchools;
