import React from 'react'
import { Box } from '@mui/material';
import Navbar from "../../common/Navbar";
import Sidebar from "../../common/Sidebar";
import AddStudent from '../../components/student/AddStudent';
function AddStudentPage() {
  return (
    <>
      
        <AddStudent/>
     
    </>
  );
}

export default AddStudentPage
