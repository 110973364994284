import React, { useState, useEffect } from "react";
import { Modal, Box, Typography, TextField, Button, Checkbox, List, ListItem, ListItemText } from "@mui/material";
import { useDebounce } from 'use-debounce';
import { apiConnector } from "../../../services/ApiConnector";
import { teacherEndpoints } from "../../../services/ApiEndPoints";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const StudentModal = ({ fetchAssignedStudentList, open, onClose, teacherId }) => {
  const token = useSelector((state) => state.auth.token);
  const [students, setStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 800); // Debounce for 800ms
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  const fetchStudents = async (page = 1, query = "", reset = false) => {
    setLoading(true);
    try {
      const response = await apiConnector(
        "GET",
        `${teacherEndpoints.GET_UNASSIGNED_STUDENT_LIST}?teacherId=${teacherId}&page=${page}&limit=10&searchTerm=${query}`,
        null,
        { Authorization: `Bearer ${token}` }
      );
      console.log(response)
      if (reset) {
        setStudents(response?.data?.data || []);
      } else {
        setStudents((prev) => [...prev, ...(response?.data?.data || [])]);
      }

      setTotalPages(response?.data?.totalPages || 1);
    } catch (error) {
      console.error("Failed to fetch students", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchStudents(1, debouncedSearchTerm, true);
      setCurrentPage(1);
    }
  }, [open, debouncedSearchTerm]);

  useEffect(() => {
    if (currentPage > 1 && open) {
      fetchStudents(currentPage, debouncedSearchTerm);
    }
  }, [currentPage]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handleLoadMore = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleSelectStudent = (student) => {
    setSelectedStudents((prev) => {
      if (prev.includes(student._id)) {
        return prev.filter((id) => id !== student._id);
      } else {
        return [...prev, student._id];
      }
    });
  };

  const handleAssignStudent = async () => {
    const studentIds = selectedStudents;
    
    if (studentIds.length === 0) {
      toast.error("Please select at least one student to assign.");
      return;
    }
    
    const toastId = toast.loading("Assigning students...");
    
    try {
      const response = await apiConnector(
        "POST",  // Assuming it's a PUT request to assign students
        `${teacherEndpoints.ASSIGN_STUDENT_TO_TEACHER}?teacherId=${teacherId}`,  // Adjust the endpoint accordingly
        { studentIds },  // Sending selected student IDs in the request body
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );
  
      if (response.status === 200) {
        toast.success("Students assigned successfully", { id: toastId });
        setSelectedStudents([]);
        fetchAssignedStudentList();  // Refresh the assigned student list after assigning
      } else {
        toast.error("Failed to assign students. Please try again.", { id: toastId });
      }
    } catch (error) {
      toast.error(`Error occurred: ${error.message}`, { id: toastId });
    } finally {
      toast.dismiss(toastId);
      onClose()
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 500,
          height: "80vh",
          overflowY: "auto",
          bgcolor: "background.paper",
          p: 3,
          borderRadius: 1,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Select Students
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search students"
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{ mb: 2 }}
        />
        <List>
          {students?.map((student, index) => (
            <ListItem
              key={index + 1}
              button
              onClick={() => handleSelectStudent(student)}
            >
              <Checkbox checked={selectedStudents.includes(student._id)} />
              <ListItemText primary={student.name} />
              <ListItemText sx={{ textAlign: 'end' }} primary={student.userId} />
            </ListItem>
          ))}
        </List>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
          }}
        >
          <Button variant="outlined" onClick={handleAssignStudent}>
            Assign
          </Button>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          {currentPage < totalPages && (
            loading ? (
              <Button variant="text" disabled>
                Loading...
              </Button>
            ) : (
              <Button variant="text" onClick={handleLoadMore}>
                Load More
              </Button>
            )
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default StudentModal;
