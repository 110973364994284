import { toast } from "react-hot-toast";
import { apiConnector } from '../ApiConnector';
import { notificationEndpoints } from '../ApiEndPoints'; // Make sure to import the correct endpoints
import { setNotifications, appendNotifications, setLoading, clearNotifications, setSearchTerm } from '../../Store/Slices/notificationSlice'; // Adjust import path as necessary

export function fetchNotifications(token, page, limit, searchTerm, reset = false) {
  return async (dispatch, getState) => {
    const { searchTerm: previousSearchTerm } = getState().notifications;

    if (reset || searchTerm !== previousSearchTerm) {
      dispatch(clearNotifications());
      dispatch(setSearchTerm(searchTerm));
    }

    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));

    try {
      const response = await apiConnector(
        "GET",
        `${notificationEndpoints.ALL_NOTIFICATION_DATA_API}?page=${page}&limit=${limit}&searchTerm=${searchTerm}`,
        null,
        { Authorization: `Bearer ${token}` }
      );
      
      if (response.status !== 200) {
        throw new Error("Could not fetch notification data");
      }
       console.log(response)
      const result = response?.data?.data;
      const totalNotifications = response?.data?.pagination?.total;
      const totalPages = response?.data?.pagination?.totalPages;
      
      if (reset || searchTerm !== previousSearchTerm || page === 1) {
        dispatch(setNotifications({ notifications: result, totalNotifications, totalPages, currentPage: page, searchTerm }));
      } else {
        dispatch(appendNotifications({ notifications: result, totalNotifications, totalPages, currentPage: page, searchTerm }));
      }

      toast.success("Notifications fetched successfully");
    } catch (error) {
      toast.error(`Error! ${error?.response?.data?.message}`);
    } finally {
      dispatch(setLoading(false));
      toast.dismiss(toastId);
    }
  };
}
