import React from 'react'

import AllStudent from '../../components/student/AllStudent';
function AllStudentPage() {
  return (
      <>
        <AllStudent/>

    </>
    
  )
}

export default AllStudentPage