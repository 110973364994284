import React, { useState, useEffect } from 'react';
import AddSurvey from '../../components/survey/AddSurveyComponent';
import { useLocation } from 'react-router-dom';

function AllStudentPage() {
  return (
    <div>
    {/* Keep in mind survey/questionnaire/quiz is refering to same entity.. */}
      <AddSurvey />
    </div>
  );
}

export default AllStudentPage;
