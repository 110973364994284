import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { apiConnector } from '../../services/ApiConnector';
import { surveyEndpoints } from '../../services/ApiEndPoints';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Divider,
  CircularProgress,
} from '@mui/material';

const SurveyDetail = () => {
  const { responseId } = useParams();
  const [surveyData, setSurveyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const token = useSelector((state) => state.auth.token);

  const fetchSurveyDetails = async () => {
    const toastId = toast.loading("Loading Survey Response Details...");
    try {
      const response = await apiConnector(
        'GET',
        `${surveyEndpoints.GET_SURVEY_RESPONSE_DETAIL}?responseId=${responseId}`,
        null,
        { Authorization: `Bearer ${token}` }
      );
      toast.success("Survey Response loaded successfully");
      setSurveyData(response.data.data);
    } catch (error) {
      toast.error("Failed to fetch survey details!");
      console.error('Error fetching survey:', error);
    } finally {
      toast.dismiss(toastId);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSurveyDetails();
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box p={4} mx="auto" width="80%" height="80vh" overflow="auto">

      {/* Survey Title */}
      <Card elevation={3} sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h4" align="center" gutterBottom>
            {surveyData?.survey?.name}
          </Typography>
        </CardContent>
      </Card>

      {/* Survey Details Card - Moved to the top */}
      <Card elevation={3} sx={{ mb: 6 }}>
        <CardContent>
          <Grid container spacing={2} paddingX={'20px'}>
            {/* Survey Details Title */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>Survey Details</Typography>
            </Grid>

            {/* Survey Description - Full Width */}
            <Grid item xs={12}>
              <Typography>
                <strong>Description:</strong> {surveyData?.survey?.description}
              </Typography>
            </Grid>

            {/* Two Columns - Total Questions */}
            <Grid item xs={12} md={6}>
              <Typography>
                <strong>Total Questions:</strong> {surveyData?.survey?.totalQuestions}
              </Typography>
            </Grid>

            {/* Quiz-Specific Data - Two Columns */}
            {surveyData?.survey?.type === 'quiz' && (
              <>
                <Grid item xs={12} md={6}>
                  <Typography>
                    <strong>Total Correct:</strong> {surveyData?.survey?.totalCorrect}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography>
                    <strong>Total Incorrect:</strong> {surveyData?.survey?.totalIncorrect}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography>
                    <strong>Passing Percentage:</strong> {surveyData?.survey?.passingMark}%
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography>
                    <strong>Obtained Percentage:</strong> {surveyData?.survey?.obtainedPercentage}%
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography>
                    <strong>Maximum Marks:</strong> {surveyData?.survey?.maximumMarks}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography>
                    <strong>Obtained Marks:</strong> {surveyData?.survey?.totalMarksObtained}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography>
                    <strong>Status:</strong> {surveyData?.survey?.pass ? 'Passed' : 'Failed'}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography>
                    <strong>Duration:</strong> {surveyData?.survey?.duration}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
      </Card>




      {/* Questions and Options */}
      {surveyData?.questions?.map((question, index) => (
        <Card key={index} elevation={3} sx={{ mb: 6 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {index + 1}. {question.title}
            </Typography>

            <Grid container spacing={2}>
              {question.options
                .filter(option => option !== '')
                .map((option, idx) => {
                  const isUserAnswer = option === question.userResponse;
                  const isCorrectAnswer = option === question.correctAnswer;
                  const optionStyle = isUserAnswer && isCorrectAnswer
                    ? { borderColor: 'green', color: 'green' }
                    : isUserAnswer
                      ? { borderColor: 'red', color: 'red' }
                      : isCorrectAnswer
                        ? { borderColor: 'green', color: 'green' }
                        : { borderColor: 'grey', color: 'black' };

                  return (
                    <Grid item xs={6} key={idx}>
                      <Box
                        sx={{
                          p: 2,
                          border: 1,
                          borderRadius: 2,
                          textAlign: 'center',
                          ...(surveyData.survey.type === 'quiz' && optionStyle), // Spread the optionStyle conditionally
                        }}
                      >
                        {option}
                      </Box>
                    </Grid>
                  );
                })}
            </Grid>

            <Box mt={4}>
              <Typography color="textSecondary">
                <strong>Your Answer:</strong>{' '}
                {(question?.userResponse) ? (Array.isArray(question.userResponse)
                  ? question.userResponse.join(', ')
                  : question.userResponse) : "User have Not Answered This question"}
              </Typography>
              {surveyData?.survey?.type === 'quiz' && (
                <Typography color="textSecondary">
                  <strong>Correct Answer:</strong> {question.correctAnswer}
                </Typography>
              )}
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default SurveyDetail;
