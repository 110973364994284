import React, { useEffect, useState } from "react";
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  Paper, IconButton, Menu, MenuItem, Button, Typography, Box, 
  TextField, InputAdornment, 
  FormControl,
  Select
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { green, red } from "@mui/material/colors";
import { useNavigate } from "react-router";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";

import { fetchReports } from '../../services/Operations/ReportOperation';
import { clearReports, setReports, setSearchTerm, updateCurrentPage } from '../../Store/Slices/reportSlice';
import { useDebounce } from 'use-debounce';
import {toast} from "react-hot-toast";
import { reportEndpoints } from "../../services/ApiEndPoints";
import { apiConnector } from "../../services/ApiConnector";



const ReportTable = ({
  totalReports,
  reportPerPage,
  handleChange,
  handleLoadMore,
  reports,
  currentPage,
  totalPages,
}) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedReport, setSelectedReport] = React.useState(null);

  const handleMenuClick = (event, report) => {
    setAnchorEl(event.currentTarget);
    setSelectedReport(report);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedReport(null);
  };

  const handleView = () => {
    if (selectedReport) {
      window.open(selectedReport.url);
    }
    handleMenuClose();
  };

  const handleDelete = async () => {
    handleMenuClose();
    if (window.confirm("Are you sure you want to delete?")) {
      const toastId = toast.loading("Deleting Report!");

      try {
        const endpoint = `${reportEndpoints.DELETE_REPORT}/${selectedReport._id}`;
        await apiConnector("DELETE", endpoint, {}, { Authorization: `Bearer ${token}` });

        // Show success message
        toast.success("Report Deleted Successfully");
        const updatedReports = reports.filter((report) => report._id !== selectedReport._id);
        dispatch(
          setReports({
            reports: updatedReports,
            totalReports: totalReports - 1,
            totalPages,
            currentPage,
          })
        );
      } catch (error) {
        console.error("Error deleting Report:", error);
        toast.error("Failed to delete Report");
      } finally {
        toast.dismiss(toastId);
        handleMenuClose(); // Ensure the menu closes after deletion
      }
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#FFBE9D" }}>
              <TableCell sx={{ fontWeight: "bold" }}>Sr</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>File Name</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>School</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Student</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Course Name</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reports?.map((report, index) => (
              <TableRow key={report.fileName}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{report?.fileName}</TableCell>
                <TableCell>{report?.schoolId?.name}</TableCell>
                <TableCell>{report?.studentId?.name}</TableCell>
                <TableCell>{report?.courseId?.name}</TableCell>
                <TableCell
                  sx={{ fontWeight: "bold" }}
                  style={{ color: report.status === "Completed" ? green[500] : red[500] }}
                >
                  {report?.status ? "ACTIVE" : "INACTIVE"}
                </TableCell>
                <TableCell>
                  <IconButton onClick={(event) => handleMenuClick(event, report)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                    <MenuItem onClick={handleView}>View Report</MenuItem>
                    <MenuItem onClick={handleDelete}>Delete</MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        mt={3}
        display={"flex"}
        justifyContent={"end"}
        alignItems={"center"}
        gap={"10px"}
        textAlign="center"
      >
        <Typography>Page - {currentPage} / {totalPages}</Typography>
        <Button
          onClick={handleLoadMore}
          disabled={currentPage >= totalPages}
          variant="outlined"
          color="primary"
        >
          Load More
        </Button>

        <FormControl>
          <Select
            value={reportPerPage}
            onChange={handleChange}
            displayEmpty
            size="small"
            renderValue={(value) => `Page Size - ${value}`}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

const  AllReport = ()=> {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const { reports, totalReports, currentPage, loading, totalPages, searchTerm } = useSelector((state) => state.reports);
  const [reportPerPage,setReportPerPage] = useState(10); // Adjust this number as needed
  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);

  const handleChange = (event) => {
    setReportPerPage(event.target.value);
  };

  // Effect for handling search term change
  useEffect(() => {
    const fetchReportData = async () => {
      dispatch(fetchReports(token, 1, reportPerPage, debouncedSearchTerm, true)); // Reset schools on new search term
    };

    if (!loading) {
      fetchReportData();
    }
  }, [debouncedSearchTerm, dispatch,reportPerPage]);

  // Effect for handling pagination
  useEffect(() => {
    const fetchReportData = async () => {
      dispatch(fetchReports(token, currentPage, reportPerPage, debouncedSearchTerm));
    };

    if (!loading && currentPage > 1) {
      fetchReportData();
    }
  }, [currentPage, debouncedSearchTerm, dispatch]);

  // Effect for handling component unmount
  useEffect(() => {
    return () => {
      dispatch(clearReports());
    };
  }, [dispatch]);

  // Handle Load More functionality
  const handleLoadMore = () => {
    if (currentPage < totalPages) {
      dispatch(updateCurrentPage(currentPage + 1));
    }
  };

  // Handle search term change
  const handleSearchChange = (e) => {
    dispatch(setSearchTerm(e.target.value));
    dispatch(updateCurrentPage(1)); // Reset to first page on new search
  };

  return (
    <div style={{ padding: '20px' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={5}>
        <Typography variant="h5" fontWeight="bold">
          All Reports <Typography component="span">({totalReports} Reports Available)</Typography>
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <TextField
            variant="outlined"
            placeholder="Search Report"
            onChange={handleSearchChange}
            value={searchTerm}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="error"
            startIcon={<AddIcon />}
            sx={{ backgroundColor: "#EF492E" }}
            onClick={() => { navigate('/add-report') }}
          >
            Add Report
          </Button>
        </Box>
      </Box>
      <ReportTable totalReports={totalReports} reportPerPage={reportPerPage} handleChange={handleChange} handleLoadMore={handleLoadMore} reports={reports} currentPage={currentPage} totalPages={totalPages} />
    </div>
  );
}

export default AllReport;
