import React, { useEffect, useState } from 'react';
import { Box, Typography, Avatar, Button, Divider, Container, Paper, CircularProgress } from '@mui/material';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { useNavigate } from 'react-router';
import { apiConnector } from '../../services/ApiConnector';
import { notificationEndpoints } from '../../services/ApiEndPoints';
import toast from 'react-hot-toast';
import {useSelector} from 'react-redux'
const NotificationCard = ({ setOpenNotification }) => {
  const token = useSelector((state)=>state.auth.token)
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNotifications = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await apiConnector(
          "GET",
          `${notificationEndpoints.ALL_NOTIFICATION_DATA_API}?page=${1}&limit=${2}`,
          null,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with actual token if needed
          }
        );
        console.log(response)
        setNotifications(response?.data?.data || []);
        toast.success('Notifications loaded successfully'); // Show success toast
      } catch (error) {
        console.error("FETCH NOTIFICATIONS ERROR:", error);
        toast.error(`Failed to load notifications: ${error.message}`); // Show error toast
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);


  return (
    <Container maxWidth="md" sx={{ position: 'absolute', top: '40px', right: '-50px', width: 320, height: 200, zIndex: 10 }}>
      <Paper elevation={3} sx={{ padding: '8px', border: '1px solid black', borderRadius: '10px', paddingX: '10px' }}>
        {/* Notifications Header */}
        <Typography variant="h6" color="error" sx={{ fontWeight: 500, marginBottom: '10px' }}>
          Notifications
        </Typography>
        {/* Notifications List */}
        {
          loading?<CircularProgress size={20} sx={{marginLeft:'45%'}}/>:null
        }
        {notifications?.length > 0 ? (
          notifications?.map((notification, index) => (
            <Box key={index}>
              <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                {/* Notification Icon */}
                <Avatar
                  sx={{
                    backgroundColor: '#ffe8e8',
                    marginRight: '1rem',
                    width: 35,
                    height: 35,
                  }}
                >
                  <NotificationsActiveIcon color="error" sx={{ fontSize: '1.5rem' }} />
                </Avatar>

                {/* Notification Content */}
                <Box>
                  <Typography sx={{ fontWeight: 600, color: '#e53935', fontSize: '16px' }}>
                    {notification?.name?.slice(0, 20)} ...
                  </Typography>
                  <Typography fontSize={'14px'} sx={{ color: '#757575', lineHeight: '17px' }}>
                    {notification?.description?.slice(0, 90)}
                    <Button
                      variant="text"
                      color="error"
                      sx={{ fontSize: '0.875rem', fontWeight: 600, marginLeft: '0.5rem' }}
                      onClick={() => {
                        setOpenNotification(false);
                        navigate(`/notification/${notification?._id}`); // Assuming notification has an `id` field
                      }}
                    >
                      Read More
                    </Button>
                  </Typography>
                </Box>
              </Box>

              {/* Divider between notifications */}
              {index < 1 && <Divider sx={{ marginY: '0.5rem' }} />}
            </Box>
          ))
        ) : (
          <Typography>No notifications available</Typography>
        )}

        {/* "Read More" Button */}
        <Box sx={{ textAlign: 'center' }}>
          <Button variant="text" color="error" sx={{ fontSize: '1rem', fontWeight: 600 }} onClick={() => {
            setOpenNotification(false);
            navigate('/notifications');
          }}>
            See All
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default NotificationCard;
