import React, { useState } from "react";
import { toast } from "react-hot-toast";
import {
  TextField,
  Button,
  Stack,
  Typography,
  Container,
  Grid,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { apiConnector } from '../../services/ApiConnector';
import { schoolEndpoints } from '../../services/ApiEndPoints';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { boardOptions, classOptions, stateOptions } from '../../data/data.js';
// import useFirebaseImageUpload from "../../hooks/useFirebaseImageUpload";
import useAwsFileUpload from "../../hooks/useAwsImageUpload.js";

const AddSchool = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  // const { uploadImageToFirebase, uploading } = useFirebaseImageUpload();  // Destructure hook
  const {uploadFileToAws,uploading} = useAwsFileUpload();
  const [schoolName, setSchoolName] = useState("");
  const [officeContactNo, setOfficeContactNo] = useState("");
  const [boardOfEducation, setBoardOfEducation] = useState("");
  const [classesFrom, setClassesFrom] = useState("");
  const [classesTill, setClassesTill] = useState("");
  const [schoolAddress, setSchoolAddress] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [landmark, setLandmark] = useState("");
  const [pincode, setPincode] = useState("");
  const [schoolLogo, setSchoolLogo] = useState(null);

  const handleLogoChange = (e) => {
    setSchoolLogo(e.target.files[0]);
    console.log(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const toastId = toast.loading("Uploading image...");
  
    // Validation using regex
    const phoneRegex = /^[6-9]\d{9}$/; // For valid 10-digit Indian contact numbers starting with 6-9
    const pincodeRegex = /^[1-9][0-9]{5}$/; // For valid 6-digit Indian pincodes
    const nameRegex = /^[a-zA-Z\s]{2,50}$/; // For validating school name (letters and spaces, min 2 chars)
  
    // Check for empty fields
    if (!schoolName || !officeContactNo || !state || !schoolAddress) {
      toast.error("All fields are required!");
      toast.dismiss(toastId);
      return;
    }
  
    // Validate school name format
    if (!nameRegex.test(schoolName)) {
      toast.error("Invalid school name format. Only letters and spaces are allowed.");
      toast.dismiss(toastId);
      return;
    }
  
    // Validate phone number format
    if (!phoneRegex.test(officeContactNo)) {
      toast.error("Invalid contact number. Must be a valid 10-digit number.");
      toast.dismiss(toastId);
      return;
    }
  
    // Validate pincode format
    if (pincode && !pincodeRegex.test(pincode)) {
      toast.error("Invalid pincode. Must be a 6-digit number.");
      toast.dismiss(toastId);
      return;
    }
  
    try {
      let imageUrl = "";
  
      if (schoolLogo) {
        // Use the hook to upload the image
        imageUrl = await uploadFileToAws(schoolLogo);
        if (!imageUrl) {
          throw new Error("Failed to upload image");
        }
      }

      const schoolData = {
        schoolName,
        officeContactNo,
        boardOfEducation,
        classesFrom,
        classesTill,
        schoolAddress,
        image: imageUrl,  // Store uploaded image URL
        state,
        city,
        landmark,
        pincode,
      };
  
      // Call API to create school
      const response = await apiConnector(
        "POST",
        `${schoolEndpoints.CREATE_SCHOOL}`,
        schoolData,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );
      
      toast.dismiss(toastId);
      toast.success("School Created Successfully!");
      navigate('/schools');
    } catch (error) {
      toast.dismiss(toastId);
      toast.error("Failed to create school. Please try again.");
      console.error("Error creating school:", error);
    }
  };
  
  return (
    <Container maxWidth="md" sx={{ paddingBottom: '50px' }}>
      <Typography variant="h4" gutterBottom align="center" sx={{ marginTop: "1rem", color: "#FF6636" }}>
        Add New School
      </Typography>
        <Stack spacing={3}>
          {/* Form fields */}
          <form onSubmit={handleSubmit}>
            <Stack spacing={3}  >
              <Typography variant="h6">School Details</Typography>
              {/* school Name */}
              <Box sx={{}}>
                <TextField
                  size="small"
                  label="School Name*"
                  variant="outlined"
                  required={true}
                  fullWidth
                  value={schoolName}
                  onChange={(e) => setSchoolName(e.target.value)}
                />
              </Box>

              <Box sx={{}}>
                <Grid container spacing={3} alignItems="center">
                  
                    <Grid item xs={12} sm={6}>
                      <Box sx={{ display: "flex", alignItems: "center", padding: "8px 12px", borderRadius: "4px", border: "1px solid #cccccc" }}>
                        <Button size="small" component="label" sx={{ color: "#000000", backgroundColor: "#f5f5f5" }} startIcon={<AttachFileIcon />}>
                          Choose Image
                          <input type="file" hidden accept=".jpg, .png" onChange={handleLogoChange} />
                        </Button>
                        <Typography size="small" variant="caption" sx={{ color: "#888888", marginLeft: "8px" }}>
                      {
                        uploading?"Uploading":<>{schoolLogo?schoolLogo.name:"Supported Files: .jpg, .png"} </>
                      }   
                        </Typography>
                      </Box>
                    </Grid>
        
                  <Grid item xs={12} sm={6}>
                    <TextField
                      size="small"
                      required={true}
                      label="Office Contact No.*"
                      variant="outlined"
                      fullWidth
                      value={officeContactNo}
                      onChange={(e) => setOfficeContactNo(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box sx={{}}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>Board of Education*</InputLabel>
                      <Select
                        required={true}
                        size="small"
                        value={boardOfEducation}
                        onChange={(e) => setBoardOfEducation(e.target.value)}
                        label="Board of Education"
                      >
                        {boardOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>Classes From*</InputLabel>
                      <Select
                        size="small"
                        required={true}
                        value={classesFrom}
                        onChange={(e) => setClassesFrom(e.target.value)}
                        label="Classes From"
                      >
                        {classOptions.map((option) => (
                          <MenuItem key={option} value={option} size="small">
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>Classes Till*</InputLabel>
                      <Select size="small"
                        value={classesTill}
                        required={true}
                        onChange={(e) => setClassesTill(e.target.value)}
                        label="Classes Till"
                      >
                        {classOptions.map((option) => (
                          <MenuItem key={option} value={option} size="small">
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>

              <Box sx={{}}>
                <TextField
                  size="small"
                  label="School Address*"
                  required={true}
                  variant="outlined"
                  fullWidth
                  value={schoolAddress}
                  onChange={(e) => setSchoolAddress(e.target.value)}
                />
              </Box>

              <Box sx={{}}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={3}>
                    <FormControl fullWidth variant="outlined" sx={{ alignContent: 'center' }}>
                      <InputLabel>State*</InputLabel>
                      <Select
                        size="small"
                        required={true}
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                        label="State"
                      >
                        {stateOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                  <TextField
                      size="small"
                      label="City"
                      variant="outlined"
                      fullWidth
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  

                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      size="small"
                      label="Landmark"
                      variant="outlined"
                      fullWidth
                      value={landmark}
                      onChange={(e) => setLandmark(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      size="small"
                      label="Pincode"
                      variant="outlined"
                      fullWidth
                      value={pincode}
                      onChange={(e) => setPincode(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Stack direction="row" spacing={2} justifyContent="space-between">
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#ffffff",
                    color: "#FF6636",
                    border: "1px solid #FF6636",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ backgroundColor: "#FF6636", color: "#ffffff" }}
                >
                  Save Details
                </Button>
              </Stack>

            </Stack>
          </form>
        </Stack>
    </Container>
  );
};

export default AddSchool;


