import React from 'react'
import { Box } from "@mui/material";
import Navbar from "../../common/Navbar";
import Sidebar from "../../common/Sidebar";
import AllTeachers from '../../components/teacher/AllTeacher';

function AllTeacherPage() {
  return (
      <>
       <AllTeachers/>
    </>
      
  )
}

export default AllTeacherPage
