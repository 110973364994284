import { toast } from "react-hot-toast"
import { apiConnector } from '../ApiConnector';
import { dashboardEndpoints } from '../ApiEndPoints';
import { setMetrics,  setLoading } from '../../Store/Slices/dashboardSlice';

export function fetchDashboard(token) {
    return async (dispatch) => {
        const toastId = toast.loading("Loading...");
           dispatch(setLoading(true));
        try {
            const response = await apiConnector("GET", `${dashboardEndpoints.DASHBOARD_API}`, null, {Authorization: `Bearer ${token}`,}
            );
            if (!response.data.status) {
                throw new Error("Could not fetch Dashboard Data");
            }
            const result = response?.data?.data;
            // // Assuming `skip` is 0 for the first page, otherwise we append
            dispatch(setMetrics({ metrics: result}));
            toast.success("Dashboard fetched successfully");
        } catch (error) {
            toast.error(`Error! ${error?.response?.data?.message}`)
        }
        dispatch(setLoading(false));
        toast.dismiss(toastId);
    };
}



