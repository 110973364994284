import { toast } from "react-hot-toast";
import { apiConnector } from '../ApiConnector';
import { courseEndpoints } from '../ApiEndPoints';
import { setCourses, appendCourses, setLoading, clearCourses, setSearchTerm } from '../../Store/Slices/courseSlice';

export function fetchCourses(token, page, limit, searchTerm, reset = false) {
  return async (dispatch, getState) => {
    const { searchTerm: previousSearchTerm } = getState().courses;
    if (reset || searchTerm !== previousSearchTerm) {
      dispatch(clearCourses());
      dispatch(setSearchTerm(searchTerm));
    }

    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    
    try {
      const response = await apiConnector("GET", `${courseEndpoints.ALL_COURSE_DATA_API}?page=${page}&limit=${limit}&searchTerm=${searchTerm}`, null, { Authorization: `Bearer ${token}` });
      console.log(response)
      if (response.status !== 200) {
        throw new Error("Could not fetch course data");
      }
      console.log(response)
      const result = response?.data?.data;
      const totalCourses = response.data.totalCourses;
      const totalPages = response.data.totalPages;

      if (reset || searchTerm !== previousSearchTerm || page === 1) {
        dispatch(setCourses({ courses: result, totalCourses, totalPages, currentPage: page, searchTerm }));
      } else {
        dispatch(appendCourses({ courses: result, totalCourses, totalPages, currentPage: page, searchTerm }));
      }

      toast.success("Courses fetched successfully");
    } catch (error) {
      console.log(error);
      toast.error(`Error! ${error?.response?.data?.message}`)
    } finally {
      dispatch(setLoading(false));
      toast.dismiss(toastId);
    }
  };
}
