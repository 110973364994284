import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    brands: [],        
    loading: false,    
    totalBrands: 0,    
    totalPages: 0,     
    currentPage: 1,    
    searchTerm: '',    
};

const brandsSlice = createSlice({
    name: 'brands',
    initialState,
    reducers: {
        setBrands(state, action) {
            state.brands = action.payload.brands;
            state.totalBrands = action.payload.totalBrands;
            state.totalPages = action.payload.totalPages;
            state.currentPage = action.payload.currentPage;
            state.loading = false;
        },
        appendBrands(state, action) {
            state.brands = [...state.brands, ...action.payload.brands];
            state.totalBrands = action.payload.totalBrands;
            state.totalPages = action.payload.totalPages;
            state.currentPage = action.payload.currentPage;
            state.loading = false;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        clearBrands(state) {
            state.brands = [];
            state.totalBrands = 0;
            state.totalPages = 0;
            state.currentPage = 1;
        },
        setSearchTerm(state, action) {
            state.searchTerm = action.payload;
        },
        updateCurrentPage(state, action) {
            state.currentPage = action.payload;
        }
    },
});

export const { setBrands, appendBrands, setLoading, clearBrands, setSearchTerm, updateCurrentPage } = brandsSlice.actions;
export default brandsSlice.reducer;
