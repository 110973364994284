import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import {
  Container,
  Button,
  TextField,
  Box,
  Typography,
  Modal,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Menu,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";


import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router";
import { apiConnector } from "../../services/ApiConnector";
import { useDispatch, useSelector } from "react-redux";
import { teacherEndpoints } from "../../services/ApiEndPoints";
import { handleImportExcel, sortByName } from "../../utils/utils";
import SchoolSelectorDropdown from '../../common/SchoolSelectorDropdown';

import { fetchTeachers } from '../../services/Operations/TeacherOperation';
import { clearTeachers, setSearchTerm, updateCurrentPage, setSchoolId, setTeachers } from '../../Store/Slices/teacherSlice';
import { useDebounce } from 'use-debounce';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const AllTeachers = () => {
  const token = useSelector((state) => state.auth.token);
  const [bulkLoading, setBulkLoading] = useState(false);
  const navigate = useNavigate();
  const [teachersData, setTeachersData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sorted,setSorted] = useState(false);

  // Pagination Section for all Teachers Page
  const [school, setSchool] = useState(null);
  const dispatch = useDispatch();
  const { teachers, totalTeachers, currentPage, loading, totalPages, searchTerm, schoolId } = useSelector((state) => state.teachers);
  const [teachersPerPage, setTeacherPerPage] = useState(10); // Adjust this number as needed
  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);
  const [debouncedSchoolId] = useDebounce(school?._id || '', 1000);

  const handleChange = (event) => {
    setTeacherPerPage(event.target.value);
  };

  // Effect for handling search term or schoolId change
  useEffect(() => {
    const fetchTeacherData = async () => {
      dispatch(fetchTeachers(token, 1, teachersPerPage, debouncedSearchTerm, debouncedSchoolId, true)); // Reset teachers on new search term or schoolId
    };

    if (!loading) {
      fetchTeacherData();
    }
  }, [debouncedSearchTerm, debouncedSchoolId, dispatch, teachersPerPage]);

  // Effect for handling pagination
  useEffect(() => {
    const fetchTeacherData = async () => {
      dispatch(fetchTeachers(token, currentPage, teachersPerPage, debouncedSearchTerm, debouncedSchoolId));
    };

    if (!loading && currentPage > 1) {
      fetchTeacherData();
    }
  }, [currentPage, debouncedSearchTerm, debouncedSchoolId, dispatch]);

  // Effect for handling component unmount
  useEffect(() => {
    return () => {
      dispatch(clearTeachers());
    };
  }, [dispatch]);

  // Handle Load More functionality
  const handleLoadMore = () => {
    if (currentPage < totalPages) {
      dispatch(updateCurrentPage(currentPage + 1));
    }
  };

  // Handle search term change
  const handleSearchChange = (e) => {
    dispatch(setSearchTerm(e.target.value));
    dispatch(updateCurrentPage(1)); // Reset to first page on new search
  };

  // Handle school selection change
  const handleSchoolChange = (selectedSchool) => {
    setSchool(selectedSchool);
    dispatch(setSchoolId(selectedSchool?._id || ''));
    dispatch(updateCurrentPage(1)); // Reset to first page on new school selection
  };

  // Bulk Data Section
  const handleCreateAll = async () => {
    setIsModalOpen(false);
    const toastId = toast.loading("Loading...");
    setBulkLoading(true);
  
    // Regular expressions for validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email validation
    const phoneRegex = /^[0-9]{10,15}$/; // Phone number validation (10-15 digits)
  
    // Validate teachersData and collect error messages
    const invalidTeachers = teachersData.reduce((acc, teacher, index) => {
      let error = '';
  
      // Check if name is present (mandatory)
      if (!teacher.name || teacher.name.trim() === '') {
        error += `Unknown Email index-${index + 2} (Name Missing)`;
      }
  
      // If phone is present, validate it
      //comparing 'undefined'because in excel fetcher function i convert phone to string ...
      if (teacher.phone !== 'undefined' && !phoneRegex.test(teacher.phone)) {
        if (teacher.phone) {
          if (error) error += `, `;
          error += `${teacher.name || `Unknown Name row-${index + 2}`} (Phone Number Invalid)`;
        }
      }
  
      // If email is present, validate it
      if (teacher.email && !emailRegex.test(teacher.email)) {
        if (error) error += `, `;
        error += `${teacher.name || `Unknown Name row-${index + 2}`} (Email Invalid)`;
      }
  
      // Check if schoolId is present (mandatory)
      if (!teacher.schoolId || teacher.schoolId.trim() === '') {
        if (error) error += `, `;
        error += `${teacher.name || `Unknown Name row-${index + 2}`} (School Missing)`;
      }
  
      // If there's an error, add it to the accumulator
      if (error) acc.push(error);
  
      return acc;
    }, []);
  
    if (invalidTeachers.length > 0) {
      // Show all errors in a single alert
      alert(`Invalid data for the following teachers:\n${invalidTeachers.join('\n')}`);
      toast.dismiss(toastId);
      setBulkLoading(false);
      return; // Stop the function if there are invalid teachers
    }
  
    // Proceed with the API call if no validation errors
    try {
      const response = await apiConnector(
        "POST",
        `${teacherEndpoints.BULK_CREATE_TEACHER}`,
        teachersData,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );
  
      // Dismiss the loading toast
      toast.dismiss(toastId);
  
      // Show success toast
      toast.success("Teachers Created Successfully!", {
        id: toastId,
      });
  
      console.log("TEACHERS Created:", response.data);
      setBulkLoading(false);
      navigate('/teachers');
    } catch (error) {
      // Dismiss the loading toast
      toast.dismiss(toastId);
      setBulkLoading(false);
  
      // Show error toast
      toast.error(`Failed to create Teachers. ${error?.response?.data?.message}`, {
        id: toastId,
      });
  
      console.error("Error creating Teachers:", error);
    }
    setBulkLoading(false);
  };
  

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setTeachersData([]);
  };

  // Send File to Excel to JSON Converter
  const handleFile = async (e) => {
    try {
      const data = await handleImportExcel(e);
      console.log(data)
      setTeachersData(data);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error reading file", error);
    }
  };

  const handleDelete = (index) => {
    const updatedData = teachersData.filter((_, i) => i !== index);
    setTeachersData(updatedData);
  };

  // Teachers Table Logic with anchor
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleClick = (event, user) => {
    setAnchorEl(event.currentTarget);
    setSelectedUser(user);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedUser(null);
  };

  const handleDeleteFull = async () => {
    if (window.confirm("Are you sure you want to delete?")) {
      const toastId = toast.loading("Deleting User!");

      try {
        // Set up the API endpoint for deleting the teacher
        const endpoint = `${teacherEndpoints.DELETE_TEACHER}/${selectedUser._id}`;

        // Make the API call to delete the teacher
        await apiConnector(
          "DELETE",
          endpoint,
          {},
          { Authorization: `Bearer ${token}` }
        );

        // Show success message
        toast.success("User Deleted Successfully");

        // Filter out the deleted teacher from the current teachers list
        const updatedTeachers = teachers.filter((teacher) => teacher._id !== selectedUser._id);

        // Dispatch the updated teacher data to Redux, assuming payload structure needs `teachers`
        dispatch(setTeachers({
          teachers: updatedTeachers,
          totalTeachers: totalTeachers - 1, // Update the total count
          totalPages: Math.ceil((totalTeachers - 1) / teachersPerPage), // Adjust based on new total
          currentPage: currentPage, // Keep current page the same or adjust if needed
          schoolId: schoolId, // Keep the schoolId same, update if necessary
        }));

      } catch (error) {
        console.error("Error deleting teacher:", error);
        toast.error("Failed to delete User");
      } finally {
        toast.dismiss(toastId);
        handleClose(); // Close the menu after deletion
      }
    }
  };



  const handleSort = () => {
    setSorted(true);
    const sortedArr = sortByName(teachers);
    // Dispatch the updated state
    dispatch(setTeachers({
      teachers: sortedArr, // Corrected to use 'students' property
      totalTeachers,       // Make sure these variables are defined in scope
      totalPages, 
      currentPage, 
      schoolId
    }));
  };


  return (
    <Container sx={{ my: 4 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
        <Typography variant="h4">
          All Teachers{" "}
          <Typography component="span" color="textSecondary" sx={{ fontSize: "0.875rem" }}>
            ({totalTeachers} Teachers)
          </Typography>
        </Typography>
        <Box>
          <input
            accept=".xlsx, .xls"
            id="import-excel"
            type="file"
            style={{ display: "none" }}
            onChange={handleFile}
          />
          <label htmlFor="import-excel">
            <Button
              variant="contained"
              sx={{ backgroundColor: "#EF492E", color: "#fff", ml: 1, "&:hover": { backgroundColor: "#D44428" } }}
              startIcon={<ImportExportIcon />}
              component="span"
              disabled={bulkLoading}
            >
              Import From Excel
            </Button>
          </label>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#EF492E", color: "#fff", ml: 1, "&:hover": { backgroundColor: "#D44428" } }}
            startIcon={<AddIcon />}
            onClick={() => {
              navigate("/add-teacher");
            }}
          >
            Add New Teacher
          </Button>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <TextField
          size="small"
          variant="outlined"
          onChange={handleSearchChange}
          value={searchTerm}
          placeholder="Search Teachers"
          sx={{ width: "30%" }}
        />
        <Box sx={{ width: '20%' }}>
          <SchoolSelectorDropdown size={'small'} school={school} setSchool={setSchool} handleSchoolChange={handleSchoolChange} />
        </Box>
      </Box>

      {/* Table Showing the Teachers */}
      <TableContainer component={Paper} sx={{ borderRadius: "8px", border: "1px solid #f5a48b", marginTop: '10px', overflowX: 'auto' }}>
        <Table sx={{ tableLayout: 'auto' }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#FFBE9D" }}>
              <TableCell sx={{ fontWeight: 'bold' }} width={'50px'}>S.No</TableCell>
              <TableCell sx={{ fontWeight: 'bold',cursor:'pointer',display:'flex',justifyContent:'center',alignItems:'center'}} width={'150px'} onClick={handleSort}>Name{sorted&&<ArrowUpwardIcon/>}</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} width={'250px'}>Email</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} width={'250px'}>userId</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} width={'200px'}>School</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} width={'100px'}>City</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} width={'50px'}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ fontSize: "16px", fontWeight: "bold" }}>
            {teachers?.map((teacher, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}.</TableCell>
                <TableCell>{teacher.name}</TableCell>
                <TableCell>{teacher.email}</TableCell>
                <TableCell>{teacher.userId}</TableCell>
                <TableCell>{teacher.schoolName}</TableCell>
                <TableCell>{teacher.city}</TableCell>
                <TableCell>
                  <IconButton onClick={(event) => handleClick(event, teacher)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={()=>{
                        navigate(`/teacher/${selectedUser?._id}/assigned-students`)
                    }}>Assigned-Students</MenuItem>
                    <MenuItem onClick={handleDeleteFull}>Delete</MenuItem>
                    <MenuItem onClick={() => navigate(`/teacherprofile/${selectedUser?._id}`)}>View</MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box mt={3} display={'flex'} justifyContent={'end'} alignItems={'center'} gap={'10px'} textAlign="center">
        {/* <Typography>total Schools -{(totalSchools)}</Typography> */}
        <Typography>Page -{currentPage}/{totalPages}</Typography>
        <Button
          onClick={handleLoadMore}
          disabled={currentPage >= totalPages}
          variant="outlined"
          color="primary"
        >
          Load More
        </Button>
        {/* page size */}
        <FormControl>

          <Select
            value={teachersPerPage}
            onChange={handleChange}
            displayEmpty
            size="small"
            renderValue={(value) => `Page Size - ${value}`}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>

        </FormControl>
      </Box>

      {/* Modal for Bulk Teacher Data */}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            borderRadius: 2,
            p: 4,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Confirm Bulk Teacher Data
          </Typography>
          <TableContainer component={Paper} sx={{ maxHeight: 300 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>No.</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {teachersData.map((teacher, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{teacher.name}</TableCell>
                    <TableCell>{teacher.email}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleDelete(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button variant="contained" onClick={handleCreateAll} disabled={bulkLoading}>
              {bulkLoading ? 'Creating...' : 'Create All'}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
};

export default AllTeachers;
