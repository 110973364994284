import React, { useEffect, useState } from 'react';
import { apiConnector } from '../../services/ApiConnector';
import { teacherEndpoints } from '../../services/ApiEndPoints';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Typography, Button } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import StudentEnrollModal from './Modal/StudentsEnrollModal';  // Assuming TestModal is in the same folder

const AssignedStudentPage = () => {
  const token = useSelector((state) => state.auth.token);
  const { id } = useParams();
  const [assignedStudents, setAssignedStudents] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);  // State to control modal visibility

  const open = Boolean(anchorEl);

  const handleClick = (event, studentId) => {
    setAnchorEl(event.currentTarget);
    setSelectedStudentId(studentId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedStudentId(null);
  };

  const handleRemoveStudent = (studentId) => {
    if (window.confirm("Are you sure you want to remove this student?")) {
      const RemoveStudentFromAssignList = async () => {
        const toastId = toast.loading("Removing Student...");
        const studentIds = [studentId];
        try {
          const response = await apiConnector(
            "PUT",
            `${teacherEndpoints.UNASSIGN_STUDENT}?teacherId=${id}`,
            { studentIds },
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            }
          );
          if (response.status === 200) {
            setAssignedStudents((prevStudents) =>
              prevStudents.filter((item) => item._id !== studentId)
            );
            toast.success("Student removed successfully");
          } else {
            toast.error("Some error occurred. Please try again later.");
          }
        } catch (error) {
          toast.error(`Failed to remove student: ${error.message}`);
          console.error("Error removing student:", error);
        } finally {
          toast.dismiss(toastId);
        }
      };

      RemoveStudentFromAssignList();
    }
    handleClose();
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  
  const fetchAssignedStudentList = async () => {
    const toastId = toast.loading("Loading Assigned Students...");

    try {
      const response = await apiConnector(
        "GET",
        `${teacherEndpoints.GET_ASSIGNED_STUDENT_LIST}?teacherId=${id}`,
        null,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );

      setAssignedStudents(response?.data?.data || []);
      toast.success("Assigned students loaded successfully");
    } catch (error) {
      toast.error(`Failed to load students list: ${error.message}`);
      console.error("Error fetching student list:", error);
    } finally {
      toast.dismiss(toastId);
    }
  };

  useEffect(() => {
    fetchAssignedStudentList();
  }, [id, token]);

  return (
    <div style={{ padding: '20px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <Typography variant="h4">Enrolled Students</Typography>
        <Button variant="contained" color="primary" onClick={handleOpenModal}>
          Assign More Students
        </Button>
      </div>
 
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: "#FFBE9D" }}>
            <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Email</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Phone</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>City</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Class</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Action</TableCell>
          </TableRow>
        </TableHead>
        {
          assignedStudents.length == 0 && <Typography  variant='h5' mt={'20%'} ml={'30%'} textAlign={'center'}>No Student is Assigned To this teacher !</Typography>
        }
        <TableBody>
          {assignedStudents?.map((student) => (
            <TableRow key={student?._id}>
              <TableCell>{student?.name}</TableCell>
              <TableCell>{student?.email}</TableCell>
              <TableCell>{student?.phone}</TableCell>
              <TableCell>{student?.city}</TableCell>
              <TableCell>{student?.class}</TableCell>
              <TableCell>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={(event) => handleClick(event, student?._id)}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={open && selectedStudentId === student?._id}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => handleRemoveStudent(student?._id)}>Remove Student</MenuItem>
                </Menu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* TestModal is activated here */}
      {isModalOpen && (
        <StudentEnrollModal fetchAssignedStudentList={fetchAssignedStudentList} open={isModalOpen} onClose={handleCloseModal} teacherId={id}/>
      )}
    </div>
  );
};

export default AssignedStudentPage;
