import React, { useEffect, useState } from "react";
import {
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CardMedia,
  Menu,
  MenuItem,
  Box,
  InputAdornment,
  Select,
  FormControl
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { fetchBrands, deleteBrand } from '../../services/Operations/BrandOperation';
import { setSearchTerm, updateCurrentPage, clearBrands } from '../../Store/Slices/brandSlice.js';
import { useDebounce } from 'use-debounce';

const AllBrands = () => {
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { brands, totalBrands, currentPage, loading, totalPages, searchTerm } = useSelector((state) => state.brands);
  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);
  const [brandsPerPage, setBrandsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedBrandId, setSelectedBrandId] = useState(null);

  const handleChange = (event) => {
    setBrandsPerPage(event.target.value);
  };

  // Fetch brands on search term change
  useEffect(() => {
    const fetchBrandData = async () => {
      dispatch(fetchBrands(token, 1, brandsPerPage, debouncedSearchTerm, true));
    };

    if (!loading) {
      fetchBrandData();
    }
  }, [debouncedSearchTerm, dispatch, brandsPerPage]);

  // Fetch brands on page change
  useEffect(() => {
    const fetchBrandData = async () => {
      dispatch(fetchBrands(token, currentPage, brandsPerPage, debouncedSearchTerm));
    };

    if (!loading && currentPage > 1) {
      fetchBrandData();
    }
  }, [currentPage, debouncedSearchTerm, dispatch]);

  // Clear brands on unmount
  useEffect(() => {
    return () => {
      dispatch(clearBrands());
    };
  }, [dispatch]);

  // Handle load more
  const handleLoadMore = () => {
    if (currentPage < totalPages) {
      dispatch(updateCurrentPage(currentPage + 1));
    }
  };

  // Handle search term change
  const handleSearchChange = (e) => {
    dispatch(setSearchTerm(e.target.value));
    dispatch(updateCurrentPage(1)); // Reset to first page on new search
  };

  // Handle menu open
  const handleMenuOpen = (event, brandId) => {
    setAnchorEl(event.currentTarget);
    setSelectedBrandId(brandId);
  };

  // Handle menu close
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Handle brand deletion
  const handleDelete = () => {
    if(window.confirm("Do you really want to delete this brand from database?")){
      dispatch(deleteBrand(token, selectedBrandId));
      handleMenuClose();
    }
  };

  // Handle navigation to brand profile
  const handleView = () => {
    navigate(`/brandprofile/${selectedBrandId}`);
    handleMenuClose();
  };

  return (
    <div style={{ padding: "20px" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={5}>
        <Typography variant="h5" fontWeight="bold">
          All Brands <Typography component="span">({totalBrands} Brands Available)</Typography>
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <TextField
            variant="outlined"
            placeholder="Search Brand"
            onChange={handleSearchChange}
            value={searchTerm}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="error"
            startIcon={<AddIcon />}
            sx={{ backgroundColor: "#EF492E" }}
            onClick={() => navigate('/addbrand')}
          >
            Add Brand
          </Button>
        </Box>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#FFBE9D" }}>
              <TableCell sx={{ fontWeight: 'bold' }}>Logo</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Title</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Brand ID</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {brands?.map((brand, index) => (
              <TableRow key={index}>
                <TableCell>
                  {brand?.logo ? (
                    <CardMedia
                      component="img"
                      sx={{ width: '100px', height: '50px', objectFit: 'contain', borderRadius: '50%' }}
                      image={brand?.logo}
                      alt="Brand Logo"
                    />
                  ) : (
                    <Typography
                      variant="body1"
                      sx={{
                        backgroundColor: 'wheat',
                        width: '50px',
                        height: '50px',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflow: 'hidden',
                        color: '#FF6636',
                        fontSize: '20px',
                      }}
                    >
                      {brand?.title.charAt(0)}
                    </Typography>
                  )}
                </TableCell>
                <TableCell>{brand?.title}</TableCell>
                <TableCell>{brand?._id}</TableCell>
                <TableCell>
                  <MoreVertIcon
                    sx={{ cursor: 'pointer' }}
                    onClick={(event) => handleMenuOpen(event, brand._id)}
                  />
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl) && selectedBrandId === brand._id}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={handleView}>View</MenuItem>
                    <MenuItem onClick={handleDelete}>Delete</MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box mt={3} display="flex" justifyContent="end" alignItems="center" gap="10px">
        <Typography>Page - {currentPage}/{totalPages}</Typography>
        <Button
          onClick={handleLoadMore}
          disabled={currentPage >= totalPages}
          variant="outlined"
          color="primary"
        >
          Load More
        </Button>
         {/* Bottom pagination  */}
        <FormControl>
          <Select
            value={brandsPerPage}
            onChange={handleChange}
            displayEmpty
            size="small"
            renderValue={(value) => `Page Size - ${value}`}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </div>
  );
};

export default AllBrands;
