import { toast } from "react-hot-toast";
import { apiConnector } from '../ApiConnector';
import { brandEndpoints } from '../ApiEndPoints';
import { setBrands, appendBrands, setLoading, clearBrands, setSearchTerm } from '../../Store/Slices/brandSlice';

// Fetch Brands (with Pagination and Search)
export function fetchBrands(token, page, limit, searchTerm, reset = false) {
    return async (dispatch, getState) => {
        const { searchTerm: previousSearchTerm } = getState().brands;

        if (reset || searchTerm !== previousSearchTerm) {
            dispatch(clearBrands());
            dispatch(setSearchTerm(searchTerm));
        }

        const toastId = toast.loading("Loading...");
        dispatch(setLoading(true));

        try {
            const response = await apiConnector(
                "GET",
                `${brandEndpoints.GET_ALL_BRANDS}?page=${page}&limit=${limit}&searchTerm=${searchTerm}`,
                null,
                { Authorization: `Bearer ${token}` }
            );
        
            if (response.status !== 200) {
                throw new Error("Could not fetch brand data");
            }
           console.log(response)
            const result = response?.data?.data;
            const totalBrands = response.data.totalBrands;
            const totalPages = response.data.totalPages;

            if (reset || searchTerm !== previousSearchTerm || page === 1) {
                dispatch(setBrands({ brands: result, totalBrands, totalPages, currentPage: page, searchTerm }));
            } else {
                dispatch(appendBrands({ brands: result, totalBrands, totalPages, currentPage: page, searchTerm }));
            }

            toast.success("Brands fetched successfully");
        } catch (error) {
            toast.error(`Error! ${error?.response?.data?.message}`);
            console.error("FETCH BRANDS ERROR....", error);
        } finally {
            dispatch(setLoading(false));
            toast.dismiss(toastId);
        }
    };
}

// Add a Brand
export function addBrand(token, brandData,navigate) {
    return async (dispatch, getState) => {
        const toastId = toast.loading("Adding brand...");

        try {
            const response = await apiConnector("POST", brandEndpoints.ADD_BRAND, brandData, {
                Authorization: `Bearer ${token}`
            });
            console.log(response)
            if (response.status !== 201) {
                throw new Error("Could not add brand");
            }

            const newBrand = response?.data?.data;
            toast.success("Brand added successfully");
            
            navigate('/brands')
        } catch (error) {
            toast.error(`Error! ${error?.response?.data?.message}`);
            console.error("ADD BRAND ERROR....", error);
        } finally {
            toast.dismiss(toastId);
        }
    };
}

// Delete a Brand
export function deleteBrand(token, brandId) {
    return async (dispatch, getState) => {
        const toastId = toast.loading("Deleting brand...");
        try {
            const response = await apiConnector("DELETE", `${brandEndpoints.DELETE_BRAND_DETAIL}?id=${brandId}`, null, {
                Authorization: `Bearer ${token}`
            });

            if (response.status !== 200) {
                throw new Error("Could not delete brand");
            }

            // Update the brands list by filtering out the deleted brand
            const updatedBrands = getState().brands.brands.filter((brand) => brand._id !== brandId);
            const totalBrands = getState().brands.totalBrands - 1;
            dispatch(setBrands({ brands: updatedBrands, totalBrands, totalPages: getState().brands.totalPages, currentPage: getState().brands.currentPage }));
            toast.success("Brand deleted successfully");
        } catch (error) {
            toast.error(`Error! ${error?.response?.data?.message}`);
            console.error("DELETE BRAND ERROR....", error);
        } finally {
            toast.dismiss(toastId);
        }
    };
}

// Fetch Brand Profile
export const fetchBrandProfile = async (token, brandId) => {
    const toastId = toast.loading("Fetching brand profile...");

    try {
        const response = await apiConnector(
            "GET",
            `${brandEndpoints.GET_BRAND_DETAIL}?id=${brandId}`,
            null, // No body required for GET request
            {
                Authorization: `Bearer ${token}`
            }
        );

        if (response.status !== 200) {
            throw new Error("Could not fetch brand profile");
        }

        const brandProfile = response?.data?.data;
        toast.success("Brand profile fetched successfully");

        // Return the brand profile
        return brandProfile;
    } catch (error) {
        toast.error(`Error! ${error?.response?.data?.message || 'Something went wrong'}`);
        console.error("FETCH BRAND PROFILE ERROR:", error);
    } finally {
        toast.dismiss(toastId);
    }
};

// Update Brand
export const updateBrand = async (token, brandId, brandData) => {
    const toastId = toast.loading("Updating brand...");

    try {
        const response = await apiConnector(
            "PUT",
            `${brandEndpoints.UPDATE_BRAND_DETAIL}?id=${brandId}`,
            brandData, // Payload to update the brand
            {
                Authorization: `Bearer ${token}`
            }
        );

        if (response.status !== 200) {
            throw new Error("Could not update brand");
        }

        toast.success("Brand updated successfully");

        // Return the updated brand data
        return response.data.data;
    } catch (error) {
        toast.error(`Error! ${error?.response?.data?.message || 'Something went wrong'}`);
        console.error("UPDATE BRAND ERROR:", error);
        
    } finally {
        toast.dismiss(toastId);
    }
};
