import React, { useEffect, useState } from "react";
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, IconButton, Menu, MenuItem, Button, Typography, Box,
  TextField, InputAdornment,
  FormControl,
  Select
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { green, red } from "@mui/material/colors";
import { useNavigate } from "react-router";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";

import { fetchCertifications } from '../../services/Operations/CertificationOperation';
import { clearCertifications, setCertifications, setSearchTerm, updateCurrentPage } from '../../Store/Slices/certificationSlice';
import { useDebounce } from 'use-debounce';
import { toast } from "react-hot-toast";
import { certificationEndpoints } from "../../services/ApiEndPoints";
import { apiConnector } from "../../services/ApiConnector";

const CertificationTable = ({
  totalCertifications,
  certificationsPerPage,
  handleChange,
  handleLoadMore,
  certifications,
  currentPage,
  totalPages,
}) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedCertification, setSelectedCertification] = React.useState(null);

  const handleMenuClick = (event, certification) => {
    setAnchorEl(event.currentTarget);
    setSelectedCertification(certification);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedCertification(null);
  };

  const handleView = () => {
    if (selectedCertification) {
      navigate(`/certification/${selectedCertification._id}`)
    }
    handleMenuClose();
  };

  const handleDelete = async () => {
    handleMenuClose();
    if (window.confirm("Are you sure you want to delete?")) {
      const toastId = toast.loading("Deleting Certification!");

      try {
        const endpoint = `${certificationEndpoints.DELETE_CERTIFICATION}/${selectedCertification._id}`;
        await apiConnector("DELETE", endpoint, {}, { Authorization: `Bearer ${token}` });

        toast.success("Certification Deleted Successfully");
        const updatedCertifications = certifications.filter((certification) => certification._id !== selectedCertification._id);
        dispatch(
          setCertifications({
            certifications: updatedCertifications,
            totalCertifications: totalCertifications - 1,
            totalPages,
            currentPage,
          })
        );
      } catch (error) {
        console.error("Error deleting Certification:", error);
        toast.error("Failed to delete Certification");
      } finally {
        toast.dismiss(toastId);
        handleMenuClose();
      }
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#FFBE9D" }}>
              <TableCell sx={{ fontWeight: "bold" }}>Sr</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Title</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>UserName</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Type</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Course Name</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>School</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Grade</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {certifications?.map((certification, index) => (
              <TableRow key={certification.fileName}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{certification?.title}</TableCell>
                <TableCell>{certification?.teacherId?.name || certification?.studentId?.name}</TableCell>
                <TableCell>{(certification?.teacherId?.name)?"Teacher":"Student"}</TableCell>
                <TableCell>{certification?.courseId?.name}</TableCell>
                <TableCell>{certification?.schoolId?.name}</TableCell>
                <TableCell>{certification?.grade}</TableCell>
                <TableCell>
                  <IconButton onClick={(event) => handleMenuClick(event, certification)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                    <MenuItem onClick={handleView}>View Certificate</MenuItem>
                    <MenuItem onClick={handleDelete}>Delete</MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        mt={3}
        display={"flex"}
        justifyContent={"end"}
        alignItems={"center"}
        gap={"10px"}
        textAlign="center"
      >
        <Typography>Page - {currentPage} / {totalPages}</Typography>
        <Button
          onClick={handleLoadMore}
          disabled={currentPage >= totalPages}
          variant="outlined"
          color="primary"
        >
          Load More
        </Button>

        <FormControl>
          <Select
            value={certificationsPerPage}
            onChange={handleChange}
            displayEmpty
            size="small"
            renderValue={(value) => `Page Size - ${value}`}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

const AllCertification = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const { certifications, totalCertifications, currentPage, loading, totalPages, searchTerm } = useSelector((state) => state.certifications);
  const [certificationsPerPage, setCertificationsPerPage] = useState(10); // Adjust this number as needed
  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);

  const handleChange = (event) => {
    setCertificationsPerPage(event.target.value);
  };

  // Effect for handling search term change
  useEffect(() => {
    const fetchCertificationData = async () => {
      dispatch(fetchCertifications(token, 1, certificationsPerPage, debouncedSearchTerm, true)); // Reset certifications on new search term
    };

    if (!loading) {
      fetchCertificationData();
    }
  }, [debouncedSearchTerm, dispatch, certificationsPerPage]);

  // Effect for handling pagination
  useEffect(() => {
    const fetchCertificationData = async () => {
      dispatch(fetchCertifications(token, currentPage, certificationsPerPage, debouncedSearchTerm));
    };

    if (!loading && currentPage > 1) {
      fetchCertificationData();
    }
  }, [currentPage, debouncedSearchTerm, dispatch]);

  // Effect for handling component unmount
  useEffect(() => {
    return () => {
      dispatch(clearCertifications());
    };
  }, [dispatch]);

  // Handle Load More functionality
  const handleLoadMore = () => {
    if (currentPage < totalPages) {
      dispatch(updateCurrentPage(currentPage + 1));
    }
  };

  // Handle search term change
  const handleSearchChange = (e) => {
    dispatch(setSearchTerm(e.target.value));
    dispatch(updateCurrentPage(1)); // Reset to first page on new search
  };

  return (
    <div style={{ padding: '20px' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={5}>
        <Typography variant="h5" fontWeight="bold">
          All Certificates <Typography component="span">({totalCertifications} Certificates Available)</Typography>
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <TextField
            variant="outlined"
            placeholder="Search Certification"
            onChange={handleSearchChange}
            value={searchTerm}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="error"
            startIcon={<AddIcon />}
            sx={{ backgroundColor: "#EF492E" }}
            onClick={() => { navigate('/add-certificate') }}
          >
            Add Certification
          </Button>
        </Box>
      </Box>
      <CertificationTable
        totalCertifications={totalCertifications}
        certificationsPerPage={certificationsPerPage}
        handleChange={handleChange}
        handleLoadMore={handleLoadMore}
        certifications={certifications}
        currentPage={currentPage}
        totalPages={totalPages}
      />
    </div>
  );
}

export default AllCertification;
