
import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { toast } from "react-hot-toast";
import {
  Container,
  Box,
  Typography,
  Grid,
  Avatar,
  TextField,
  InputAdornment,
  Button,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import SchoolIcon from "@mui/icons-material/School";
import { apiConnector } from '../../services/ApiConnector';
import { teacherEndpoints } from '../../services/ApiEndPoints'; // Adjust import if needed
import { useSelector } from 'react-redux';
import { teacherOptions } from '../../data/data'

const TeacherProfile = () => {
  const token = useSelector((state) => state.auth.token);
  const { id } = useParams();
  const [teacherData, setTeacherData] = useState(null);
  const [updateData, setUpdateData] = useState({});
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    const fetchTeacherProfile = async () => {
      const toastId = toast.loading("Loading teacher profile...");

      try {
        const response = await apiConnector(
          "GET",
          `${teacherEndpoints.GET_TEACHER_DETAIL}/${id}`,
          null,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        );
        console.log(response.data.data)

        setTeacherData(response?.data?.data);
        toast.success("Teacher profile loaded successfully");
      } catch (error) {
        toast.error(`Failed to load teacher profile: ${error.message}`);
        console.error("FETCH TEACHER PROFILE ERROR:", error);
      } finally {
        toast.dismiss(toastId);
      }
    };

    fetchTeacherProfile();
  }, [id, token]);

  const handleEdit = () => {
    setEditing(true);
  };


  const handleUpdate = async () => {

    if (updateData?.name?.trim() === "") {
      toast.error("Teacher name is required.");
      return;
    }
    const phonePattern = /^[0-9]{10}$/;
    if (updateData?.phone && !phonePattern.test(updateData.phone)) {
      return toast.error("Phone number must be 10 digits.");
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (updateData?.email && !emailPattern.test(updateData.email)) {
      return toast.error("Invalid email address.");
    }
    
    const pincodePattern = /^[0-9]{6}$/;
    if (updateData?.pincode && !pincodePattern.test(updateData.pincode)) {
      return toast.error("Pincode must be 6 digits.");
    }
    console.log(updateData)
    setEditing(false);
    const toastId = toast.loading("Updating Teacher Data...");
    try {
      const response = await apiConnector(
        "PUT",
        `${teacherEndpoints.UPDATE_TEACHER_DETAIL}/${id}`,
        updateData,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );

      setTeacherData(response?.data?.data);
      toast.success("Teacher profile updated successfully");
    } catch (error) {
      toast.error(`Failed to update teacher profile: ${error.message}`);
      console.error("UPDATE TEACHER PROFILE ERROR:", error);
    } finally {
      toast.dismiss(toastId);
    }
  };

  const handleChange = (e) => {
    setUpdateData({
      ...updateData,
      [e.target.name]: e.target.value,
    });
    setTeacherData({
      ...teacherData,
      [e.target.name]: e.target.value,
    });
  };

  if (!teacherData) {
    return <Typography>Loading...</Typography>;
  }

  const handleCourseChange = (e, index) => {
    const { name, value } = e.target;
  
    // Create a deep copy of the Qualification array
    const updatedQualifications = [...teacherData.Qualification];
    
    // Update the specific field in the qualification object at the given index
    updatedQualifications[index] = {
      ...updatedQualifications[index],
      [name]: value,
    };
  
    // Update the teacherData state to reflect changes in the UI
    setTeacherData((prevData) => ({
      ...prevData,
      Qualification: updatedQualifications,
    }));
  
    // Update the updateData state similarly
    setUpdateData((prevData) => ({
      ...prevData,
      Qualification: updatedQualifications,
    }));
  };
  
  
  console.log(teacherData)
  return (
    <Container maxWidth="md" style={{ paddingTop: "20px", paddingBottom: '50px' }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        borderRadius={8}
        p={3}
        bgcolor="white"
        position="relative"
      >
        <Avatar
          alt="Teacher Photo"
          src={teacherData?.profilePhoto || "https://via.placeholder.com/100"}
          style={{ width: 100, height: 100, marginBottom: "20px" }}
        />
        {
          editing ? (
            <>
              <InputLabel>Teacher Name</InputLabel>
              <TextField
                name="name"
                value={updateData?.name || teacherData?.name}
                onChange={handleChange}
                variant="outlined"
                sx={{ width: '50%', mx: 'auto' }}
              />
            </>
          ) : (
            <Typography variant="h4" gutterBottom>
              {teacherData?.name}
            </Typography>
          )
        }

        {
          editing ? (
            <Button
              onClick={handleUpdate}
              variant="outlined"
              sx={{
                position: "absolute",
                top: 50,
                right: 10,
                color: "#FF6636",
                borderColor: "#FF6636",
                borderRadius: "4px",
                "&:hover": {
                  borderColor: "#FF6636",
                  backgroundColor: "#FF6636",
                  color: "#fff",
                },
              }}
            >
              Update Details
            </Button>
          ) : (
            <Button
              onClick={handleEdit}
              variant="outlined"
              sx={{
                position: "absolute",
                top: 50,
                right: 10,
                color: "#FF6636",
                borderColor: "#FF6636",
                borderRadius: "4px",
                "&:hover": {
                  borderColor: "#FF6636",
                  backgroundColor: "#FF6636",
                  color: "#fff",
                },
              }}
            >
              Edit Details
            </Button>
          )
        }

        <Grid container spacing={2}>
          {/* Contact */}
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>UserId:</strong>
            </Typography>
            <TextField
              size="small"
              name="userId"
              value={updateData?.userId || teacherData?.userId}
              onChange={handleChange}
              disabled={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon color="action" />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>Brand Id:</strong>
            </Typography>
            <TextField
              size="small"
              name="userId"
              value={updateData?.brandId?._id || teacherData?.brandId?._id}
              onChange={handleChange}
              disabled={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>MID:</strong>
            </Typography>
            <TextField
              size="small"
              name="mid"
              value={updateData?.mid || teacherData?.mid}
              onChange={handleChange}
              disabled={!editing}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>Brand Name:</strong>
            </Typography>
            <TextField
              size="small"
              name="userId"
              value={updateData?.brandId?.title || teacherData?.brandId?.title}
              onChange={handleChange}
              disabled={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>Contact No.:</strong>
            </Typography>
            <TextField
              size="small"
              name="phone"
              value={updateData?.phone || teacherData?.phone}
              onChange={handleChange}
              disabled={!editing}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon color="action" />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>Email:</strong>
            </Typography>
            <TextField
              size="small"
              name="email"
              value={updateData?.email || teacherData?.email}
              onChange={handleChange}
              disabled={!editing}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon color="action" />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>Teacher Type:</strong>
            </Typography>
            <TextField
              size="small"
              name="teacherType"
              value={updateData?.teacherType || teacherData?.teacherType}
              onChange={handleChange}
              disabled={!editing}
              variant="outlined"
              fullWidth
            />
          </Grid>
          {/* School */}
          <Grid item xs={12} >
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>School:</strong>
            </Typography>
            <TextField
              size="small"
              name="school"
              value={updateData?.school || teacherData?.schoolName}
              onChange={handleChange}
              disabled={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SchoolIcon color="action" />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>Address:</strong>
            </Typography>
            <TextField
              size="small"
              name="address"
              value={updateData?.address || teacherData?.address}
              onChange={handleChange}
              disabled={!editing}
              variant="outlined"
              fullWidth
              multiline
              rows={2}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>State:</strong>
            </Typography>
            <TextField
              size="small"
              name="state"
              value={updateData?.state || teacherData?.state}
              onChange={handleChange}
              disabled={!editing}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>City:</strong>
            </Typography>
            <TextField
              size="small"
              name="city"
              value={updateData?.city || teacherData?.city}
              onChange={handleChange}
              disabled={!editing}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>Landmark:</strong>
            </Typography>
            <TextField
              size="small"
              name="landmark"
              value={updateData?.landmark || teacherData?.landmark}
              onChange={handleChange}
              disabled={!editing}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>Pincode:</strong>
            </Typography>
            <TextField
              size="small"
              name="pincode"
              value={updateData?.pincode || teacherData?.pincode}
              onChange={handleChange}
              disabled={!editing}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: '30px', fontWeight: '500' }}>Qualifications</Typography>
          </Grid>

          {/* The add more part in teacher is remaining ... */}
          {
           teacherData?.Qualification?.map((item, index) => {
              return (
                <Grid key={index} item xs={12} display={'flex'} justifyContent={'space-between'}>
                  {/* //courseName */}
                  <Grid item xs={12} sm={3.8}>
                    <Typography variant="body1" style={{ marginBottom: "5px" }}>
                      <strong>Course:</strong>
                    </Typography>
                    <TextField
                      size="small"
                      name="course"
                      value={item?.course}
                      disabled={!editing}
                      variant="outlined"
                      fullWidth
                      onChange={(e)=>{
                        handleCourseChange(e,index)
                      }}
                    />
                  </Grid>
                  {/* Degree */}
                  <Grid item xs={12} sm={3.8}>
                    {/* //courseName */}
                    <Typography variant="body1" style={{ marginBottom: "5px" }}>
                      <strong>Degree-Type:</strong>
                    </Typography>
                    <TextField
                      size="small"
                      name="degreeType"
                      value={item?.degreeType}
                      disabled={!editing}
                      variant="outlined"
                      fullWidth
                      onChange={(e)=>{
                        handleCourseChange(e,index)
                      }}
                    />
                  </Grid>
                  {/* Passout year */}
                  <Grid item xs={12} sm={3.8}>
                    {/* //courseName */}
                    <Typography variant="body1" style={{ marginBottom: "5px" }}>
                      <strong>Passout-year:</strong>
                    </Typography>
                    <TextField
                      size="small"
                      name="passoutYear"
                      value={item?.passoutYear }
                      disabled={!editing}
                      variant="outlined"
                      fullWidth
                      onChange={(e)=>{
                        handleCourseChange(e,index)
                      }}
                    />
                  </Grid>

                </Grid>
              )
            })
          }

        </Grid>
      </Box>
    </Container>
  );
};

export default TeacherProfile;
