import * as XLSX from "xlsx";

export const handleImportExcel = (event) => {
  return new Promise((resolve, reject) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          let jsonData = XLSX.utils.sheet_to_json(worksheet);
          
          // Convert fields to string
          jsonData = jsonData.map(row => ({
            ...row,
            pincode: String(row.pincode), // Ensure pincode is a string
            parentContactNo: String(row.parentContactNo),
            contactNo: String(row.contactNo),
            phone:String(row.phone),
            QualificationPassoutYear:String(row.QualificationPassoutYear),
          }));
          resolve(jsonData);
        } catch (error) {
          console.log(error)
          reject(error);
        }
      };
      reader.readAsArrayBuffer(file);
    } else {
      reject(new Error("No file selected"));
    }
  });
};


// sort by name function ...
export const sortByName = (arr)=> {
  // Create a shallow copy of the array to avoid mutation
  const arrCopy = [...arr]; 

  return arrCopy.sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  });
}
