import React, { useState } from "react";
import ProfileCard from "../components/navbar/ProfileCard";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Badge,
  Avatar,
  Stack,
  Box,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationCard from "../components/navbar/NotificationCard";
import {useSelector} from 'react-redux'

const Navbar = () => {
  const [openProfile, setOpenProfile] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const { user } = useSelector((state) => state.profile);

  const handleClick = () => {
    setOpenProfile((prev) => !prev);
  };
  return (
    <AppBar
      position="static" // Changed from fixed to static for normal flow
      sx={{
        backgroundColor: "#fff",
        color: "#000",
      }}
    >
      <Toolbar>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          sx={{ flexGrow: 1 }}
        >
          <Box>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ fontSize: "0.875rem" }}
            >
              Hello!
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", fontSize: "1.25rem" }}
              >
                {user.name}
              </Typography>
              <span
                role="img"
                aria-label="hello"
                style={{ fontSize: "1.5rem", color: "yellow" }}
              >
                👋
              </span>
            </Stack>
          </Box>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <div style={{ position: 'relative' }}>
            <IconButton color="inherit" onClick={() => setOpenNotification((prev) => !prev)}>
              <Badge badgeContent={4} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            {
              openNotification &&
              <NotificationCard setOpenNotification={setOpenNotification} />
            }
          </div>
          <div style={{ position: 'relative' }}>
            <Avatar
              alt={user?.name}
              src={user?.profilePhoto || `https://ui-avatars.com/api/?name=${user?.name}&background=random`}
              onClick={handleClick}
              sx={{ cursor: 'pointer', width: 50, height: 50 }}
            />
            {
              openProfile && <ProfileCard user={user} setOpenProfile={setOpenProfile} />
            }
          </div>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
